import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const BigCard = () => {
  return (
    <div>
      <div className="grid-container">
        <main className="main">
          <div className="row">
            <div className="col-md-6">
              {" "}
              <Skeleton height={500} />
            </div>
            <div className="col-md-6">
              {" "}
              <Skeleton height={500} width={700} />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
