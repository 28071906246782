import React from "react";
import { Link } from "react-router-dom";
import "./Error.css";
import logo from "./../../assets/img/logo.png";
import { useTranslation } from "react-i18next";

const ErrorPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="logo m-3">
        <Link to="/">
          <img src={logo} alt="" />
        </Link>
      </div>
      <div className="unauth-ctn">
        <div className="main">
          <div className="unauth-wrapper">
            <h2>{t("Oh_No")}</h2>
            <p>{t("It_looks")}</p>
            <Link to="/">
              <button>{t("Return_home")}</button>
            </Link>
          </div>
        </div>
        <div className="unauth-svg-ctn">
          <svg
            width="898"
            height="679"
            viewBox="0 0 898 679"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.18"
              d="M278.781 569.009C335.548 594.044 399.754 593.659 461.764 592.833C494.292 592.419 527.946 591.758 557.565 578.236C598.741 559.425 625.477 519.049 661.602 491.728C703.079 460.304 756.167 446.753 796.382 413.731C815.955 397.671 831.804 377.524 842.827 354.692C853.849 331.86 859.78 306.893 860.206 281.527C860.631 256.162 855.542 231.008 845.293 207.817C835.043 184.625 819.879 163.954 800.856 147.241C779.583 128.568 754.191 115.376 729.019 102.404L602.611 37.1309C572.992 21.8454 542.605 6.31209 509.555 2.15335C481.665 -1.3444 453.446 3.50288 426.188 9.75476C348.504 27.3812 271.424 57.7593 214.437 113.365C165.411 161.287 133.404 225.651 79.7665 268.313C66.0414 279.192 50.834 288.804 40.8421 303.18C4.88228 354.986 41.3087 421.167 88.029 447.717C152.729 484.457 210.951 539.127 278.781 569.009Z"
              fill="#DD4F05"
            />
            <path
              d="M449.009 679C641.883 679 798.237 659.621 798.237 635.717C798.237 611.812 641.883 592.434 449.009 592.434C256.136 592.434 99.7812 611.812 99.7812 635.717C99.7812 659.621 256.136 679 449.009 679Z"
              fill="#68E1FD"
            />
            <path
              d="M449.009 679C641.883 679 798.237 659.621 798.237 635.717C798.237 611.812 641.883 592.434 449.009 592.434C256.136 592.434 99.7812 611.812 99.7812 635.717C99.7812 659.621 256.136 679 449.009 679Z"
              fill="url(#paint0_linear_2720_4248)"
            />
            <path
              d="M257.696 212.861C251.218 208.454 243.924 205.396 236.247 203.87C228.57 202.343 220.665 202.378 213.001 203.973C210.196 204.412 207.546 205.55 205.294 207.284C200.045 211.728 200.864 219.993 199.469 226.792C197.763 235.169 192.16 242.346 190.145 250.612C188.971 256.831 188.903 263.21 189.945 269.453C192.896 294.081 198.778 318.265 207.464 341.489L183.102 663.469C183.102 663.469 208.018 667.513 258.559 671.556L263.919 364.93L257.696 212.861Z"
              fill="#EC8D5B"
            />
            <path
              d="M229.992 400.636L262.262 531.553V342.733L247.91 299.938L229.992 400.636Z"
              fill="url(#paint1_linear_2720_4248)"
            />
            <path
              d="M651.976 215.616C658.468 211.242 665.768 208.217 673.444 206.721C681.121 205.225 689.019 205.288 696.671 206.906C699.48 207.355 702.135 208.492 704.4 210.217C709.627 214.661 708.83 222.926 710.203 229.748C711.931 238.124 717.534 245.301 719.549 253.567C720.727 259.779 720.787 266.153 719.727 272.387C716.796 297.016 710.922 321.201 702.23 344.422L726.482 661.913C726.482 661.913 699.351 666.491 650.869 671.09L645.664 367.841L651.976 215.616Z"
              fill="#EC8D5B"
            />
            <path
              d="M683.824 391.282L672.373 450.274L647.988 503.49L645.84 387.683L683.824 391.282Z"
              fill="url(#paint2_linear_2720_4248)"
            />
            <path
              d="M666.727 107.542H234.909C224.671 107.542 216.371 115.868 216.371 126.139V385.283C216.371 395.554 224.671 403.88 234.909 403.88H666.727C676.965 403.88 685.264 395.554 685.264 385.283V126.139C685.264 115.868 676.965 107.542 666.727 107.542Z"
              fill="#68E1FD"
            />
            <path
              d="M666.727 107.542H234.909C224.671 107.542 216.371 115.868 216.371 126.139V385.283C216.371 395.554 224.671 403.88 234.909 403.88H666.727C676.965 403.88 685.264 395.554 685.264 385.283V126.139C685.264 115.868 676.965 107.542 666.727 107.542Z"
              fill="url(#paint3_linear_2720_4248)"
            />
            <path
              d="M666.727 95.6541H234.909C224.671 95.6541 216.371 103.98 216.371 114.252V373.395C216.371 383.667 224.671 391.993 234.909 391.993H666.727C676.965 391.993 685.264 383.667 685.264 373.395V114.252C685.264 103.98 676.965 95.6541 666.727 95.6541Z"
              fill="#3C1400"
            />
            <path
              d="M257.898 219.194C265.362 210.884 265.362 210.884 273.801 200.929L309.747 158.713C315.4 152.519 320.583 145.909 325.25 138.938H359.203C358.249 148.097 357.813 157.303 357.896 166.512V220.527H362.702C368.19 220.475 373.667 220.029 379.092 219.194V249.456C373.706 248.786 368.284 248.452 362.857 248.456H357.896V256.767C357.743 263.605 358.181 270.445 359.203 277.208H325.095C326.02 270.323 326.457 263.381 326.402 256.433V248.412H279.448C268.662 248.412 263.945 248.568 257.898 249.056V219.194ZM326.557 201.596C326.557 194.108 326.889 184.976 327.554 177.666C324.231 182.31 321.751 185.642 317.277 191.286L293.025 220.86H326.49L326.557 201.596Z"
              fill="white"
            />
            <path
              d="M406.488 264.743C401.202 259.524 397.08 253.238 394.396 246.301C390.03 234.093 387.841 221.209 387.928 208.239C387.928 190.775 392.07 172.688 398.382 162.201C408.482 145.248 425.713 135.938 446.576 135.938C462.655 135.938 476.741 141.426 486.841 151.491C492.134 156.705 496.257 162.993 498.934 169.933C503.322 182.189 505.512 195.128 505.401 208.151C505.401 225.615 501.259 243.901 494.969 254.367C485.025 270.987 467.616 280.297 446.576 280.297C430.342 280.208 416.61 274.875 406.488 264.743ZM421.992 206.973C421.992 236.725 430.43 252.011 446.842 252.011C463.253 252.011 471.359 237.058 471.359 207.573C471.271 178.977 462.589 163.979 446.576 163.979C431.006 163.979 421.903 179.821 421.903 206.906L421.992 206.973Z"
              fill="white"
            />
            <path
              d="M513.992 219.194C521.456 210.884 521.456 210.884 529.894 200.929L565.863 158.713C571.48 152.517 576.625 145.907 581.255 138.938H615.23C614.258 148.096 613.814 157.302 613.901 166.512V220.527H618.707C624.195 220.475 629.672 220.029 635.097 219.194V249.456C629.711 248.786 624.289 248.452 618.862 248.456H613.901V256.767C613.743 263.606 614.188 270.447 615.23 277.208H581.1C582.034 270.324 582.478 263.381 582.429 256.433V248.412H535.542C524.778 248.412 520.039 248.568 513.992 249.056V219.194ZM582.651 201.596C582.651 194.108 582.983 184.976 583.647 177.666C580.325 182.31 577.845 185.642 573.371 191.286L549.119 220.86H582.584L582.651 201.596Z"
              fill="white"
            />
            <path
              d="M259.491 325.891C262.28 325.433 265.103 325.21 267.929 325.224C271.358 324.963 274.763 325.975 277.497 328.068C278.483 328.972 279.26 330.08 279.777 331.315C280.293 332.551 280.535 333.884 280.487 335.223C280.555 336.556 280.357 337.889 279.905 339.145C279.452 340.4 278.754 341.552 277.852 342.533C276.455 343.833 274.811 344.836 273.019 345.481C271.226 346.126 269.321 346.401 267.42 346.288C266.238 346.331 265.055 346.227 263.898 345.977V359.686H259.469L259.491 325.891ZM263.92 342.377C265.111 342.654 266.332 342.773 267.553 342.733C272.868 342.733 276.102 340.155 276.102 335.423C276.102 330.69 272.913 328.757 268.062 328.757C266.673 328.719 265.283 328.838 263.92 329.112V342.377Z"
              fill="white"
            />
            <path
              d="M287.618 348.932L284.074 359.686H279.645L291.206 325.491H296.477L308.105 359.686H303.409L299.777 348.932H287.618ZM298.692 345.488L295.347 335.645C294.594 333.423 294.085 331.379 293.576 329.401C293.088 331.423 292.512 333.512 291.87 335.578L288.526 345.488H298.692Z"
              fill="white"
            />
            <path
              d="M338.293 358.153C334.932 359.371 331.39 360.009 327.817 360.042C325.488 360.267 323.139 359.977 320.934 359.193C318.729 358.409 316.722 357.15 315.054 355.504C313.386 353.859 312.098 351.866 311.28 349.668C310.462 347.469 310.134 345.117 310.32 342.777C310.32 332.579 317.341 325.135 328.769 325.135C331.682 325.063 334.579 325.601 337.274 326.713L336.211 330.312C333.84 329.271 331.268 328.77 328.681 328.846C320.375 328.846 314.971 334.023 314.971 342.577C314.971 351.132 320.176 356.398 328.127 356.398C330.127 356.504 332.127 356.186 333.996 355.465V345.288H327.064V341.733H338.293V358.153Z"
              fill="white"
            />
            <path
              d="M362.657 343.644H349.368V355.976H364.141V359.686H344.961V325.491H363.388V329.201H349.413V340H362.701L362.657 343.644Z"
              fill="white"
            />
            <path
              d="M380.641 359.686V325.491H385.447L396.366 342.777C398.64 346.348 400.689 350.059 402.501 353.887C402.102 349.443 401.991 345.177 401.991 339.844V325.535H406.155V359.731H401.725L390.895 342.377C388.545 338.701 386.415 334.887 384.517 330.957H384.361C384.627 335.289 384.716 339.4 384.716 345.066V359.686H380.641Z"
              fill="white"
            />
            <path
              d="M443.029 342.222C443.029 353.998 435.898 360.242 427.194 360.242C418.489 360.242 411.867 353.243 411.867 342.889C411.867 332.023 418.512 324.935 427.703 324.935C436.894 324.935 443.029 332.09 443.029 342.222ZM416.585 342.777C416.585 350.088 420.527 356.642 427.459 356.642C434.392 356.642 438.378 350.199 438.378 342.422C438.378 335.645 434.835 328.535 427.504 328.535C420.173 328.535 416.585 335.289 416.585 342.777Z"
              fill="white"
            />
            <path
              d="M453.951 329.246H443.586V325.491H468.835V329.246H458.403V359.686H453.973L453.951 329.246Z"
              fill="white"
            />
            <path
              d="M483.339 325.491H501.699V329.201H487.746V340.555H500.636V344.199H487.746V359.753H483.316L483.339 325.491Z"
              fill="white"
            />
            <path
              d="M537.093 342.222C537.093 353.998 529.961 360.242 521.279 360.242C512.265 360.242 505.953 353.243 505.953 342.889C505.953 332.023 512.597 324.935 521.767 324.935C530.936 324.935 537.093 332.09 537.093 342.222ZM510.648 342.777C510.648 350.088 514.591 356.642 521.523 356.642C528.455 356.642 532.442 350.199 532.442 342.422C532.442 335.645 528.898 328.535 521.567 328.535C514.236 328.535 510.648 335.289 510.648 342.777Z"
              fill="white"
            />
            <path
              d="M547.216 325.491V345.733C547.216 353.398 550.605 356.642 555.167 356.642C560.217 356.642 563.45 353.287 563.45 345.733V325.491H567.88V345.488C567.88 355.998 562.365 360.308 554.99 360.308C548.013 360.308 542.742 356.309 542.742 345.688V325.557L547.216 325.491Z"
              fill="white"
            />
            <path
              d="M575.543 359.686V325.491H580.349L591.268 342.777C593.544 346.343 595.586 350.055 597.381 353.887H597.492C597.093 349.443 596.982 345.177 596.982 339.844V325.535H601.124V359.731H596.694L585.886 342.377C583.528 338.706 581.398 334.892 579.507 330.957H579.352C579.596 335.289 579.707 339.4 579.707 345.066V359.686H575.543Z"
              fill="white"
            />
            <path
              d="M608.766 325.935C611.867 325.468 614.998 325.23 618.134 325.224C624.446 325.224 628.964 326.713 631.932 329.49C633.57 331.069 634.848 332.984 635.678 335.105C636.508 337.226 636.869 339.503 636.738 341.778C636.864 344.216 636.496 346.655 635.654 348.947C634.813 351.239 633.517 353.335 631.844 355.109C628.654 358.309 623.383 360.042 616.761 360.042C614.088 360.022 611.418 359.844 608.766 359.509V325.935ZM613.195 356.176C614.661 356.394 616.143 356.483 617.625 356.442C627.038 356.442 632.132 351.154 632.132 341.933C632.132 333.867 627.702 328.735 618.334 328.735C616.602 328.702 614.871 328.859 613.173 329.201L613.195 356.176Z"
              fill="white"
            />
            <path
              d="M236.236 208.973C238.137 209.922 239.756 211.355 240.932 213.128C241.905 215.211 242.382 217.493 242.327 219.794L242.991 234.125C243.292 236.483 243.011 238.879 242.172 241.102C241.725 242.199 240.907 243.103 239.862 243.655C238.818 244.208 237.612 244.374 236.458 244.124C237.306 246.913 237.58 249.846 237.264 252.745C236.948 255.644 236.048 258.449 234.619 260.988C233.903 262.058 233.344 263.225 232.958 264.454C232.774 265.937 232.849 267.441 233.18 268.898C233.682 272.493 233.414 276.154 232.395 279.637C231.375 283.12 229.627 286.344 227.266 289.096C226.545 289.997 225.658 290.751 224.653 291.317C223.335 291.881 221.921 292.183 220.489 292.206L213.978 292.762C213.656 292.868 213.314 292.898 212.978 292.851C212.643 292.803 212.323 292.679 212.042 292.488C211.762 292.297 211.529 292.044 211.362 291.749C211.194 291.454 211.097 291.124 211.076 290.784C206.132 275.051 203.885 258.589 204.432 242.102C204.688 233.794 205.681 225.525 207.4 217.394C208.441 212.639 210.478 205.395 215.55 203.462C222.106 201.151 230.566 205.706 236.236 208.973Z"
              fill="#EC8D5B"
            />
            <path
              d="M663.958 208.973C662.058 209.922 660.439 211.355 659.263 213.128C658.289 215.211 657.812 217.493 657.867 219.794L657.203 234.125C656.902 236.483 657.184 238.879 658.022 241.102C658.469 242.199 659.287 243.103 660.332 243.655C661.376 244.208 662.582 244.374 663.736 244.124C662.89 246.912 662.615 249.844 662.927 252.742C663.239 255.64 664.132 258.445 665.553 260.988C666.284 262.052 666.852 263.221 667.236 264.454C667.423 265.938 667.34 267.444 666.992 268.898C666.5 272.494 666.775 276.155 667.798 279.636C668.821 283.118 670.57 286.342 672.928 289.096C673.649 289.997 674.537 290.751 675.541 291.318C676.859 291.881 678.273 292.183 679.705 292.206L686.283 292.851C686.605 292.957 686.947 292.987 687.282 292.94C687.618 292.892 687.938 292.768 688.218 292.577C688.499 292.386 688.731 292.133 688.899 291.838C689.066 291.542 689.164 291.212 689.184 290.873C694.128 275.14 696.375 258.678 695.829 242.191C695.576 233.881 694.575 225.612 692.839 217.483C691.82 212.728 690.624 206.773 685.596 204.84C678.974 202.418 669.628 205.707 663.958 208.973Z"
              fill="#EC8D5B"
            />
            <defs>
              <linearGradient
                id="paint0_linear_2720_4248"
                x1="446.883"
                y1="575.48"
                x2="453.37"
                y2="758.877"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#4E1B00" />
                <stop offset="0.95" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_2720_4248"
                x1="249.726"
                y1="514.666"
                x2="243.239"
                y2="258.967"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopOpacity="0" />
                <stop offset="0.99" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_2720_4248"
                x1="666.77"
                y1="479.426"
                x2="662.445"
                y2="319.026"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopOpacity="0" />
                <stop offset="0.95" />
              </linearGradient>
              <linearGradient
                id="paint3_linear_2720_4248"
                x1="442.922"
                y1="529.82"
                x2="453.02"
                y2="181.689"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#240C00" />
                <stop offset="0.99" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
