import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import BuyersSidebar from "../BuyersComponent/BuyerSideBar";

import { ProtectedRoutes } from "../../../../components/utils/ProtectedRoutes";
import { axiosInstance } from "../../../../components/baseUrl";

import toast, { Toaster } from "react-hot-toast";

import { GlobalContext } from "../../../../components/utils/GlobalState";
import BuyersHeader from "../BuyersComponent/BuyersHeader";

import { Capitalize } from "../../../../components/helpers/functionHelpers";
import { convertDateFormat } from "../../../../components/helpers/convertDate";
import { useJsApiLoader, StandaloneSearchBox } from "@react-google-maps/api";

const BuyersSettings = () => {
  const { t } = useTranslation();
  const { user } = useContext(GlobalContext);
  const [userJoin, setUserJoin] = useState("");
  const [userLoading, setUserLoading] = useState(true);
  const [inputType, setInputType] = useState("password");
  const [inputTypee, setInputTypee] = useState("password");
  const [passLoader, setPassLoader] = useState(false);
  const [updateLoader, setUpdateLoader] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  const [editPassword, setEditPassword] = useState({
    currentPassword: "",
    newPassword: "",
  });

  const [editProfile, setEditProfile] = useState({
    firstName: "",
    country: "",
    businessName: "",
    email: "",
    phoneNumber: "",
    address: "",
  });

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });

  const handlePlacesChanged = () => {
    // Access the Autocomplete instance using refs
    const autocomplete = autocompleteRef.current;

    if (autocomplete) {
      const places = autocomplete.getPlaces();
      if (places && places.length > 0) {
        const selectedPlace = places[0];
        setEditProfile({
          address: selectedPlace.formatted_address || "",
        });
      }
    }
  };

  // Ref to hold the Autocomplete instance
  const autocompleteRef = React.useRef(null);

  const handleClick = (event) => {
    setIsActive((current) => !current);
  };

  const handlePasswordToggle = (e) => {
    inputType === "password" ? setInputType("text") : setInputType("password");
  };

  const handlePasswordTogglee = (e) => {
    inputTypee === "password"
      ? setInputTypee("text")
      : setInputTypee("password");
  };

  const handlePasswordChange = (e) => {
    setEditPassword({ ...editPassword, [e.target.name]: e.target.value });
  };

  const handleChange = (e) => {
    setEditProfile({ ...editProfile, [e.target.name]: e.target.value });
  };

  const getUser = () => {
    axiosInstance
      .get(`/auth/current-user`)
      .then((response) => {
        setEditProfile({
          firstName: response.data.currentUser.firstName,
        });
        if (response.data.currentUser.country) {
          setEditProfile({
            businessName: response.data.currentUser.businessName,
          });
        }
        if (response.data.currentUser.country) {
          setEditProfile({
            country: response.data.currentUser.country,
          });
        }
        if (response.data.currentUser.address) {
          setEditProfile({
            address: response.data.currentUser.address,
          });
        }
        setUserJoin(response.data.currentUser.createdAt);

        setUserLoading(false);
      })
      .catch((error) => {
        setUserLoading(false);
        console.log(error);
        if (error.message && error.message === "Network Error") {
          navigate("/no-connection");
        }
      });
  };

  useEffect(() => {
    getUser();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  const handleEditProfile = async (e) => {
    e.preventDefault();
    if (editProfile.address) {
      // to check if address exist with google before upgating the user profile
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          editProfile.address
        )}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
      );

      const data = await response.json();
      if (data.status !== "OK") {
        toast.error(
          `Invalid address. Please enter a valid address from the suggestions.`,
          {
            duration: 4000,
            style: {
              background: "#353434",
              color: "#fff",
            },
          }
        );
        return;
      } else {
        let toastId = "";
        toastId = toast.loading("loading...", {
          style: {
            background: "#353434",
            color: "#fff",
          },
        });
        setUpdateLoader(true);
        try {
          const editUserDetails = {
            firstName: editProfile.firstName,
            country: editProfile.country,
            businessName: editProfile.businessName,
            address: editProfile.address,
          };
          await axiosInstance.patch(
            "/auth/user/update-profile",
            editUserDetails
          );
          setUpdateLoader(false);
          toast.success("Your profile has been successful updated.", {
            id: toastId,
            duration: 4000,
            style: {
              background: "#353434",
              color: "#fff",
            },
          });
        } catch (error) {
          setUpdateLoader(false);
          if (!error.response.data.errors) {
            return navigate(`/no-connection`);
          }
          toast.error(`${error.response.data.errors[0].message}`, {
            id: toastId,
            duration: 4000,
            style: {
              background: "#353434",
              color: "#fff",
            },
          });
        }
      }
    } else {
      toast.error(`Please enter an address.`, {
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
    }
  };

  const handleUserPasswordChange = async (e) => {
    e.preventDefault();
    let toastId = "";
    toastId = toast.loading("loading...", {
      style: {
        background: "#353434",
        color: "#fff",
      },
    });
    setPassLoader(true);
    try {
      const editUserPassword = {
        oldPassword: editPassword.currentPassword,
        newPassword: editPassword.newPassword,
      };
      await axiosInstance.patch(`/auth/update-password`, editUserPassword);
      setPassLoader(false);
      toast.success("Your password has been successfully changed.", {
        id: toastId,
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
    } catch (error) {
      setPassLoader(false);
      if (!error.response.data.errors) {
        return navigate(`/no-connection`);
      }
      toast.error(`${error.response.data.errors[0].message}`, {
        id: toastId,
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
    }
  };

  return (
    <>
      <Toaster />
      <div>
        <div className="grid-container">
          <BuyersHeader
            title="Settings"
            handleClick={handleClick}
            user={user}
            userLoading={userLoading}
            Capitalize={Capitalize}
          />

          <BuyersSidebar isActive={isActive} handleClick={handleClick} />

          <main className="main">
            <div className="main-overview">
              <div className="overview-card">
                <div className="seller-profile-card">
                  <div className="seller-setting-profile">
                    <div className="settings__title__ctn">
                      <h4>{t("Profile")}</h4> <button>Delete Account</button>
                    </div>

                    <p>{t("Update_your_personal_details")}</p>
                  </div>
                  {!isLoaded ? (
                    <div className="lds-ctn">
                      <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  ) : (
                    <form>
                      <div className="row">
                        <div className="form-group-right col-lg-6">
                          <div className="form-group">
                            <label>{t("First_Name")}</label>
                            <div className="div-form-control">
                              {user.firstName && user.firstName}
                            </div>
                          </div>
                          <div className="form-group">
                            <label>{t("Last_Name")}</label>
                            <div className="div-form-control">
                              {user.LastName && user.LastName}
                            </div>
                          </div>
                          <div className="form-group">
                            <label>{t("Email")}</label>
                            <div className="div-form-control">
                              {user.email && user.email}
                            </div>
                          </div>
                          <div className="form-group">
                            <label>{t("Phone_number")}</label>
                            <div className="div-form-control">
                              {user.phoneNumber && user.phoneNumber}
                            </div>
                          </div>
                        </div>
                        <div className="form-group-left col-lg-6">
                          <div className="form-group">
                            <label>{t("Role")}</label>
                            <div className="div-form-control">
                              {user.role && user.role}
                            </div>
                          </div>
                          <div className="form-group">
                            <label>{t("Business_Name")}</label>
                            <input
                              className="form-control"
                              type="text"
                              id="businessname"
                              name="businessName"
                              value={editProfile.businessName}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="form-group">
                            <label>{t("Country")}</label>
                            <input
                              className="form-control"
                              type="text"
                              id="country"
                              name="country"
                              value={editProfile.country}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="form-group">
                            <label>{t("Address")}</label>
                            <StandaloneSearchBox
                              onLoad={(ref) => (autocompleteRef.current = ref)}
                              onPlacesChanged={handlePlacesChanged}
                            >
                              <input
                                className="form-control address"
                                type="text"
                                id="address"
                                name="address"
                                value={editProfile.address}
                                onChange={handleChange}
                                placeholder="Enter address"
                              />
                            </StandaloneSearchBox>
                          </div>
                        </div>
                      </div>
                      <div className="seller-footer">
                        <div className="seller-seting-submit">
                          {!updateLoader ? (
                            <button
                              type="submit"
                              className="btn btn-primary buyersavechanges"
                              onClick={handleEditProfile}
                            >
                              {t("Save_Changes")}
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="btn btn-primary buyersavechanges"
                            >
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            </button>
                          )}
                          <button
                            type="button"
                            className="buyerchangepassword btn btn-primary"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            {t("Change_Password")}
                          </button>
                        </div>
                        <div className="seller-seting-joindate">
                          <p>{t("Joined_Since")}</p>
                          <p>{convertDateFormat(userJoin)}</p>
                        </div>
                      </div>
                    </form>
                  )}
                </div>

                <div className="seller-setting-form"></div>
              </div>

              {/* modal */}
              <div
                className="modal fade"
                id="exampleModal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body mx-2">
                      <form>
                        <div className="form-group password">
                          <label>{t("Current_password")}</label>
                          <div className="form-control passwordToggle">
                            <input
                              type={inputTypee}
                              id="exampleInputPassword"
                              name="currentPassword"
                              value={editPassword.currentPassword}
                              onChange={handlePasswordChange}
                            />
                            <span
                              className={"password-icon"}
                              onClick={handlePasswordTogglee}
                            >
                              {inputTypee === "password" ? (
                                <i className="far fa-eye-slash mt-1 pt-1"></i>
                              ) : (
                                <i className="far fa-eye mt-1 pt-1"></i>
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="form-group password">
                          <label>{t("New_password")}</label>
                          <div className="form-control passwordToggle">
                            <input
                              type={inputType}
                              id="exampleInputPassword1"
                              name="newPassword"
                              value={editPassword.newPassword}
                              onChange={handlePasswordChange}
                            />
                            <span
                              className={"password-icon"}
                              onClick={handlePasswordToggle}
                            >
                              {inputType === "password" ? (
                                <i className="far fa-eye-slash mt-1 pt-1"></i>
                              ) : (
                                <i className="far fa-eye mt-1 pt-1"></i>
                              )}
                            </span>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="modal-footer mx-2 mb-5">
                      {!passLoader ? (
                        <button
                          type="submit"
                          className="buyerchangepassword  btn btn-primary"
                          onClick={handleUserPasswordChange}
                        >
                          {t("Save_changes")}
                        </button>
                      ) : (
                        <button type="submit" className="btn btn-primary">
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* end modal */}
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default ProtectedRoutes(BuyersSettings, ["BUYER", "SELLER"]);
