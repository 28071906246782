import React from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "./../../assets/img/logo.png";
import { useTranslation } from "react-i18next";

const OrderNotification = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleBackToPreviousPage = () => {
    navigate(-1);
  };
  return (
    <>
      <div className="p-4 logo">
        <Link to="/">
          <img src={logo} alt="TOFA" />
        </Link>
      </div>
      <div className="internet-ctn">
        <h1>{t("Success")}!</h1>
        <p>{t("Your_order_was_successfully_placed")} </p>
        <p>{t("Visit_your_dashboard_to_monitor")}. </p>
        <div>
          <button
            className="request-quotebtn"
            onClick={handleBackToPreviousPage}
          >
            {t("Back")}
          </button>
        </div>
      </div>
    </>
  );
};

export default OrderNotification;
