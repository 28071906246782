import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import "./PaymentSuccess.css";

import { useUpdateOrderPayment } from "../../components/hooks/useUpdateOrderPayment";
import { useSendOrderInvoiceAsEmail } from "../../components/hooks/useSendOrderInvoiceAsEmail";

const PaymentSucces = () => {
  const location = useLocation();

  const { handleOrderPaymentUpdate } = useUpdateOrderPayment();
  const { handleSendInvoice } = useSendOrderInvoiceAsEmail();

  const queryParams = new URLSearchParams(location.search);

  const paymentStatus = queryParams.get("status");

  useEffect(() => {
    const transactionId = localStorage.getItem("transactionId");
    const savedAllOrderIds = localStorage.getItem("allOrderIds");
    const allOrderIds = JSON.parse(savedAllOrderIds);
    const storedInvoiceDetails = localStorage.getItem("invoiceDetails");
    const invoiceDetails = JSON.parse(storedInvoiceDetails);

    if (paymentStatus === "success") {
      if (transactionId && allOrderIds) {
        handleOrderPaymentUpdate(
          "TransactPay",
          transactionId,
          "PAID",
          allOrderIds
        );
      }
      if (invoiceDetails) {
        handleSendInvoice(invoiceDetails);
      }
    }
  }, [paymentStatus]);

  return (
    <div className="payment-status-container">
      {!paymentStatus && (
        <div className="pending-message">
          <h2>Please contact TOFA support</h2>
          <p className="__support">
            admin@tradersofafrica.com or +2349166417373
          </p>
          <Link to="/" className="home__link">
            Back home
          </Link>
        </div>
      )}
      {paymentStatus === "success" && (
        <div className="success-message">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="64"
            height="64"
            viewBox="0 0 24 24"
            fill="none"
            stroke="green"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="success-icon"
          >
            <circle cx="12" cy="12" r="10"></circle>
            <path d="M9 12l2 2l4-4"></path>
          </svg>
          <h2>Payment Successful!</h2>
          <p>Thank you for your payment. Your transaction was successful.</p>
          <Link to="/" className="home__link">
            Back home
          </Link>
        </div>
      )}
      {paymentStatus === "failed" && (
        <div className="failed-message">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="64"
            height="64"
            viewBox="0 0 24 24"
            fill="none"
            stroke="red"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="failed-icon"
          >
            <circle cx="12" cy="12" r="10"></circle>
            <line x1="15" y1="9" x2="9" y2="15"></line>
            <line x1="9" y1="9" x2="15" y2="15"></line>
          </svg>
          <h2>Payment Failed</h2>
          <p>There was an issue with your payment. Please try again.</p>

          <p className="__support">
            Please contact TOFA support: admin@tradersofafrica.com or
            +2349166417373
          </p>
        </div>
      )}
    </div>
  );
};

export default PaymentSucces;
