import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../baseUrl";

export const useUpdateOrderStatus = () => {
  const navigate = useNavigate();

  const updateOrderStatus = async (orderIdArray) => {
    try {
      for (let i = 0; i < orderIdArray.length; i++) {
        const orderId = orderIdArray[i];

        await axiosInstance.patch(`/order`, {
          status: "PROCESSING",
          orderID: orderId,
        });
      }
    } catch (error) {
      if (!error.response.data.errors) {
        return navigate(`/no-connection`);
      }
      console.log(error);
    }
  };

  return {
    updateOrderStatus,
  };
};
