import { useState, useEffect } from "react";
import { axiosInstance } from "../baseUrl";
import { useNavigate } from "react-router-dom";

export const useGetAllReviews = () => {
  const [allReviews, setAllReviews] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const getAllReviews = async () => {
    try {
      setIsLoading(true);
      const { data } = await axiosInstance.get(`/reviews/get-total-reviews`);
      console.log("data", data);
      setAllReviews(data?.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error.message && error.message === "Network Error") {
        navigate("/no-connection");
      }
    }
  };

  useEffect(() => {
    getAllReviews();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    allReviews,
    isLoading,
    getAllReviews,
  };
};
