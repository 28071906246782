import { useState } from "react";
import { axiosInstance } from "../baseUrl";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export const useCreateDispute = () => {
  const navigate = useNavigate();

  const [disputeLoading, setDisputeLoading] = useState(false);

  const handleSubmitDispute = async (e, subject, complaints) => {
    e.preventDefault();
    setDisputeLoading(true);
    try {
      const createDispute = {
        subject: subject,
        complaint: complaints,
      };
      await axiosInstance.post(`/dispute`, createDispute);
      toast.success("Dispute created successfully.", {
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
      setDisputeLoading(false);
    } catch (error) {
      console.log(error);
      setDisputeLoading(false);
      if (!error.response.data.errors) {
        return navigate(`/no-connection`);
      }
      toast.error(`${error.response.data.errors[0].message}`, {
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
    }
  };

  return {
    handleSubmitDispute,
    disputeLoading,
  };
};
