import { numberWithCommas } from "./functionHelpers";

export const rateExchange = {
  success: true,
  timestamp: 1519296206,
  base: "USD",
  date: "2023-07-15",
  rates: {
    AUD: 1.566015,
    CAD: 1.560132,
    EUR: parseFloat(process.env.REACT_APP_CURRENCY_EUR),
    CHF: 1.154727,
    CNY: 7.827874,
    GBP: parseFloat(process.env.REACT_APP_CURRENCY_GBP),
    GHS: parseFloat(process.env.REACT_APP_CURRENCY_GHS),
    JPY: 132.360679,
    USD: parseFloat(process.env.REACT_APP_CURRENCY_USD),
    NGN: parseFloat(process.env.REACT_APP_CURRENCY_NGN),
    XOF: parseFloat(process.env.REACT_APP_CURRENCY_XOF),
  },
};
const rates = rateExchange.rates;

export const convertPrice = (price, selectedCurrency) => {
  const numericPrice = typeof price === "string" ? parseFloat(price) : price;

  if (selectedCurrency === "USD") {
    return numericPrice?.toFixed(2);
  }

  const conversionRate = rates[selectedCurrency];
  if (!conversionRate) {
    return null; // Handle unsupported or unavailable currency
  }

  const roundedPrice = numericPrice * conversionRate;

  return numberWithCommas(roundedPrice?.toFixed(2));
};
