import React, { useState, useEffect, useMemo, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { GlobalContext } from "../../../../components/utils/GlobalState";

import SellersSidebar from "../SellersComponent/SellerSideBar";

import { axiosInstance } from "../../../../components/baseUrl.jsx";
import { ProtectedRoutes } from "../../../../components/utils/ProtectedRoutes";
import SearchInput from "../../BuyersDashboard/BuyersComponent/SearchInput";
import PaginationComponent from "../../BuyersDashboard/BuyersComponent/Pagination";

import SellersHeader from "../SellersComponent/SellersHeader";
import CardOverviewLoader from "../../../../components/SkeletonLoader/CardOverviewLoader";
import TableSkeleton from "../../../../components/SkeletonLoader/TableSkeleton";
import { useGetSellersOrder } from "../../../../components/hooks/useGetSellersOrder";
import {
  Capitalize,
  numberWithCommas,
} from "../../../../components/helpers/functionHelpers";
import { convertDateFormat } from "../../../../components/helpers/convertDate";

const SellersRfqs = () => {
  const { t } = useTranslation();
  const [sellerRfqs, setSellerRfqs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const ITEMS_PER_PAGE = 10;
  const [noMatch, setNoMatch] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  const { user } = useContext(GlobalContext);
  const { summary, orderLoading } = useGetSellersOrder();

  const handleClick = (event) => {
    setIsActive((current) => !current);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  useEffect(() => {
    axiosInstance
      .get(`/rfq/seller`)
      .then((response) => {
        setSellerRfqs(response.data.data.rfqs);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        if (error.message && error.message === "Network Error") {
          navigate("/no-connection");
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rfqsData = useMemo(() => {
    let computedrfqs = sellerRfqs;

    if (search) {
      computedrfqs = computedrfqs.filter(
        (rfq) =>
          rfq.productName.toLowerCase().includes(search.toLowerCase()) ||
          rfq.status.toLowerCase().includes(search.toLowerCase()) ||
          rfq.destinationPort.toLowerCase().includes(search.toLowerCase())
      );
      if (computedrfqs.length < 1) {
        setNoMatch(true);
        setTotalItems(0);
      } else if (computedrfqs.length > 0) {
        setNoMatch(false);
      }
    } else {
      setNoMatch(false);
    }

    setTotalItems(computedrfqs.length);

    return computedrfqs.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [sellerRfqs, currentPage, search]);

  return (
    <>
      <div className="grid-container">
        <SellersHeader handleClick={handleClick} user={user} title="My RFQ's" />

        <SellersSidebar isActive={isActive} handleClick={handleClick} />

        <main className="main">
          {orderLoading ? (
            <CardOverviewLoader />
          ) : (
            <div className="row">
              <div className="col-lg-4 col-sm-6 col-12">
                <div className="overview-card">
                  <div>
                    <h2>{t("All_RFQ's")}</h2>
                    {/* <p className="order-p">
                  Scroll down for Detailed RfQ’s history
                </p> */}
                    <div className="d-flex justify-content-between mt-4">
                      <h3>
                        {summary.total_number_of_enquiries &&
                          numberWithCommas(summary.total_number_of_enquiries)}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-12">
                <div className="overview-card">
                  <div>
                    <h2>{t("Sent_Responses")}</h2>
                    {/* <p className="order-p">
                  Click to view detailed responses you’ve sent to RFQ’s
                  suppliers posted
                </p> */}
                    <div className="d-flex justify-content-between mt-4">
                      <h3>
                        {summary.total_responded_quote &&
                          numberWithCommas(summary.total_responded_quote)}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-12">
                <div className="overview-card">
                  <div>
                    <h2>{t("Awaiting_Responses")}</h2>
                    {/* <p className="order-p">
                  Detailed transaction history is on the order page
                </p> */}
                    <div className="d-flex justify-content-between mt-4">
                      <h3>
                        {summary.total_awaiting_response_quote &&
                          numberWithCommas(
                            summary.total_awaiting_response_quote
                          )}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="section-title-ctn">
            <h1 className="section-title">{t("All_RFQ's")}</h1>
            <div className="header__search">
              <SearchInput
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
                placeholder="Search for RFQs, RFQ status and more"
              />
            </div>
          </div>

          {loading ? (
            <TableSkeleton />
          ) : (
            <>
              {sellerRfqs && sellerRfqs.length > 0 ? (
                <div className="main-overview">
                  <div className="overview-card no-padding">
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">{t("Date")}</th>
                            <th scope="col">{t("Enquiry_Info")}</th>
                            <th scope="col">{t("Quantity")} </th>
                            <th scope="col">{t("Shipping_Terms")}</th>
                            <th scope="col">{t("Destination")}</th>
                            <th scope="col">{t("Status")}</th>
                            <th scope="col">{t("Action")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {rfqsData.map((rfq, index) => (
                            <tr key={index}>
                              <td>
                                {rfq.createdAt &&
                                  convertDateFormat(rfq.createdAt)}
                              </td>
                              <td>
                                {rfq.productName && Capitalize(rfq.productName)}
                              </td>
                              <td>
                                {rfq && rfq.quantityRequired} {rfq.unit}
                              </td>
                              <td>{rfq && rfq.termsOfTrade}</td>
                              <td>{rfq && rfq.destinationPort}</td>
                              <td>
                                {rfq.status === "AWAITING_RESPONSE" && (
                                  <div className="text-warning">
                                    {t("Awaiting_Response")}
                                  </div>
                                )}
                                {rfq.status === "RESPONDED" && (
                                  <div className="text-success">
                                    {t("Responded")}
                                  </div>
                                )}
                              </td>
                              <td>
                                <Link to={`/seller/rfq-info/${rfq.id}`}>
                                  {t("view")}
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="empty-state">
                  <h3>{t("There_are_no_RFQs_created_for_your_product.")}</h3>
                  <p>
                    {t("Create_a_product")}{" "}
                    <Link to="/create-new-product">{t("here")}. </Link>
                    {t("All_your_RFQ_will")}
                  </p>
                </div>
              )}
              {noMatch === true ? (
                <div className="empty-state">
                  <h4>{t("No_results_found")}</h4>
                  <p>{t("No_RFQ_result_matched_your_criteria.")}</p>
                </div>
              ) : (
                <PaginationComponent
                  total={totalItems}
                  itemsPerPage={ITEMS_PER_PAGE}
                  currentPage={currentPage}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              )}
            </>
          )}
        </main>
      </div>
    </>
  );
};

export default ProtectedRoutes(SellersRfqs, ["SELLER"]);
