import { axiosInstance } from "../baseUrl";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export const useDeleteProductImg = () => {
  const [rerender, setRerender] = useState(true);
  const navigate = useNavigate;

  const handleDeleteProductImage = async (imageId) => {
    try {
      await axiosInstance.delete(`/product/image/${imageId}`);
      toast.success("Product Image deleted!", {
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
      setRerender((prev) => !prev);
    } catch (error) {
      console.log(error);
      if (!error.response.data.errors) {
        return navigate(`/no-connection`);
      }
      toast.error(`${error.response.data.errors[0].message}`, {
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
    }
  };
  return { handleDeleteProductImage, rerender };
};
