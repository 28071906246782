import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Authentication.css";
import SiteLogo from "../../assets/img/logo.png";
import { axiosInstance } from "../../components/baseUrl.jsx";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    let toastId = "";
    toastId = toast.loading("loading...", {
      style: {
        background: "#353434",
        color: "#fff",
      },
    });
    try {
      const forgotPassword = {
        email: email,
      };
      await axiosInstance.post(`/auth/forgot-password`, forgotPassword);
      setEmail("");
      toast.success(`A password reset link has been sent to ${email}`, {
        id: toastId,
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      if (!error.response.data.errors) {
        return navigate(`/no-connection`);
      }
      toast.error(`${error.response.data.errors[0].message}`, {
        id: toastId,
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
    }
  };

  return (
    <>
      <div>
        <Toaster />
        <section className="auth">
          <div className="auth-lhs">
            <div className="auth-lhs-header">
              <Link to="/">
                {" "}
                <img className="site-logo" src={SiteLogo} alt="" />
              </Link>
            </div>
          </div>
          <div className="auth-rhs">
            <div className="auth-rhs-header d-flex justify-content-between">
              <img className="site-logo mobile-only" src={SiteLogo} alt="" />
              <p>
                <Link to="/support">
                  <i className="fas fa-info-circle support-icon me-2">
                    {" "}
                    {t("Customer_Support")}
                  </i>
                </Link>
              </p>
            </div>
            <div className="auth-rhs-content d-flex align-items-center">
              <div className="rhs-inner">
                <h2>{t("Forgot_Password")}</h2>
                <p>
                  {t("Enter_registered_email_address_to_reset_your_password")}
                </p>
                <div className="auth-account-wrap">
                  <form className="auth-form" onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                      >
                        {t("Email_address")}
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={email}
                        name="email"
                        onChange={handleEmail}
                      />
                    </div>
                    {loading ? (
                      <button type="submit" className="btn btn-danger">
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </button>
                    ) : (
                      <button type="submit" className="btn btn-danger">
                        {t("Reset_Password")}
                      </button>
                    )}

                    <p>
                      {t("Remember_your_password")}?{" "}
                      <Link to="/login" className="user-link">
                        {t("Login_here")}
                      </Link>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ForgotPassword;
