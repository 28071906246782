import { axiosInstance } from "../baseUrl";

export const useCreateMoMoFootSoldierOrder = () => {
  const handleCreateMoMoFootSoldierOrder = async (orderDetails) => {
    try {
      await axiosInstance.post(`/api/v2/ref-order/create`, orderDetails);
    } catch (error) {
      console.log("error", error);
    }
  };
  return {
    handleCreateMoMoFootSoldierOrder,
  };
};
