import React, { useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import "./Authentication.css";
import SiteLogo from "../../assets/img/logo.png";
import { axiosInstance } from "../../components/baseUrl.jsx";

import toast, { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { toastOptions } from "../../components/helpers/functionHelpers.js";

const ResetPassword = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { resetToken } = useParams();
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [inputType, setInputType] = useState("password");
  const [confirmInputType, setConfirmInputType] = useState("password");
  const [passwordInput, setPasswordInput] = useState({
    password: "",
    confirmPassword: "",
  });

  const handlePasswordChange = (evnt) => {
    const passwordInputValue = evnt.target.value.trim();
    const passwordInputFieldName = evnt.target.name;
    const NewPasswordInput = {
      ...passwordInput,
      [passwordInputFieldName]: passwordInputValue,
    };
    setPasswordInput(NewPasswordInput);
  };

  const handlePasswordToggle = (e) => {
    inputType === "password" ? setInputType("text") : setInputType("password");
  };
  const handleConfirmPasswordToggle = (e) => {
    confirmInputType === "password"
      ? setConfirmInputType("text")
      : setConfirmInputType("password");
  };
  const navigate = useNavigate();
  const handleValidation = (evnt) => {
    const passwordInputValue = evnt.target.value.trim();
    const passwordInputFieldName = evnt.target.name;
    //htmlFor password
    if (passwordInputFieldName === "password") {
      const uppercaseRegExp = /(?=.*?[A-Z])/;
      const lowercaseRegExp = /(?=.*?[a-z])/;
      const digitsRegExp = /(?=.*?[0-9])/;
      const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
      const minLengthRegExp = /.{6,}/;
      const passwordLength = passwordInputValue.length;
      const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
      const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
      const digitsPassword = digitsRegExp.test(passwordInputValue);
      const specialCharPassword = specialCharRegExp.test(passwordInputValue);
      const minLengthPassword = minLengthRegExp.test(passwordInputValue);
      let errMsg = "";
      if (passwordLength === 0) {
        errMsg = "Password is empty";
      } else if (!uppercasePassword) {
        errMsg = "At least one Uppercase";
      } else if (!lowercasePassword) {
        errMsg = "At least one Lowercase";
      } else if (!digitsPassword) {
        errMsg = "At least one digit";
      } else if (!specialCharPassword) {
        errMsg = "At least one Special Characters";
      } else if (!minLengthPassword) {
        errMsg = "At least minumum 6 characters";
      } else {
        errMsg = "";
      }
      setPasswordError(errMsg);
    }
    // htmlFor confirm password
    if (
      passwordInputFieldName === "confirmPassword" ||
      (passwordInputFieldName === "password" &&
        passwordInput.confirmPassword.length > 0)
    ) {
      if (passwordInput.confirmPassword !== passwordInput.password) {
        setConfirmPasswordError("Confirm password is not match");
      } else {
        setConfirmPasswordError("");
      }
    }
  };
  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (passwordInput.confirmPassword !== passwordInput.password) {
      setConfirmPasswordError("Confirm password is not a match");
      toast.error("Confirm password is not a match", toastOptions);
    }
    try {
      const changePassword = {
        password: passwordInput.password,
      };
      await axiosInstance.post(
        `/auth/reset-password/${resetToken}`,
        changePassword
      );
      setPasswordInput({
        password: "",
        confirmPassword: "",
      });
      toast.success(
        "Your Password has been changed successfully.",
        toastOptions
      );
      setTimeout(() => {
        navigate(`/login`);
      }, 6800);
    } catch (err) {
      setLoading(false);
      if (err.response.data.errors[0].message) {
        toast.error(`${err.response.data.errors[0].message}`, toastOptions);
      } else {
        toast.error(`No internet or server down!`, toastOptions);
      }

      console.log(err);
    }
  };

  return (
    <>
      <div>
        <Toaster />
        <section className="auth">
          <div className="auth-lhs">
            <div className="auth-lhs-header">
              <Link to="/">
                <img className="site-logo" src={SiteLogo} alt="" />
              </Link>
            </div>
          </div>
          <div className="auth-rhs">
            <div className="auth-rhs-header d-flex justify-content-between">
              <img className="site-logo mobile-only" src={SiteLogo} alt="" />
              <p>
                <Link to="/support">
                  <i className="fas fa-info-circle support-icon me-2">
                    {" "}
                    {t("Customer_Support")}
                  </i>
                </Link>
              </p>
            </div>
            <div className="auth-rhs-content d-flex align-items-center">
              <div className="rhs-inner">
                <h2>{t("Remember_your_password")}</h2>
                <p>{t("Set_a_new_password_below_for_your_account")}</p>
                <div className="auth-account-wrap">
                  <form onSubmit={handleSubmit} className="auth-form">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputPassword1"
                        className="form-label"
                      >
                        {t("New_Password")}
                      </label>
                      <div className="form-control passwordToggle">
                        <input
                          type={inputType}
                          id="exampleInputPassword1"
                          name="password"
                          value={passwordInput.password}
                          onChange={handlePasswordChange}
                          onKeyUp={handleValidation}
                        />
                        <span
                          className={"password-icon"}
                          onClick={handlePasswordToggle}
                        >
                          {inputType === "password" ? (
                            <i className="far fa-eye-slash mt-1 pt-1"></i>
                          ) : (
                            <i className="far fa-eye mt-1 pt-1"></i>
                          )}
                        </span>
                      </div>
                      <p className="error-message">{passwordError}</p>
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputPassword1"
                        className="form-label"
                      >
                        {t("Confirm_New_Password")}
                      </label>
                      <div className="form-control passwordToggle">
                        <input
                          type={confirmInputType}
                          id="exampleInputPassword1"
                          name="confirmPassword"
                          value={passwordInput.confirmPassword}
                          onChange={handlePasswordChange}
                          onKeyUp={handleValidation}
                        />
                        <span
                          className={"password-icon"}
                          onClick={handleConfirmPasswordToggle}
                        >
                          {inputType === "password" ? (
                            <i className="far fa-eye-slash mt-1 pt-1"></i>
                          ) : (
                            <i className="far fa-eye mt-1 pt-1"></i>
                          )}
                        </span>
                      </div>
                      <p className="error-message">{confirmPasswordError}</p>
                    </div>
                    {loading ? (
                      <button type="submit" className="btn btn-danger">
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </button>
                    ) : (
                      <button type="submit" className="btn btn-danger">
                        {t("Reset_Password")}
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ResetPassword;
