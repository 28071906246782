import { axiosInstance } from "../baseUrl";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export const useSatisfyOrder = () => {
  const navigate = useNavigate();

  const updateSatisfiedStatus = async (orderId, satisfiedStatus) => {
    try {
      await axiosInstance.patch("/order", {
        orderID: orderId,
        buyerSatisfaction: satisfiedStatus,
      });

      // toast.success("Feedback sent successfully", {
      //   duration: 4000,
      //   style: {
      //     background: "#353434",
      //     color: "#fff",
      //   },
      // });
    } catch (error) {
      console.log(error);

      if (!error.response.data.errors) {
        return navigate(`/no-connection`);
      }

      toast.error(`${error.response.data.errors[0].message}`, {
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
    }
  };
  return {
    updateSatisfiedStatus,
  };
};
