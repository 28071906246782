import axios from "axios";
import { createContext, useEffect, useState } from "react";
import { currencies } from "../helpers/functionHelpers";

export const CurrencyExchangeContext = createContext();

const CurrencyExchangeState = ({ children }) => {
  const [loadingExchangeRate, setLoadingExchangeRate] = useState(false);
  const [allExhabgeRate, setAllExhangeRate] = useState({});

  const exchangeRateKey = process.env.REACT_APP_EXCHANGERATE_API_KEY;

  const handleGetExhangeRate = async () => {
    setLoadingExchangeRate(true);
    try {
      const { data } = await axios.get(
        `https://v6.exchangerate-api.com/v6/${exchangeRateKey}/latest/USD`
      );
      setAllExhangeRate(data);
      localStorage.setItem("lastExchangeRate", JSON.stringify(data));
      setLoadingExchangeRate(false);
    } catch (error) {
      setLoadingExchangeRate(false);
      console.log("error from fetching exchange rate", error);
      const exchangeDetails = localStorage.getItem("lastExchangeRate");
      if (exchangeDetails) {
        const exchangeRate = JSON.parse(exchangeDetails);
        setAllExhangeRate(exchangeRate);
      }
    }
  };

  useEffect(() => {
    handleGetExhangeRate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allExhabgeRate?.time_next_update_utc]);

  const convertCurrency = (price, fromCurrency, toCurrency) => {
    // const conversionRates = allExhabgeRate?.conversion_rates;
    const conversionRates = currencies?.conversion_rates;
    const fromRate = conversionRates[fromCurrency];
    const toRate = conversionRates[toCurrency];

    if (!price || !fromRate || !toRate) {
      return 0;
    }

    if (fromRate === undefined || toRate === undefined) {
      throw new Error("Invalid currency code");
    }

    // Convert the price to USD first, then to the target currency
    const priceInUSD = price / fromRate;
    const convertedPrice = priceInUSD * toRate;

    return convertedPrice.toFixed(2);
  };

  function getLastUpdateAndRate(currencyCode) {
    // const { conversion_rates } = allExhabgeRate;
    const { conversion_rates } = currencies;
    const rate = conversion_rates[currencyCode];

    if (rate === undefined) {
      throw new Error("Invalid currency code");
    }

    // const date = new Date(time_last_update_utc);
    // const formattedDate = date.toLocaleString("en-US", {
    //   weekday: "short",
    //   year: "numeric",
    //   month: "short",
    //   day: "numeric",
    //   hour: "2-digit",
    //   minute: "2-digit",
    //   hour12: true,
    // });

    return `Current rate: 1 USD = ${rate.toFixed(2)} ${currencyCode}`;
  }

  const value = { convertCurrency, loadingExchangeRate, getLastUpdateAndRate };

  return (
    <CurrencyExchangeContext.Provider value={value}>
      {children}
    </CurrencyExchangeContext.Provider>
  );
};

export default CurrencyExchangeState;
