import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../baseUrl";

export const useAddProductImage = () => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImageFiles, setSelectedImageFiles] = useState(null);

  const navigate = useNavigate();

  const onSelectFile = (e) => {
    const selectedFiles = e.target.files;
    const selectedFilesArray = Array.from(selectedFiles);
    // const invalidFiles = [];

    //handling error when image is more than 350kb
    // selectedFilesArray.forEach((file) => {
    //   const fileSize = Math.round(file.size / 1024);
    //   if (fileSize > 350) {
    //     invalidFiles.push(file.name);
    //   }
    // });
    // if (invalidFiles.length === 1) {
    //   const errorMessage = `Image is too large. Image must not exceed 350KB! `;
    //   toast.error(`${errorMessage} `, {
    //     duration: 4000,
    //     style: {
    //       background: "#353434",
    //       color: "#fff",
    //     },
    //   });
    //   e.target.value = null;
    // } else if (invalidFiles.length > 1) {
    //   const errorMessage = `The following images exceed the maximum size of 350KB:\n${invalidFiles.join(
    //     ",\n"
    //   )}`;
    //   toast.error(`${errorMessage} `, {
    //     duration: 4000,
    //     style: {
    //       background: "#353434",
    //       color: "#fff",
    //     },
    //   });
    //   e.target.value = null;
    // }
    const imagesArray = selectedFilesArray.map((file) => {
      return URL.createObjectURL(file);
    });
    setSelectedImages((previousImages) => previousImages.concat(imagesArray));
    setSelectedImageFiles(selectedFiles);
  };

  const handleUploadProductImage = async (productId) => {
    try {
      const formData = new FormData();

      for (let i = 0; i < selectedImageFiles.length; i++) {
        formData.append("images", selectedImageFiles[i]);
      }

      await axiosInstance.post(`/product/images/${productId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {
      console.log(error);
      if (!error.response.data.errors) {
        return navigate(`/no-connection`);
      }
      toast.error(`${error.response.data.errors[0].message}`, {
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
    }
  };

  return {
    onSelectFile,
    selectedImages,
    handleUploadProductImage,
    setSelectedImages,
  };
};
