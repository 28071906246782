import React from "react";
import "./ProductDescription.css";

const ProductDescription = ({ isActive, productInfo }) => {
  return (
    <div>
      <div className={isActive ? "not-active" : "product-description-content"}>
        <p>
          {productInfo.productDescription && productInfo.productDescription}
        </p>
      </div>
    </div>
  );
};

export default ProductDescription;
