import React, { useState, useEffect, useMemo, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import BuyersSidebar from "../BuyersComponent/BuyerSideBar";
import PaginationComponent from "../BuyersComponent/Pagination";
import UserSearch from "./UserSearch";

import { axiosInstance } from "../../../../components/baseUrl";
import { ProtectedRoutes } from "../../../../components/utils/ProtectedRoutes";
import { GlobalContext } from "../../../../components/utils/GlobalState";

import dayjs from "dayjs";

import toast, { Toaster } from "react-hot-toast";
import { Capitalize } from "../../../../components/helpers/functionHelpers";
import { convertDateFormat } from "../../../../components/helpers/convertDate";
import { BigCard } from "../../../../components/SkeletonLoader/BigCard";

const BuyersMessage = () => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const ITEMS_PER_PAGE = 6;
  const [noMatch, setNoMatch] = useState(false);
  const [allMessages, setAllMessages] = useState([]);
  const [msgInfo, setMsgInfo] = useState({});
  const [msgLoader, setMsgLoader] = useState(false);
  const [msgLoad, setMsgLoad] = useState(false);
  const [loader, setLoader] = useState(false);
  const [view, setView] = useState(false);
  const [resp, setResp] = useState([]);
  const [response, setResponse] = useState({
    msg: "",
  });
  const navigate = useNavigate();
  const { user, userLoading } = useContext(GlobalContext);

  const respReverse = [];
  for (let i = resp.length - 1; i >= 0; i--) {
    respReverse.push(resp[i]);
  }

  const handleClick = (event) => {
    setIsActive((current) => !current);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  const handleRespChange = (e) => {
    setResponse({
      ...response,
      [e.target.name]: e.target.value,
    });
  };

  const handleResponseMsg = async (e) => {
    let toastId = "";
    e.preventDefault();
    setLoader(true);
    toastId = toast.loading("loading...", {
      style: {
        background: "#353434",
        color: "#fff",
      },
    });
    try {
      const responseDetails = {
        from: msgInfo.message.recieverID,
        messageID: msgInfo.message.id,
        message: response.msg,
      };
      await axiosInstance.post(
        `/message/send-message-response`,
        responseDetails
      );
      toast.success("Message sent.", {
        id: toastId,
        duration: 3000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
      setLoader(false);
      setResponse({
        msg: "",
      });
      setTimeout(() => {
        window.location.reload();
      }, 6300);
    } catch (error) {
      console.log(error);
      setLoader(false);
      if (!error.response.data.errors) {
        return navigate(`/no-connection`);
      }
      toast.error(`${error.response.data.errors[0].message}`, {
        id: toastId,
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
    }
  };

  const viewMsg = async (msgId) => {
    setMsgLoad(true);
    try {
      const { data } = await axiosInstance.get(`/message/${msgId}`);
      setMsgInfo(data.data);
      setResp(data.data.message.response);
      setMsgLoad(false);
      setMsgLoader(true);
      setView(true);
    } catch (error) {
      console.log(error);
      setMsgLoad(false);
      setMsgLoader(false);
      if (error.message && error.message === "Network Error") {
        navigate("/no-connection");
      }
    }
  };

  useEffect(() => {
    axiosInstance
      .get(`/message/user-messages`)
      .then((response) => {
        setAllMessages(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        if (error.message && error.message === "Network Error") {
          navigate("/no-connection");
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const messagesData = useMemo(() => {
    let computedMessages = allMessages;

    if (search) {
      computedMessages = computedMessages.filter(
        (message) =>
          message.receiverDetails.firstName
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          message.receiverDetails.LastName.toLowerCase().includes(
            search.toLowerCase()
          ) ||
          message.message.message.toLowerCase().includes(search.toLowerCase())
      );
      if (computedMessages.length < 1) {
        setNoMatch(true);
        setTotalItems(0);
      } else if (computedMessages.length > 0) {
        setNoMatch(false);
      }
    } else {
      setNoMatch(false);
    }

    setTotalItems(computedMessages.length);

    return computedMessages.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [allMessages, currentPage, search]);

  return (
    <div>
      <Toaster />
      <div className="grid-container">
        <div className={"menuu-icon"} onClick={handleClick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-6 h-6"
          >
            <path
              fillRule="evenodd"
              d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <header className="header">
          <div className="header__message me-5">
            <h2>{t("Message_Center")}</h2>
          </div>
        </header>

        <BuyersSidebar isActive={isActive} handleClick={handleClick} />

        {userLoading ? (
          <BigCard />
        ) : loading ? (
          <BigCard />
        ) : (
          <main className="main">
            <div className="chat-main web-version">
              <div className="main-overview">
                <div className="overview-card-userlist">
                  <UserSearch
                    onSearch={(value) => {
                      setSearch(value);
                      setCurrentPage(1);
                    }}
                  />
                  <div className="userlist">
                    {messagesData &&
                      messagesData.map((user, index) => (
                        <div
                          className="userlist-container my-4 mx-2"
                          onClick={(e) => viewMsg(user.message.id)}
                          key={index}
                        >
                          <div className="userlist-textImage">
                            <div className="flex-shrink-0 user-area-art  first-letter my-1">
                              {" "}
                              {user.receiverDetails &&
                                Capitalize(user.receiverDetails.firstName)
                                  .charAt(0)
                                  .toUpperCase()}
                            </div>

                            <div className="userlist-info">
                              <h6>
                                {user.receiverDetails &&
                                  Capitalize(
                                    user.receiverDetails.firstName
                                  )}{" "}
                                {user.receiverDetails &&
                                  Capitalize(user.receiverDetails.LastName)}
                              </h6>
                              <p>
                                {user.message &&
                                user.message.message.length > 25 ? (
                                  <span>
                                    {user.message.message.slice(0, 25)}...
                                  </span>
                                ) : (
                                  <span>{user.message.message}</span>
                                )}
                              </p>
                            </div>
                          </div>

                          <div className="userlist-time">
                            <p>
                              {dayjs(user.message.createdAt).format("hh:mm a")}
                            </p>
                            {user.message.read ? (
                              <span className="doublecheck">
                                <i className="fas fa-check"></i>
                              </span>
                            ) : (
                              <span className="not-delivered">
                                <i className="fas fa-check"></i>
                              </span>
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                  {noMatch === true ? (
                    <div className="empty-state">
                      <p>{t("No_message_or_user_matched_your_search")}</p>
                    </div>
                  ) : (
                    <PaginationComponent
                      total={totalItems}
                      itemsPerPage={ITEMS_PER_PAGE}
                      currentPage={currentPage}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  )}
                </div>
              </div>
              <div className="main-overview">
                {msgLoad ? (
                  <div className="gooey">
                    <span className="dot"></span>
                    <div className="dots">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                ) : (
                  <div>
                    {msgLoader ? (
                      <div className="overview-card">
                        <div className="message-center-header">
                          <div className="message-header-profilepic userlist-textImage">
                            <div className="flex-shrink-0 user-area-art  second-letter">
                              {" "}
                              {msgInfo.receiverDetails &&
                                Capitalize(msgInfo.receiverDetails.firstName)
                                  .charAt(0)
                                  .toUpperCase()}
                            </div>

                            <div className="message-header-status">
                              <h6>
                                {msgInfo.receiverDetails &&
                                  Capitalize(
                                    msgInfo.receiverDetails.firstName
                                  )}{" "}
                                {msgInfo.receiverDetails &&
                                  Capitalize(msgInfo.receiverDetails.LastName)}
                              </h6>
                              <p>{t("Online")}</p>
                            </div>
                          </div>

                          <div className="message-file-picker">
                            <span>
                              <i
                                className="fas fa-camera-retro"
                                style={{ color: "#282828" }}
                              ></i>
                            </span>
                            <span>
                              <i className="fas fa-paperclip"></i>
                            </span>
                            <span>
                              <i className="fas fa-ellipsis-v"></i>
                            </span>
                          </div>
                        </div>
                        <div className="horizontal-line"></div>
                        <div className="chat-area">
                          <div className="message-dialogue">
                            <div className="chat-msg sender">
                              <div>
                                <p>
                                  {msgInfo.message && msgInfo.message.message}
                                </p>
                                <p className="chat-stamp">
                                  {/* {dayjs(msgInfo.createdAt).format("hh:mm a")}{" "} */}
                                  {msgInfo.message.createdAt &&
                                    convertDateFormat(
                                      msgInfo.message.createdAt
                                    )}
                                </p>
                              </div>
                            </div>
                            {respReverse.length > 0 &&
                              respReverse.map((response, index) => (
                                <div
                                  className={`chat-msg ${
                                    user.id === response.senderID
                                      ? "sender"
                                      : "receiver"
                                  }`}
                                  key={index}
                                >
                                  <div>
                                    <p>
                                      {response.messageValue &&
                                        response.messageValue}
                                    </p>
                                    <p className="chat-stamp">
                                      {response.createdAt &&
                                        convertDateFormat(response.createdAt)}
                                    </p>
                                  </div>
                                </div>
                              ))}
                          </div>
                          <div className="MessageCenterform">
                            <form
                              className="MessageCenterform-chatmessage"
                              onSubmit={handleResponseMsg}
                            >
                              <textarea
                                placeholder=" Type message"
                                name="msg"
                                value={response.msg}
                                onChange={handleRespChange}
                                onKeyDown={(event) => {
                                  event.key === "Enter" &&
                                    handleResponseMsg(event);
                                }}
                              ></textarea>
                              {!loader ? (
                                <button>
                                  <i className="far fa-paper-plane"></i>
                                </button>
                              ) : (
                                <button>
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                </button>
                              )}
                            </form>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <p>{t("Select_a_seller_to_chat_with...")}</p>
                    )}
                  </div>
                )}
              </div>
            </div>

            {/* mobile */}
            <div className="chat-main mobile-version">
              <div className="main-overview">
                {msgLoad ? (
                  <div className="gooeey">
                    <span className="dot"></span>
                    <div className="dots">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                ) : (
                  <div>
                    {view ? (
                      <div className="overview-card">
                        <p
                          onClick={(e) => setView(false)}
                          style={{ cursor: "pointer", color: "#282828" }}
                        >
                          <i className="fas fa-arrow-left"></i> {t("Back")}
                        </p>
                        <div className="message-center-header">
                          <div className="message-header-profilepic userlist-textImage">
                            <div className="flex-shrink-0 user-area-art  second-letter">
                              {" "}
                              {msgInfo.receiverDetails &&
                                Capitalize(msgInfo.receiverDetails.firstName)
                                  .charAt(0)
                                  .toUpperCase()}
                            </div>

                            <div className="message-header-status">
                              <h6>
                                {msgInfo.receiverDetails &&
                                  Capitalize(
                                    msgInfo.receiverDetails.firstName
                                  )}{" "}
                                {msgInfo.receiverDetails &&
                                  Capitalize(msgInfo.receiverDetails.LastName)}
                              </h6>
                              <p>{t("Online")}</p>
                            </div>
                          </div>

                          <div className="message-file-picker">
                            <span>
                              <i
                                className="fas fa-camera-retro"
                                style={{ color: "#282828" }}
                              ></i>
                            </span>
                            <span>
                              <i className="fas fa-paperclip"></i>
                            </span>
                            <span>
                              <i className="fas fa-ellipsis-v"></i>
                            </span>
                          </div>
                        </div>
                        <div className="horizontal-line"></div>
                        <div className="chat-area">
                          <div className="message-dialogue">
                            <div className="chat-msg sender">
                              <div>
                                <p>
                                  {msgInfo.message && msgInfo.message.message}
                                </p>
                                <p className="chat-stamp">
                                  {/* {dayjs(msgInfo.createdAt).format("hh:mm a")}{" "} */}
                                  {msgInfo.message.createdAt &&
                                    convertDateFormat(
                                      msgInfo.message.createdAt
                                    )}
                                </p>
                              </div>
                            </div>
                            {respReverse.length > 0 &&
                              respReverse.map((response, index) => (
                                <div
                                  className={`chat-msg ${
                                    user.id === response.senderID
                                      ? "sender"
                                      : "receiver"
                                  }`}
                                  key={index}
                                >
                                  <div>
                                    <p>
                                      {response.messageValue &&
                                        response.messageValue}
                                    </p>
                                    <p className="chat-stamp">
                                      {response.createdAt &&
                                        convertDateFormat(response.createdAt)}
                                    </p>
                                  </div>
                                </div>
                              ))}
                          </div>
                          <div className="MessageCenterform">
                            <form
                              className="MessageCenterform-chatmessage"
                              onSubmit={handleResponseMsg}
                            >
                              <textarea
                                placeholder=" Type message"
                                name="msg"
                                value={response.msg}
                                onChange={handleRespChange}
                                onKeyDown={(event) => {
                                  event.key === "Enter" &&
                                    handleResponseMsg(event);
                                }}
                              ></textarea>
                              {!loader ? (
                                <button>
                                  <i className="far fa-paper-plane"></i>
                                </button>
                              ) : (
                                <button>
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                </button>
                              )}
                            </form>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="main-overview">
                        <div className="overview-card-userlist">
                          <UserSearch
                            onSearch={(value) => {
                              setSearch(value);
                              setCurrentPage(1);
                            }}
                          />
                          <div className="userlist">
                            {messagesData &&
                              messagesData.map((user, index) => (
                                <div
                                  className="userlist-container my-4 mx-2"
                                  onClick={(e) => viewMsg(user.message.id)}
                                  key={index}
                                >
                                  <div className="userlist-textImage">
                                    <div className="flex-shrink-0 user-area-art  first-letter my-1">
                                      {" "}
                                      {user.receiverDetails &&
                                        Capitalize(
                                          user.receiverDetails.firstName
                                        )
                                          .charAt(0)
                                          .toUpperCase()}
                                    </div>

                                    <div className="userlist-info">
                                      <h6>
                                        {user.receiverDetails &&
                                          Capitalize(
                                            user.receiverDetails.firstName
                                          )}{" "}
                                        {user.receiverDetails &&
                                          Capitalize(
                                            user.receiverDetails.LastName
                                          )}
                                      </h6>
                                      <p>
                                        {user.message &&
                                        user.message.message.length > 25 ? (
                                          <span>
                                            {user.message.message.slice(0, 25)}
                                            ...
                                          </span>
                                        ) : (
                                          <span>{user.message.message}</span>
                                        )}
                                      </p>
                                    </div>
                                  </div>

                                  <div className="userlist-time">
                                    <p>
                                      {dayjs(user.message.createdAt).format(
                                        "hh:mm a"
                                      )}
                                    </p>
                                    {user.message.read ? (
                                      <span className="doublecheck">
                                        <i className="fas fa-check"></i>
                                      </span>
                                    ) : (
                                      <span className="not-delivered">
                                        <i className="fas fa-check"></i>
                                      </span>
                                    )}
                                  </div>
                                </div>
                              ))}
                          </div>
                          {noMatch === true ? (
                            <div className="empty-state">
                              <p>{t("No_message_or_user_matched_your_search")}</p>
                            </div>
                          ) : (
                            <PaginationComponent
                              total={totalItems}
                              itemsPerPage={ITEMS_PER_PAGE}
                              currentPage={currentPage}
                              onPageChange={(page) => setCurrentPage(page)}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            {/* end mobile */}
          </main>
        )}
      </div>
    </div>
  );
};

export default ProtectedRoutes(BuyersMessage, ["BUYER", "SELLER"]);
