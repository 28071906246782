import React, { useEffect, useState } from "react";

const Properties = ({ productInfo, setCurrentItem }) => {
  const [firstValues, setFirstValues] = useState([]);
  const [secondValues, setSecondValues] = useState([]);
  const [thirdValues, setThirdValues] = useState([]);
  const [fourthValues, setFourthValues] = useState([]);
  const [fifthValues, setFifthValues] = useState([]);
  const [selectedFirstValue, setSelectedFirstValue] = useState(null);
  const [selectedSecondValue, setSelectedSecondValue] = useState(null);
  const [selectedThirdValue, setSelectedThirdValue] = useState(null);
  const [selectedFourthValue, setSelectedFourthValue] = useState(null);
  const [selectedFifthValue, setSelectedFifthValue] = useState(null);

  const extractValues = () => {
    const firstSet = new Set();
    const secondSet = new Set();
    const thirdSet = new Set();
    const fouthSet = new Set();
    const fifthSet = new Set();

    productInfo?.ae_item_sku_info_dtos?.ae_item_sku_info_d_t_o?.forEach(
      (skuItem) => {
        const properties = skuItem.ae_sku_property_dtos.ae_sku_property_d_t_o;

        if (properties[0]) {
          if (properties[0].property_value_definition_name) {
            firstSet.add(properties[0].property_value_definition_name);
          } else {
            firstSet.add(properties[0].sku_property_value);
          }
        }
        if (properties[1]) {
          if (properties[1].property_value_definition_name) {
            secondSet.add(properties[1].property_value_definition_name);
          } else {
            secondSet.add(properties[1].sku_property_value);
          }
        }
        if (properties[2]) {
          if (properties[2].property_value_definition_name) {
            thirdSet.add(properties[2].property_value_definition_name);
          } else {
            thirdSet.add(properties[2].sku_property_value);
          }
        }
        if (properties[3]) {
          if (properties[3].property_value_definition_name) {
            fouthSet.add(properties[3].property_value_definition_name);
          } else {
            fouthSet.add(properties[3].sku_property_value);
          }
        }
        if (properties[4]) {
          if (properties[4].property_value_definition_name) {
            fifthSet.add(properties[4].property_value_definition_name);
          } else {
            fifthSet.add(properties[4].sku_property_value);
          }
        }
      }
    );
    setFirstValues([...firstSet]);
    setSecondValues([...secondSet]);
    setThirdValues([...thirdSet]);
    setFourthValues([...fouthSet]);
    setFifthValues([...fifthSet]);
  };

  const handleSelection = () => {
    const matchedItem =
      productInfo?.ae_item_sku_info_dtos?.ae_item_sku_info_d_t_o?.find(
        (skuItem) => {
          const properties = skuItem.ae_sku_property_dtos.ae_sku_property_d_t_o;

          // Match based on selected values (checking if the property exists before matching)
          const firstMatch = selectedFirstValue
            ? properties[0]?.property_value_definition_name ===
                selectedFirstValue ||
              properties[0]?.sku_property_value === selectedFirstValue
            : true;

          const secondMatch = selectedSecondValue
            ? properties[1]?.property_value_definition_name ===
                selectedSecondValue ||
              properties[1]?.sku_property_value === selectedSecondValue
            : true;

          const thirdMatch = selectedThirdValue
            ? properties[2]?.property_value_definition_name ===
                selectedThirdValue ||
              properties[2]?.sku_property_value === selectedThirdValue
            : true;

          const fourMatch = selectedFourthValue
            ? properties[3]?.property_value_definition_name ===
                selectedFourthValue ||
              properties[3]?.sku_property_value === selectedFourthValue
            : true;

          const fifthMatch = selectedFifthValue
            ? properties[4]?.property_value_definition_name ===
                selectedFifthValue ||
              properties[4]?.sku_property_value === selectedFifthValue
            : true;

          return (
            firstMatch && secondMatch && thirdMatch && fourMatch && fifthMatch
          );
        }
      );
    setCurrentItem(matchedItem);
  };

  useEffect(() => {
    const firstSkuItem =
      productInfo?.ae_item_sku_info_dtos?.ae_item_sku_info_d_t_o[0];

    if (firstSkuItem) {
      const firstProperty =
        firstSkuItem?.ae_sku_property_dtos?.ae_sku_property_d_t_o[0];

      const secondProperty =
        firstSkuItem?.ae_sku_property_dtos?.ae_sku_property_d_t_o[1];

      const thirdProperty =
        firstSkuItem?.ae_sku_property_dtos?.ae_sku_property_d_t_o[2];

      const fourthProperty =
        firstSkuItem?.ae_sku_property_dtos?.ae_sku_property_d_t_o[3];

      const fiftyProperty =
        firstSkuItem?.ae_sku_property_dtos?.ae_sku_property_d_t_o[4];

      const defaultFirstValue = firstProperty?.property_value_definition_name
        ? firstProperty?.property_value_definition_name
        : firstProperty?.sku_property_value || null;

      const defaultSecondValue = secondProperty?.property_value_definition_name
        ? secondProperty?.property_value_definition_name
        : secondProperty?.sku_property_value || null;

      const defaultThirdValue = thirdProperty?.property_value_definition_name
        ? thirdProperty?.property_value_definition_name
        : thirdProperty?.sku_property_value || null;

      const defaultFourthValue = fourthProperty?.property_value_definition_name
        ? fourthProperty?.property_value_definition_name
        : fourthProperty?.sku_property_value || null;

      const defaultFifthValue = fiftyProperty?.property_value_definition_name
        ? fiftyProperty?.property_value_definition_name
        : fiftyProperty?.sku_property_value || null;

      setSelectedFirstValue(defaultFirstValue);
      setSelectedSecondValue(defaultSecondValue);
      setSelectedThirdValue(defaultThirdValue);
      setSelectedFourthValue(defaultFourthValue);
      setSelectedFifthValue(defaultFifthValue);
    } else {
      setSelectedFirstValue(null);
      setSelectedSecondValue(null);
      setSelectedThirdValue(null);
      setSelectedFourthValue(null);
      setSelectedFifthValue(null);
    }
  }, [productInfo]);

  useEffect(() => {
    extractValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleSelection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFirstValue, selectedSecondValue, selectedThirdValue]);

  return (
    <div className="current_item_properties">
      <div className="">
        <h6>
          {productInfo?.ae_item_sku_info_dtos?.ae_item_sku_info_d_t_o[0]
            ?.ae_sku_property_dtos?.ae_sku_property_d_t_o[0]
            ?.sku_property_name &&
            `${productInfo?.ae_item_sku_info_dtos?.ae_item_sku_info_d_t_o[0]?.ae_sku_property_dtos?.ae_sku_property_d_t_o[0]?.sku_property_name}  :`}{" "}
          {selectedFirstValue}
        </h6>
        <div className="prop_wrapper">
          {firstValues?.map((item, index) => (
            <div
              key={index}
              className={`prop_content
                ${item === selectedFirstValue && "prop_content_active"}
              `}
              onClick={() => setSelectedFirstValue(item)}
            >
              {item}
            </div>
          ))}
        </div>
      </div>
      <div className="">
        <h6>
          {productInfo?.ae_item_sku_info_dtos?.ae_item_sku_info_d_t_o[0]
            ?.ae_sku_property_dtos?.ae_sku_property_d_t_o[1]
            ?.sku_property_name &&
            `${productInfo?.ae_item_sku_info_dtos?.ae_item_sku_info_d_t_o[0]?.ae_sku_property_dtos?.ae_sku_property_d_t_o[1]?.sku_property_name} :`}{" "}
          {selectedSecondValue}
        </h6>
        <div className="prop_wrapper">
          {secondValues?.map((item, index) => (
            <div
              key={index}
              className={`prop_content
                ${item === selectedSecondValue && "prop_content_active"}
              `}
              onClick={() => setSelectedSecondValue(item)}
            >
              {item}
            </div>
          ))}
        </div>
      </div>
      <div className="">
        <h6>
          {productInfo?.ae_item_sku_info_dtos?.ae_item_sku_info_d_t_o[0]
            ?.ae_sku_property_dtos?.ae_sku_property_d_t_o[2]
            ?.sku_property_name &&
            `${productInfo?.ae_item_sku_info_dtos?.ae_item_sku_info_d_t_o[0]?.ae_sku_property_dtos?.ae_sku_property_d_t_o[2]?.sku_property_name} :`}{" "}
          {selectedThirdValue}
        </h6>
        <div className="prop_wrapper">
          {thirdValues?.map((item, index) => (
            <div
              key={index}
              className={`prop_content
                ${item === selectedThirdValue && "prop_content_active"}
              `}
              onClick={() => setSelectedThirdValue(item)}
            >
              {item}
            </div>
          ))}
        </div>
      </div>
      <div className="">
        <h6>
          {productInfo?.ae_item_sku_info_dtos?.ae_item_sku_info_d_t_o[0]
            ?.ae_sku_property_dtos?.ae_sku_property_d_t_o[3]
            ?.sku_property_name &&
            `${productInfo?.ae_item_sku_info_dtos?.ae_item_sku_info_d_t_o[0]?.ae_sku_property_dtos?.ae_sku_property_d_t_o[3]?.sku_property_name} :`}{" "}
          {selectedFourthValue}
        </h6>
        <div className="prop_wrapper">
          {fourthValues?.map((item, index) => (
            <div
              key={index}
              className={`prop_content
                ${item === selectedFourthValue && "prop_content_active"}
              `}
              onClick={() => setSelectedFourthValue(item)}
            >
              {item}
            </div>
          ))}
        </div>
      </div>
      <div className="">
        <h6>
          {productInfo?.ae_item_sku_info_dtos?.ae_item_sku_info_d_t_o[0]
            ?.ae_sku_property_dtos?.ae_sku_property_d_t_o[4]
            ?.sku_property_name &&
            `${productInfo?.ae_item_sku_info_dtos?.ae_item_sku_info_d_t_o[0]?.ae_sku_property_dtos?.ae_sku_property_d_t_o[4]?.sku_property_name} :`}{" "}
          {selectedFifthValue}
        </h6>
        <div className="prop_wrapper">
          {fifthValues?.map((item, index) => (
            <div
              key={index}
              className={`prop_content
                ${item === selectedFifthValue && "prop_content_active"}
              `}
              onClick={() => setSelectedFifthValue(item)}
            >
              {item}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Properties;
