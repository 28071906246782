import React, { useState } from "react";

const SearchInput = ({ onSearch, placeholder }) => {
  const [search, setSearch] = useState("");

  const onInputChange = (value) => {
    setSearch(value);
    onSearch(value);
  };
  return (
    <form>
      <div className=" search-issues">
        <input
          type="text"
          className="form-control"
          id=""
          placeholder={placeholder}
          value={search}
          onChange={(e) => onInputChange(e.target.value)}
        />
      </div>
    </form>
  );
};

export default SearchInput;
