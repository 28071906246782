import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ProtectedRoutes } from "../../components/utils/ProtectedRoutes";
import logo from "./../../assets/img/logo.png";
import cookies from "js-cookie";
import { convertPrice } from "../../components/helpers/convertPrice.js";
import { useTranslation } from "react-i18next";

const BankDetails = () => {
  const { t } = useTranslation();
  const bankName = process.env.REACT_APP_BANK_NAME;
  const accountName = process.env.REACT_APP_ACCOUNT_NAME;
  const accountNameII = process.env.REACT_APP_ACCOUNT_NAME_2ND;
  const accountNumber = process.env.REACT_APP_ACCOUNT_NUMBER;
  const accountNumberII = process.env.REACT_APP_ACCOUNT_NUMBER_2ND;
  const support = process.env.REACT_APP_SUPPORT;

  const navigate = useNavigate();
  const selectedCurrency = cookies.get("currency") || "NGN";

  const { cost } = useParams();

  const handleBackToPreviousPage = () => {
    navigate(-1);
  };
  return (
    <div className="bank-ctn">
      {" "}
      <div className="p-4 logo">
        <Link to="/">
          <img src={logo} alt="TOFA" />
        </Link>
      </div>
      <div className="bank-details">
        <p>
          {t("Your_order_has_been_recorded")}{" "}
          {selectedCurrency === "NGN" ? "Naira" : "Dollar"}{" "}
          {t("account_bank_details_below")}:
        </p>
        {selectedCurrency === "NGN" ? (
          <ul>
            <li>
              <span>{t("Cost_of_Product")}(s):</span> {selectedCurrency}{" "}
              {cost && convertPrice(cost, selectedCurrency)}
            </li>
            <li>
              <span>{t("Bank_name")}:</span> {bankName}{" "}
            </li>
            <li>
              <span>{t("Account_name")}:</span> {accountName}
            </li>
            <li>
              <span>{t("Account_number")}:</span> {accountNumber}{" "}
            </li>
          </ul>
        ) : (
          <ul>
            <li>
              <span>{t("Cost_of_Product")}(s):</span> {selectedCurrency}{" "}
              {cost && convertPrice(cost, selectedCurrency)}
            </li>
            <li>
              <span>{t("Bank_name")}:</span> {bankName}{" "}
            </li>
            <li>
              <span>{t("Account_name")}:</span> {accountNameII}{" "}
            </li>
            <li>
              <span>{t("Account_number")}:</span> {accountNumberII}{" "}
            </li>
          </ul>
        )}
        <p className="notice">
          <span className="danger">{t("Important_Notice")}:</span>{" "}
          {t("Upon_completing")}{" "}
          <Link to="/buyer/orders">{t('"Orders"_page')}</Link> {t("and_select")}{" "}
          {t("option_corresponding")} {t("has_loaded")}{" "}
          {t("crucial_to_facilitate")} {t("you_require_further")}{" "}
          {t("to_get_in")} <span className="title"> {support}.</span>
          {t("We're_here_to_help!")}
        </p>
        <p>{t("Feel_free_to_access")}</p>
        <div>
          <button
            className="request-quotebtn"
            onClick={handleBackToPreviousPage}
          >
            {t("back")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProtectedRoutes(BankDetails, ["BUYER", "SELLER"]);
