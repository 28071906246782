import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../baseUrl";
import { AppContext } from "./AppState";

export const ProtectedRoutes = (WrappedComponent, roles) => {
  return (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const navigate = useNavigate();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [verified, setVerified] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { setUser } = useContext(AppContext);

    // eslint-disable-next-line react-hooks/rules-of-hooks, no-undef
    useEffect(() => {
      axiosInstance
        .get(`/auth/current-user`)
        .then((response) => {
          const user = response.data.currentUser;
          setUser(user);

          if (!roles && user) {
            return setVerified(true);
          }

          const filterRoles = roles.filter((role) => {
            return role === user.role;
          });

          if (filterRoles.length > 0) {
            setVerified(true);
          } else {
            setVerified(false);
            navigate("/unauthorized");
          }
        })
        .catch((error) => {
          console.log(error);
          navigate("/login");
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (verified) {
      return <WrappedComponent {...props} />;
    } else {
      return null;
    }
  };
};
