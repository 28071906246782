import { useState } from "react";

import toast from "react-hot-toast";

import { axiosInstance } from "../baseUrl";
import { toastOptions } from "../helpers/functionHelpers";

export const usePayWithTransferTransactPay = () => {
  const [trnasferLoad, setTransferLoad] = useState(false);
  const [selectedBankCode, setSelectedBankCode] = useState("");
  const [accountNumber, setAcountNumber] = useState("");

  const handlePayWithTransfer = async (country, transactionId) => {
    if (!selectedBankCode) {
      toast.error("Please select bank.", toastOptions);
      return;
    }
    setTransferLoad(true);
    try {
      const payLoad = {
        reference: transactionId,
        paymentoption: "bank-transfer",
        country: country,
        BankTransfer: {
          bankcode: selectedBankCode,
        },
      };
      const { data } = await axiosInstance.post(
        `/transact-pay/pay/bank-transfer`,
        payLoad
      );
      setAcountNumber(data?.data?.paymentDetail?.recipientAccount);
      console.log("data payment with card transactpay", data);
    } catch (error) {
      console.log("error payment with card transactpay", error);
      if (error?.response?.data?.message) {
        toast.error(`${error?.response?.data?.message}`, toastOptions);
      } else {
        toast.error(`Payment failed please contact support`, toastOptions);
      }
    } finally {
      setTransferLoad(false);
    }
  };
  return {
    trnasferLoad,
    handlePayWithTransfer,
    setSelectedBankCode,
    selectedBankCode,
    accountNumber,
  };
};
