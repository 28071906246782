import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const ProductSkeleton = () => {
  return (
    <>
      <div className="section-head">
        <h2>
          {" "}
          <Skeleton height={20} />
        </h2>
      </div>

      <div className="main-container">
        <div className="p-container">
          <div className="product-card">
            <div className="product-card-img">
              <Skeleton height={100} />
            </div>
            <div className="product-card-info">
              <h3 className="seller-name">
                <Skeleton count={4} />
              </h3>
            </div>
          </div>
          <div className="product-card">
            <div className="product-card-img">
              <Skeleton height={100} />
            </div>
            <div className="product-card-info">
              <h3 className="seller-name">
                <Skeleton count={4} />
              </h3>
            </div>
          </div>
          <div className="product-card">
            <div className="product-card-img">
              <Skeleton height={100} />
            </div>
            <div className="product-card-info">
              <h3 className="seller-name">
                <Skeleton count={4} />
              </h3>
            </div>
          </div>
          <div className="product-card">
            <div className="product-card-img">
              <Skeleton height={100} />
            </div>
            <div className="product-card-info">
              <h3 className="seller-name">
                <Skeleton count={4} />
              </h3>
            </div>
          </div>
          <div className="product-card">
            <div className="product-card-img">
              <Skeleton height={100} />
            </div>
            <div className="product-card-info">
              <h3 className="seller-name">
                <Skeleton count={4} />
              </h3>
            </div>
          </div>
          <div className="product-card">
            <div className="product-card-img">
              <Skeleton height={100} />
            </div>
            <div className="product-card-info">
              <h3 className="seller-name">
                <Skeleton count={4} />
              </h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductSkeleton;
