import Star from "../../assets/img/Star.svg";
import StarColor from "../../assets/img/StarColor.svg";

export const fiveStar = [StarColor, StarColor, StarColor, StarColor, StarColor];

export const fourStar = [StarColor, StarColor, StarColor, StarColor, Star];

export const threeStar = [StarColor, StarColor, StarColor, Star, Star];

export const twoStar = [StarColor, StarColor, Star, Star, Star];

export const oneStar = [StarColor, Star, Star, Star, Star];

export const noStar = [Star, Star, Star, Star, Star];
