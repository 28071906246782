import { useState } from "react";

import toast from "react-hot-toast";

import { axiosInstance } from "../baseUrl";
import { toastOptions } from "../helpers/functionHelpers";

export const usePayWithCardTransactPay = () => {
  const [cardLoad, setCardLoad] = useState(false);
  const [cardDetails, setCardDetails] = useState({
    cardNumber: "",
    expiryDate: "",
    cvv: "",
  });

  const handleCardInputChange = (e) => {
    const { name, value } = e.target;
    setCardDetails({ ...cardDetails, [name]: value });
  };

  const handlePayWithCard = async (e, country, transactionId) => {
    e.preventDefault();
    setCardLoad(true);
    try {
      const { cardNumber, cvv } = cardDetails;
      const [month, year] = cardDetails.expiryDate.split("/");
      const payLoad = {
        reference: transactionId,
        paymentoption: "C",
        country: country,
        card: {
          cardnumber: cardNumber,
          expirymonth: month,
          expiryyear: year,
          cvv: cvv,
        },
      };
      const { data } = await axiosInstance.post(
        `/transact-pay/pay/card`,
        payLoad
      );
      const redirectUrl = data?.data?.paymentDetail?.redirectUrl;
      window.location.href = redirectUrl;
    } catch (error) {
      console.log("error payment with card transactpay", error);
      if (error?.response?.data?.message) {
        toast.error(`${error?.response?.data?.message}`, toastOptions);
      } else {
        toast.error(`Payment failed please contact support`, toastOptions);
      }
    } finally {
      setCardLoad(false);
    }
  };
  return {
    cardLoad,
    handlePayWithCard,
    setCardDetails,
    cardDetails,
    handleCardInputChange,
  };
};
