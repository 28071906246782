import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import cookies from "js-cookie";

const Footer = () => {
  const { t } = useTranslation();
  const [currentCurrencyCode, setCurrentCurrencyCode] = useState(
    cookies.get("currency") || "NGN"
  );
  const currentLanguageCode = cookies.get("i18next") || "en";

  const companyName = process.env.REACT_APP_COMPANY_NAME;
  const languages = [
    {
      code: "en",
      name: "English",
      country_code: "gb",
    },
    {
      code: "fr",
      name: "Français",
      country_code: "fr",
    },
    {
      code: "sw",
      name: "Kiswahili",
      country_code: "ke",
    },
  ];

  const currency = [
    {
      code: "EUR",
      name: "EUR",
      country_code: "EUR",
    },
    {
      code: "GBP",
      name: "GBP",
      country_code: "GBP",
    },
    {
      code: "GHS",
      name: "GHS",
      country_code: "GHS",
    },
    {
      code: "NGN",
      name: "NGN",
      country_code: "NGN",
    },
    {
      code: "USD",
      name: "USD",
      country_code: "USD",
    },
    {
      code: "XOF",
      name: "XOF",
      country_code: "XOF",
    },
  ];

  const setCurrencyCode = (currency) => {
    cookies.set("currency", currency);
    setCurrentCurrencyCode(currency);
  };

  const handleCurrencyChange = (currency) => {
    const newCurrency = currency;
    setCurrencyCode(newCurrency);
    window.location.reload();
  };
  return (
    <>
      <footer>
        <div className="footer-top">
          <div className="container">
            <div className="row ">
              <div className="ft-wrap col-md-3 col-sm-6 col-6">
                <h4>{t("Products")}</h4>

                <p>
                  <a href="/category/all" rel="noopener noreferrer">
                    {t("browse")} {t("Products")}
                  </a>
                </p>
                <p>
                  <a href="/afcfta/products" rel="noopener noreferrer">
                    AFCFTA {t("Products")}
                  </a>
                </p>
                <p>
                  <a href="/old-mutual" rel="noopener noreferrer">
                    Old Mutual {t("Products")}
                  </a>
                </p>
              </div>
              <div className="ft-wrap col-md-3 col-sm-6 col-6">
                <h4>{t("Need_Help")}</h4>
                <p>
                  <a
                    href={process.env.REACT_APP_WHATSAPP_CONTACT}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("Contact_Us")}
                  </a>
                </p>
                <p>
                  <a href="/support">{t("Contact_support")}</a>
                </p>
                <p>
                  <a href="/help-center">{t("FAQ")}</a>
                </p>
              </div>
              <div className="ft-wrap col-md-3 col-sm-6 col-6">
                <h4>{t("Features")}</h4>
                <p>
                  <a href="/">{t("Home")}</a>
                </p>
                <p>
                  <a href="/category/all" rel="noopener noreferrer">
                    {t("All_Categories")}
                  </a>
                </p>
                <p>
                  <a href="#search">{t("Search")}</a>
                </p>
              </div>
              <div className="ft-wrap col-md-3 col-sm-6 col-6">
                <h4>{t("Informative")}</h4>

                <p>
                  <a href="/become-seller" rel="noopener noreferrer">
                    {t("Become_seller")}
                  </a>
                </p>
                <p>
                  <a href="/all-rfqs" rel="noopener noreferrer">
                    {t("quotes")}
                  </a>
                </p>
              </div>
              <div className="ft-wrap col-md-3 col-sm-6 col-6">
                <h4> {t("Currency")}</h4>
                {currency.map(({ code, name, country_code }) => (
                  <p
                    key={country_code}
                    onClick={() => handleCurrencyChange(code)}
                    style={{
                      opacity: code === currentCurrencyCode ? "0.5" : "1",
                    }}
                  >
                    {name}
                  </p>
                ))}
              </div>
              <div className="ft-wrap col-md-3 col-sm-6 col-6">
                <h4> {t("Language")}</h4>
                {languages.map(({ code, name, country_code }) => (
                  <p
                    key={country_code}
                    onClick={() => i18next.changeLanguage(code)}
                    style={{
                      opacity: code === currentLanguageCode ? "0.5" : "1",
                    }}
                  >
                    {" "}
                    <span
                      className={`flag-icon flag-icon-${country_code}`}
                    ></span>
                    {name}
                  </p>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="footer-btm">
          <div className="container">
            <div className="fb-wrap">
              <p className="copyright">
                ©2022 {companyName}. {t("All_Rights_Reserved")}
              </p>
              <ul>
                <li>
                  <Link to="/terms-of-use">{t("Terms_Conditions")}</Link>
                </li>
                <li>
                  <Link to="/privacy">{t("Privacy_Policy")}</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
