import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../baseUrl";

export const useGetAllCategories = () => {
  const navigate = useNavigate();

  const onCateErr = (error) => {
    if (error) {
      navigate("/no-connection");
    }
  };

  const getAllCategories = () => {
    return axiosInstance
      .get(`/category`)
      .then((res) => res?.data?.data.categories);
  };

  const { data: allCategories, isLoading: cateLoading } = useQuery(
    "categoryy",
    getAllCategories,
    {
      onError: onCateErr,
    }
  );
  return {
    allCategories,
    cateLoading,
  };
};
