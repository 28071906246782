import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header/Header";

import HeroImg2 from "../../assets/img/hero-img2.png";

import "./OldMutual.css";

import suppliercompliance from "../../assets/img/supplierCompliance.png";
import becomeseller from "../../assets/img/steven-cleghorn-psomVjxL29Y-unsplash.jpg";

import journey from "../../assets/img/alex-hudson-m3I92SgM3Mk-unsplash.jpg";

import CategorySkeleton from "../../components/SkeletonLoader/CategorySkeleton";
import ProductSkeleton from "../../components/SkeletonLoader/ProductSkeleton";
import { useGetAllCategories } from "../../components/hooks/useGetAllCategories";
import { useGetAllProduct } from "../../components/hooks/useGetAllProduct";
import { Capitalize } from "../../components/helpers/functionHelpers";
import cookies from "js-cookie";
import { convertPrice } from "../../components/helpers/convertPrice";

const OldMutualMain = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { allCategories, cateLoading } = useGetAllCategories();
  const { loading, allProducts } = useGetAllProduct();
  const selectedCurrency = cookies.get("currency") || "NGN";

  const companyNameCaps = process.env.REACT_APP_COMPANY_NAME_CAPS;
  const company = process.env.REACT_APP_COMPANY_NAME;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  return (
    <>
      <Header />
      <section id="main-hero-section" className="mt-4">
        <div className="container">
          <div
            id="carouselExampleSlidesOnly"
            className="carousel slide carousel-fade"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img src={HeroImg2} className="d-block w-100" alt="..." />
              </div>
              <div className="carousel-item">
                <img src={HeroImg2} className="d-block w-100" alt="..." />
              </div>
              <div className="carousel-item">
                <img src={HeroImg2} className="d-block w-100" alt="..." />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="main-why-sell-container">
        <div className="container">
          <h4>{t("Why_Join_the_MarketPlace")}</h4>
          <div className="main-why-sell">
            <div className="main-why-sell-content">
              <h5>
                <i
                  className="fas fa-spinner"
                  style={{ color: "#8DC63F", marginRight: "10px" }}
                ></i>
                {t("Secured_Trade")}
              </h5>
              <p>
                {companyNameCaps} {t("company_details")}.{" "}
                {t("company_details_2")}.
              </p>
            </div>
            <div className="main-why-sell-content">
              <h5>
                <i
                  className="fas fa-spinner"
                  style={{ color: "#8DC63F", marginRight: "10px" }}
                ></i>
                {t("Low_Commissions")}
              </h5>
              <p>{t("commission")}</p>
            </div>
            <div className="main-why-sell-content">
              <h5>
                <i
                  className="fas fa-spinner"
                  style={{ color: "#8DC63F", marginRight: "10px" }}
                ></i>
                {t("support")}
              </h5>
              <p>{t("We_provide")}</p>
            </div>
            <div className="main-why-sell-content">
              <h5>
                <i
                  className="fas fa-spinner"
                  style={{ color: "#8DC63F", marginRight: "10px" }}
                ></i>
                {t("Diverse_Network")}
              </h5>
              <p>{t("Network_with_over")}</p>
            </div>
          </div>
        </div>
        <div className="oldmutual-main-becomeseller">
          <div className="container">
            <div className="oldmutual-main-becomeseller-box">
              <div className="main-become-seller-image">
                <img src={becomeseller} alt="..." />
              </div>
              <div className="main-become-seller-content">
                <div className="main-become-seller-content-list">
                  <h4>{t("Become_a_seller")}</h4>
                  <ul>
                    <li>
                      <i
                        className="fas fa-stream"
                        style={{ color: "#8DC63F", marginRight: "10px" }}
                      ></i>
                      {t("Fill_the_reg")}
                    </li>
                    <li>
                      <i
                        className="fas fa-stream"
                        style={{ color: "#8DC63F", marginRight: "10px" }}
                      ></i>
                      {t("Add_Business")}
                    </li>
                    <li>
                      <i
                        className="fas fa-stream"
                        style={{ color: "#8DC63F", marginRight: "10px" }}
                      ></i>
                      {t("List_your_products_and_start")}
                    </li>
                  </ul>
                </div>
                <div className="main-become-seller-content-button">
                  <Link to="/old-mutual/seller/registration">
                    <button>{t("Start_Selling_Now")}</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="oldmutual-main-suppliercompliance-container">
        <div className="container">
          <div className="oldmutual-main-suppliercompliance">
            <div className="oldmutual-main-suppliercompliance-image">
              <img src={suppliercompliance} alt="" />
            </div>
            <div className="oldmutual-main-suppliercompliance-infobox">
              <div className="oldmutual-main-suppliercompliance-info">
                <p>{t("compliances")}</p>
                <p className="om-download">
                  <span>
                    <i
                      className="fas fa-arrow-alt-circle-down"
                      style={{ color: "#000" }}
                    ></i>
                  </span>
                  {t("Download")}
                </p>
              </div>
              <div className="oldmutual-main-suppliercompliance-info">
                <p>{t("Certain_products")}</p>
                <p className="om-download">
                  <span>
                    <i
                      className="fas fa-arrow-alt-circle-down"
                      style={{ color: "#000" }}
                    ></i>
                  </span>
                  {t("Download")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="oldmutual-explore-categories">
        {!cateLoading ? (
          <section id="popular-cat">
            <div className="container">
              <div className="section-head">
                <h2>{t("Explore_Categories")}</h2>
                <h6>
                  <Link to="/category/all" style={{ color: "#8DC63F" }}>
                    {t("All_categories")}
                  </Link>
                </h6>
              </div>
              <div className="cat-wrap">
                {allCategories &&
                  allCategories.slice(0, 6).map((category) => (
                    <Link to={`/category/${category.id}`} key={category.id}>
                      <div className="cat-box">
                        <img src={category.image} className="" alt="..." />
                        <h3>{category.category}</h3>
                      </div>
                    </Link>
                  ))}
              </div>
            </div>
          </section>
        ) : (
          <CategorySkeleton />
        )}
      </div>
      <div className="oldmutual-featured-product">
        <div className="container">
          <div className="feature-prod-box">
            <div className="featured-prod-box-heading">
              <p>{t("Featured_Products")}</p>
              <Link to="/category/all">
                <button>{t("View_More")}</button>
              </Link>
            </div>
            {loading ? (
              <ProductSkeleton />
            ) : (
              <div className="main-container">
                <div className="p-container">
                  {allProducts &&
                    allProducts.slice(0, 8).map((product) => (
                      <Link to={`/product-info/${product.id}`} key={product.id}>
                        <div className="product-card">
                          <div className="product-card-img">
                            <img
                              src={product?.productImages[0]?.image}
                              className=""
                              alt="..."
                            />
                          </div>
                          <div className="product-card-info">
                            <h3 className="seller-name">
                              {product &&
                                Capitalize(product.createdBy.firstName)}{" "}
                              {product &&
                                Capitalize(product.createdBy.LastName)}
                            </h3>
                            <h2
                              className={
                                product.productName &&
                                product.productName.length > 20
                                  ? "product-name-long"
                                  : "product-name"
                              }
                            >
                              {product && Capitalize(product.productName)}
                            </h2>
                            <p className="product-price">
                              <span className="p-currency">
                                {selectedCurrency}
                              </span>{" "}
                              {product.minPricePerUnit &&
                                convertPrice(
                                  product.minPricePerUnit,
                                  selectedCurrency
                                )}
                              <span className="p-unit">
                                /{product && product.unitForMinOrder}
                              </span>
                            </p>
                          </div>
                        </div>
                      </Link>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="begin-journey-container">
        <div className="container">
          <div className="begin-journey-box">
            <div className="begin-journey-image">
              <img src={journey} alt="..." />
            </div>
          </div>
          <div className="begin-journey-content">
            <h4>{t("Ready_to_Begin")}</h4>
            <p>{t("join_the_safest")}</p>

            <button className="startselling-button">
              <Link
                to="/old-mutual/seller/registration"
                style={{ color: "#fff" }}
              >
                {t("Start_Selling")}
              </Link>
            </button>
          </div>
        </div>
        ``
      </div>
      <div className="begin-journey-footer">
        <div className="container">
          <div className="begin-journey-footer-box">
            <p>
              {" "}
              © 2022 {company} {t("partnership")}
            </p>
            <p>{t("Terms")}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default OldMutualMain;
