import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../baseUrl";

export const useGetProductInfo = () => {
  const [productInfo, setProductInfo] = useState({});
  const [productImages, setProductImages] = useState([]);
  const [currentImage, setCurrentImage] = useState({});
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const displayImageHandler = (imageIndex) => {
    setCurrentImage(productImages[imageIndex].image);
  };
  const getInfo = async (productId) => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(`/product/${productId}`);
      setProductInfo(data.data);
      setProductImages(data.data.productImages);
      if (data.data.productImages[0]) {
        setCurrentImage(data.data.productImages[0].image);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.message && error.message === "Network Error") {
        navigate("/no-connection");
      }
    }
  };

  return {
    getInfo,
    displayImageHandler,
    loading,
    currentImage,
    productInfo,
    productImages,
  };
};
