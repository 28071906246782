import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Link, useNavigate, useParams } from "react-router-dom";

import BuyersSidebar from "../BuyersComponent/BuyerSideBar";
import Ellipse from "../../../../assets/img/Ellipse.png";
import "../../Dashboard.css";

import { ProtectedRoutes } from "../../../../components/utils/ProtectedRoutes";
import { axiosInstance } from "../../../../components/baseUrl";

import toast, { Toaster } from "react-hot-toast";

import MessageModal from "../BuyersMessage/MessageModal";
import BuyerRfqModal from "./BuyerRfqModal";
import { Capitalize } from "../../../../components/helpers/functionHelpers";
import { BigCard } from "../../../../components/SkeletonLoader/BigCard";

const BuyersRfqQuote = () => {
  const { t } = useTranslation();
  const [rfqInfo, setRfqInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [loader, setLoader] = useState(false);
  const [totalCost, setTotalCost] = useState(0);
  const { rfqId } = useParams();
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const [orderMsg, setOrderMsg] = useState(false);

  const handleClick = (event) => {
    setIsActive((current) => !current);
  };

  const getRfqInfo = async () => {
    try {
      axiosInstance.get(`/rfq/${rfqId}`).then((response) => {
        setRfqInfo(response.data.data);
        if (response.data.data.supplierResponse) {
          setTotalCost(response?.data?.data?.supplierResponse?.price);
        }
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error.message && error.message === "Network Error") {
        navigate("/no-connection");
      }
    }
  };

  useEffect(() => {
    getRfqInfo();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rfqId, navigate]);

  const handleSubmit = async () => {
    let toastId = "";
    toastId = toast.loading("loading...", {
      style: {
        background: "#353434",
        color: "#fff",
      },
    });
    setLoader(true);
    try {
      const createOrder = {
        quantityOrdered: rfqInfo.quantityRequired,
        productID: rfqInfo.productId,
        incoterm: rfqInfo.termsOfTrade,
        sellerId: rfqInfo.sellerId,
        paymentTerm: rfqInfo.paymentTerms,
        shippingType: "LOCAL",
        cost: totalCost.toString(),
        countryOfOrigin: rfqInfo.product.countryOfOrigin,
        port: rfqInfo.destinationPort,
        note: rfqInfo.productDescription,
        destination: rfqInfo.destinationPort,
      };

      await axiosInstance.post("/order", createOrder);

      toast.success("You have successfully created your order.", {
        id: toastId,
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
      setLoader(false);
      setOrderMsg(true);
    } catch (error) {
      console.log(error);
      setLoader(false);
      if (!error.response.data.errors) {
        return navigate(`/no-connection`);
      }
      toast.error(`${error.response.data.errors[0].message}`, {
        id: toastId,
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
    }
  };

  return (
    <>
      <Toaster />
      <div className="grid-container">
        <div className={"menuu-icon"} onClick={handleClick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-6 h-6"
          >
            <path
              fillRule="evenodd"
              d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
              clipRule="evenodd"
            />
          </svg>
        </div>

        <BuyersSidebar isActive={isActive} handleClick={handleClick} />

        {loading ? (
          <BigCard />
        ) : (
          <main className="main">
            <div className="chat-main">
              <div className="main-overview">
                <div className="overview-card-buyers-info">
                  <div className="buyer-info-container">
                    <div className="buyer-info-pic">
                      <img src={Ellipse} alt="" />
                    </div>
                  </div>

                  <div className="buyer-info-details">
                    <h6>
                      {rfqInfo.seller && Capitalize(rfqInfo.seller.firstName)}{" "}
                      {rfqInfo.seller && Capitalize(rfqInfo.seller.LastName)}
                    </h6>

                    <div className="buyer-info">
                      <span>
                        <i className="fas fa-envelope"></i>
                      </span>
                      <p>{rfqInfo.seller && rfqInfo.seller.email}</p>
                    </div>
                    <div className="buyer-info">
                      <span>
                        <i className="fas fa-phone-alt"></i>
                      </span>
                      <p>{rfqInfo.seller && rfqInfo.seller.phoneNumber}</p>
                    </div>
                    <div className="buyer-info">
                      <span>
                        <i className="fas fa-briefcase"></i>
                      </span>
                      <p>{rfqInfo.seller && rfqInfo.seller.businessType}</p>
                    </div>
                    <div className="buyer-info">
                      <span>
                        <i className="fas fa-map-marker-alt"></i>
                      </span>
                      <p>{rfqInfo.seller && rfqInfo.seller.country}</p>
                    </div>

                    <div className="buyer-info messageseller">
                      <span>
                        <i className="fas fa-store"></i>
                      </span>
                      <Link to={`/sellers-info/${rfqInfo.sellerId}`}>
                        <button>{t("View_Sellers_Page")}</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="main-overview">
                <div className="overview-card no-padding">
                  <div className="product-info">
                    <p className="product-info-title">{t("Product_Name")}</p>
                    <p className="product-info-description">
                      {rfqInfo.productName && Capitalize(rfqInfo.productName)}
                    </p>
                  </div>
                  <div className="product-info">
                    <p className="product-info-title">{t("Quantity")}</p>
                    <p className="product-info-description">
                      {rfqInfo && rfqInfo.quantityRequired}{" "}
                      {rfqInfo && rfqInfo.unit}
                    </p>
                  </div>
                  <div className="product-info">
                    <p className="product-info-title">{t("Incoterm")}</p>
                    <p className="product-info-description">
                      {rfqInfo && rfqInfo.termsOfTrade}
                    </p>
                  </div>
                  <div className="product-info">
                    <p className="product-info-title">{t("Destination")}</p>
                    <p className="product-info-description">
                      {rfqInfo && rfqInfo.destinationPort}
                    </p>
                  </div>

                  <div className="product-requirement">
                    <p>{t("Product_Requirement")}</p>
                    <p>{rfqInfo && rfqInfo.productDescription}</p>
                  </div>
                </div>
              </div>
            </div>
            <>
              <h4 className="quote-titlee">{t("Recieved_Quotes")}</h4>
              <div className="product-quote">
                {rfqInfo.supplierResponse ? (
                  <div className="main-overview">
                    <div className="overview-card no-padding">
                      <div className="table-responsive">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col">{t("Product_Image")}</th>
                              <th scope="col">{t("Request_Info")}</th>
                              <th scope="col">{t("Quantity")}</th>
                              <th scope="col">{t("Supplier")}</th>
                              <th scope="col">{t("Prompt")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div className="d-flex">
                                  <div className="flex-shrink-0">
                                    <img
                                      className="table-product-img"
                                      src={
                                        rfqInfo.product.productImages[0].image
                                      }
                                      alt="..."
                                    />
                                  </div>
                                </div>
                              </td>

                              <td>
                                <p>
                                  {rfqInfo.productName &&
                                    Capitalize(rfqInfo.productName)}
                                </p>
                              </td>
                              <td>
                                {rfqInfo && rfqInfo.quantityRequired}
                                {rfqInfo && rfqInfo.unit}
                              </td>
                              <td>
                                <p>
                                  {rfqInfo.seller &&
                                    Capitalize(rfqInfo.seller.firstName)}
                                  _
                                  {rfqInfo.seller &&
                                    Capitalize(rfqInfo.seller.LastName)}
                                </p>
                              </td>
                              <td>
                                <p className="buyerviewquote">
                                  <a
                                    data-bs-toggle="modal"
                                    href="#exampleModalToggle"
                                    role="button"
                                  >
                                    {t("View_Quote")}
                                  </a>
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>{t("No_response_from_seller_yet")}</div>
                )}

                <BuyerRfqModal
                  rfqInfo={rfqInfo}
                  Capitalize={Capitalize}
                  loader={loader}
                  handleSubmit={handleSubmit}
                  orderMsg={orderMsg}
                />
              </div>
            </>
          </main>
        )}
        <MessageModal supplierInfo={rfqInfo} Capitalize={Capitalize} />
      </div>
    </>
  );
};

export default ProtectedRoutes(BuyersRfqQuote, ["BUYER", "SELLER"]);
