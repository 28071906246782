export function combineOrderIds(totalCartQuantity, data, aliexpressOrderId) {
  let combinedArray = [];

  // Check if totalCartQuantity > 0 and data exists, then add data.orderIDs to combinedArray
  if (totalCartQuantity > 0 && data) {
    combinedArray = combinedArray.concat(data.data.data.orderIDs);
  }

  // Get the array from aliexpressOrderId() and add 'AE' prefix to each ID
  const aliExpressIds = aliexpressOrderId().map((id) => `AE${id}`);

  // Check if aliexpressOrderId array is not empty, then add to combinedArray
  if (aliExpressIds.length > 0) {
    combinedArray = combinedArray.concat(aliExpressIds);
  }

  return combinedArray;
}
