import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../../../components/baseUrl";
import toast from "react-hot-toast";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const SellerBankDetails = () => {
  const { t } = useTranslation();
  const [bankLoader, setBankLoader] = useState(false);
  const [editLoader, setEditLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [accountName, setAccountName] = useState("");
  const [bankId, setBankId] = useState("");
  const [bankName, setBankName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [bankDetails, setBankDetails] = useState({
    accountName: "",
    accountNumber: "",
    bankName: "",
  });

  const navigate = useNavigate();

  // get all bank details
  const onErrorBank = (error) => {
    if (error) {
      navigate("/no-connection");
    }
  };

  const getBank = () => {
    return axiosInstance.get(`/bank`).then((res) => res?.data?.data?.banks);
  };

  const { data: bank, isLoading: bankLoading } = useQuery("bank", getBank, {
    onError: onErrorBank,
  });

  const handleBankChange = (e) => {
    setBankDetails({ ...bankDetails, [e.target.name]: e.target.value });
  };

  //   upload bank details
  const handleBankUpdate = async (e) => {
    e.preventDefault();
    let toastId = "";
    toastId = toast.loading("loading...", {
      style: {
        background: "#353434",
        color: "#fff",
      },
    });
    setBankLoader(true);
    try {
      const uploadBankDetails = {
        bankName: bankDetails.bankName,
        accountName: bankDetails.accountName,
        accountNumber: bankDetails.accountNumber,
      };
      await axiosInstance.post("/bank", uploadBankDetails);
      setBankLoader(false);
      toast.success("Bank details saved successfully", {
        id: toastId,
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
    } catch (error) {
      console.log(error);
      setBankLoader(false);
      if (!error.response.data.errors) {
        return navigate(`/no-connection`);
      }
      toast.error(`${error.response.data.errors[0].message}`, {
        id: toastId,
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
    }
  };

  //   get bank details by id
  const getBankInfo = async (bankId) => {
    setLoading(true);
    try {
      const data = await axiosInstance.get(`/bank/${bankId}`);
      setLoading(false);
      setBankName(data.data.data.bankName);
      setAccountNumber(data.data.data.accountNumber);
      setAccountName(data.data.data.accountName);
      setBankId(data.data.data.id);
    } catch (error) {
      setLoading(false);
      console.log(error);
      if (!error.response.data.errors) {
        return navigate(`/no-connection`);
      }
    }
  };

  // edit bank details
  const handleEditBankDetaills = async (bankId) => {
    setEditLoader(true);
    let toastId = "";
    toastId = toast.loading("loading...", {
      style: {
        background: "#353434",
        color: "#fff",
      },
    });
    try {
      const editBankDetails = {
        accountName,
        accountNumber,
        bankName,
      };
      await axiosInstance.patch(`/bank/${bankId}`, editBankDetails);
      setEditLoader(false);
      toast.success("Bank details updated", {
        id: toastId,
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
    } catch (error) {
      setEditLoader(false);
      console.log(error);
      if (!error.response.data.errors) {
        return navigate(`/no-connection`);
      }
      toast.error(`${error.response.data.errors[0].message}`, {
        id: toastId,
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
    }
  };

  // delete bank details
  const submitDelete = (bankId) => {
    confirmAlert({
      title: "Confirm Delete",
      message: "Are you sure you want to delete this bank details?",
      buttons: [
        {
          label: "Yes",
          onClick: (e) => handleDelete(bankId),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const handleDelete = (bankId) => {
    let toastId = "";
    toastId = toast.loading("loading...", {
      style: {
        background: "#353434",
        color: "#fff",
      },
    });
    axiosInstance
      .delete(`/bank/${bankId}`)
      .then((response) => {
        toast.success("Bank deleted!", {
          id: toastId,
          duration: 4000,
          style: {
            background: "#353434",
            color: "#fff",
          },
        });
      })
      .catch((error) => {
        console.log(error);
        if (!error.response.data.errors) {
          return navigate(`/no-connection`);
        }
        toast.error(`${error.response.data.errors[0].message}`, {
          id: toastId,
          duration: 4000,
          style: {
            background: "#353434",
            color: "#fff",
          },
        });
      });
  };

  return (
    <>
      {bankLoading ? (
        <div className="lds-ctn">
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <div>
          {bank?.length > 0 ? (
            <div className="row">
              {bank?.map((details, index) => (
                <div
                  className="col-lg-6 col-md-8 col-sm-10 col-12 "
                  key={index}
                >
                  <div className="bank-content">
                    <div className="row bank-ctn">
                      <div className="col-lg-12">
                        <div className="bank-wrapper">
                          <label>{t("Bank_Name")}:</label>
                          <div className="div-bank">{details.bankName}</div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="bank-wrapper">
                          <label>{t("Account_Name")}:</label>
                          <div className="div-bank">{details.accountName}</div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="bank-wrapper">
                          <label>{t("Account_Number")}:</label>
                          <div className="div-bank">
                            {details.accountNumber}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bank-svg-ctn">
                      <span
                        onClick={() => getBankInfo(details.id)}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal3"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                          />
                        </svg>
                      </span>
                      <span
                        className="delete-bank"
                        onClick={() => submitDelete(details.id)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <h5 className="mb-5">{t("Accouint_details")}</h5>
          )}

          <div className="seller-footer">
            <div className="seller-seting-submit">
              <button
                type="button"
                className="btn btn-primary changepassword"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal2"
              >
                {t("Add_bank")}
              </button>
            </div>
          </div>

          {/* modal for end bank details*/}
          <div
            className="modal fade"
            id="exampleModal3"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalToggleLabel">
                    {t("Edit_Bank_Details")}
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                {loading ? (
                  <div className="lds-ctn">
                    <div className="lds-ring">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="modal-body mx-2">
                      <form>
                        <div className="form-group password">
                          <label>{t("Bank_Name")}</label>
                          <div className="form-control passwordToggle">
                            <input
                              type="text"
                              id="exampleInputPassword1"
                              name="bankName"
                              value={bankName}
                              onChange={(e) => setBankName(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="form-group password">
                          <label>{t("Account_Name")}</label>
                          <div className="form-control passwordToggle">
                            <input
                              type="text"
                              name="accountName"
                              value={accountName}
                              onChange={(e) => setAccountName(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="form-group password">
                          <label>{t("Account_Number")}</label>
                          <div className="form-control passwordToggle">
                            <input
                              type="text"
                              name="accountNumber"
                              value={accountNumber}
                              onChange={(e) => setAccountNumber(e.target.value)}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="modal-footer mx-2 mb-5">
                      {!editLoader ? (
                        <button
                          type="submit"
                          className="btn btn-primary changepassword"
                          onClick={() => handleEditBankDetaills(bankId)}
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          {t("Update")}
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-primary changepassword"
                        >
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>{" "}
                        </button>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          {/* end edit bank Modal */}

          {/* modal for bank details*/}
          <div
            className="modal fade"
            id="exampleModal2"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalToggleLabel">
                    {t("Add_Bank_Details")}
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body mx-2">
                  <form>
                    <div className="form-group password">
                      <label>{t("Bank_Name")}</label>
                      <div className="form-control passwordToggle">
                        <input
                          type="text"
                          id="exampleInputPassword1"
                          name="bankName"
                          value={bankDetails.bankName}
                          onChange={handleBankChange}
                        />
                      </div>
                    </div>
                    <div className="form-group password">
                      <label>{t("Account_Name")}</label>
                      <div className="form-control passwordToggle">
                        <input
                          type="text"
                          name="accountName"
                          value={bankDetails.accountName}
                          onChange={handleBankChange}
                        />
                      </div>
                    </div>
                    <div className="form-group password">
                      <label>{t("Account_Number")}</label>
                      <div className="form-control passwordToggle">
                        <input
                          type="text"
                          name="accountNumber"
                          value={bankDetails.accountNumber}
                          onChange={handleBankChange}
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div className="modal-footer mx-2 mb-5">
                  {!bankLoader ? (
                    <button
                      type="submit"
                      className="btn btn-primary changepassword"
                      onClick={handleBankUpdate}
                    >
                      {t("Save")}
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-primary changepassword"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* end bank Modal */}
        </div>
      )}
    </>
  );
};

export default SellerBankDetails;
