import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const CardSkeleton = () => {
  return (
    <div>
      <div className="topbar">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-4">
              <p>
                <Skeleton height={20} />
              </p>
            </div>
            <div className="col-lg-8 col-8" align="right">
              <ul className="tb-menu">
                <li>
                  <Skeleton height={20} />
                </li>
                <li>
                  <Skeleton height={20} />
                </li>
                <li>
                  <Skeleton height={20} />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <nav className="navbar sticky-top shadow-sm">
        <div className="container">
          <div className="nav-wrap">
            <div className="d-flex align-items-center">
              <div className="logo">
                <Skeleton height={50} />
              </div>
            </div>
            <div className="search">
              <Skeleton height={50} />
            </div>
            <div className="menu">
              <ul className="nav-menu">
                <li className=" mobile-search">
                  <Skeleton height={20} />
                </li>
                <li className="dropstart">
                  <Skeleton height={50} />
                </li>
                <li className="">
                  <Skeleton height={50} />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      <section id="main-hero-section" className="mt-4">
        <div className="container">
          <div
            id="carouselExampleSlidesOnly"
            className="carousel slide carousel-fade"
            data-bs-ride="carousel"
          >
            <Skeleton height={200} />
          </div>
        </div>
      </section>
      {/* End of Home Hero Section */}

      {/* New Products */}
      <section id="featured-products">
        <div className="container">
          <div className="main-container">
            <div className="p-container">
              <div className="product-card">
                <div className="product-card-img">
                  <Skeleton height={100} />
                </div>
                <div className="product-card-info">
                  <h3 className="seller-name">
                    <Skeleton count={4} />
                  </h3>
                </div>
              </div>
              <div className="product-card">
                <div className="product-card-img">
                  <Skeleton height={100} />
                </div>
                <div className="product-card-info">
                  <h3 className="seller-name">
                    <Skeleton count={4} />
                  </h3>
                </div>
              </div>
              <div className="product-card">
                <div className="product-card-img">
                  <Skeleton height={100} />
                </div>
                <div className="product-card-info">
                  <h3 className="seller-name">
                    <Skeleton count={4} />
                  </h3>
                </div>
              </div>
              <div className="product-card">
                <div className="product-card-img">
                  <Skeleton height={100} />
                </div>
                <div className="product-card-info">
                  <h3 className="seller-name">
                    <Skeleton count={4} />
                  </h3>
                </div>
              </div>
              <div className="product-card">
                <div className="product-card-img">
                  <Skeleton height={100} />
                </div>
                <div className="product-card-info">
                  <h3 className="seller-name">
                    <Skeleton count={4} />
                  </h3>
                </div>
              </div>
              <div className="product-card">
                <div className="product-card-img">
                  <Skeleton height={100} />
                </div>
                <div className="product-card-info">
                  <h3 className="seller-name">
                    <Skeleton count={4} />
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Banner Area */}
      <section className="banner-area">
        <div className="container">
          <div className="banner-wrap">
            <Skeleton height={100} />
          </div>
        </div>
      </section>
      {/* End of Banner Area */}

      {/* New Products */}
      <section id="featured-products">
        <div className="container">
          <div className="main-container">
            <div className="p-container">
              <div className="product-card">
                <div className="product-card-img">
                  <Skeleton height={100} />
                </div>
                <div className="product-card-info">
                  <h3 className="seller-name">
                    <Skeleton count={4} />
                  </h3>
                </div>
              </div>
              <div className="product-card">
                <div className="product-card-img">
                  <Skeleton height={100} />
                </div>
                <div className="product-card-info">
                  <h3 className="seller-name">
                    <Skeleton count={4} />
                  </h3>
                </div>
              </div>
              <div className="product-card">
                <div className="product-card-img">
                  <Skeleton height={100} />
                </div>
                <div className="product-card-info">
                  <h3 className="seller-name">
                    <Skeleton count={4} />
                  </h3>
                </div>
              </div>
              <div className="product-card">
                <div className="product-card-img">
                  <Skeleton height={100} />
                </div>
                <div className="product-card-info">
                  <h3 className="seller-name">
                    <Skeleton count={4} />
                  </h3>
                </div>
              </div>
              <div className="product-card">
                <div className="product-card-img">
                  <Skeleton height={100} />
                </div>
                <div className="product-card-info">
                  <h3 className="seller-name">
                    <Skeleton count={4} />
                  </h3>
                </div>
              </div>
              <div className="product-card">
                <div className="product-card-img">
                  <Skeleton height={100} />
                </div>
                <div className="product-card-info">
                  <h3 className="seller-name">
                    <Skeleton count={4} />
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of New Products */}

      {/* Features */}

      <section id="features-wrap">
        <div className="container">
          <div className="section-head">
            <h2>
              <Skeleton height={10} />
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-3">
              <div className="feature-card">
                <Skeleton height={50} />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="feature-card">
                <Skeleton height={50} />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="feature-card">
                <Skeleton height={50} />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="feature-card">
                <Skeleton height={50} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CardSkeleton;
