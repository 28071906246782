import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import BuyersSidebar from "../BuyersComponent/BuyerSideBar";
import "../../Dashboard.css";

import { ProtectedRoutes } from "../../../../components/utils/ProtectedRoutes";
import { axiosInstance } from "../../../../components/baseUrl";

import ResponseInfo from "./ResponseInfo";
import SearchInput from "../BuyersComponent/SearchInput";
import PaginationComponent from "../BuyersComponent/Pagination";
import { Capitalize } from "../../../../components/helpers/functionHelpers";
import cookies from "js-cookie";
import { convertPrice } from "../../../../components/helpers/convertPrice";
import { convertDateFormat } from "../../../../components/helpers/convertDate";
import { BigCard } from "../../../../components/SkeletonLoader/BigCard";

const BuyerMultipleInfo = () => {
  const { t } = useTranslation();
  const [rfqInfo, setRfqInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const { rfqId } = useParams();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const ITEMS_PER_PAGE = 2;
  const [noMatch, setNoMatch] = useState(false);
  const [responses, setResponses] = useState([]);
  const [responseInfo, setResponseInfo] = useState({});
  const [respInfoLoad, setRespInfoLoad] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  const selectedCurrency = cookies.get("currency") || "NGN";

  const handleClick = (event) => {
    setIsActive((current) => !current);
  };

  const getRfqResponseInfo = async (respId) => {
    setRespInfoLoad(true);
    try {
      const { data } = await axiosInstance.get(
        `/multiple-rfq/response/${respId}`
      );
      setResponseInfo(data.data);
      setRespInfoLoad(false);
    } catch (error) {
      console.log(error);
      setRespInfoLoad(false);
      if (error.message && error.message === "Network Error") {
        navigate("/no-connection");
      }
    }
  };

  const getRfqInfo = async () => {
    try {
      axiosInstance.get(`/multiple-rfq/${rfqId}`).then((response) => {
        setRfqInfo(response.data.data);
        if (response.data.data.defaultResponse) {
          setResponses(response.data.data.defaultResponse);
        }
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error.message && error.message === "Network Error") {
        navigate("/no-connection");
      }
    }
  };

  useEffect(() => {
    getRfqInfo();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  const responseData = useMemo(() => {
    let computedrfqs = responses;

    if (search) {
      computedrfqs = computedrfqs.filter(
        (resp) =>
          resp.user.firstName.toLowerCase().includes(search.toLowerCase()) ||
          resp.user.LastName.toLowerCase().includes(search.toLowerCase())
      );
      if (computedrfqs.length < 1) {
        setNoMatch(true);
        setTotalItems(0);
      } else if (computedrfqs.length > 0) {
        setNoMatch(false);
      }
    } else {
      setNoMatch(false);
    }

    if (computedrfqs.length) {
      setTotalItems(computedrfqs.length);
    }

    return computedrfqs.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [responses, currentPage, search]);

  return (
    <>
      {/* <ToastContainer /> */}
      <div className="grid-container">
        <div className={"menuu-icon"} onClick={handleClick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-6 h-6"
          >
            <path
              fillRule="evenodd"
              d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <header className="header">
          <div className="header__search">
            <SearchInput
              onSearch={(value) => {
                setSearch(value);
                setCurrentPage(1);
              }}
              placeholder="Search for rfqs, product name and more"
            />
          </div>
        </header>

        <BuyersSidebar isActive={isActive} handleClick={handleClick} />

        {loading ? (
          <BigCard />
        ) : (
          <main className="main">
            <h4 className="quote-titlee">{t("RFQ_details")}</h4>
            <div className="col-lg-12">
              <div className="main-overview">
                <div className="overview-card no-padding">
                  <div className="product-info">
                    <p className="product-info-title">{t("Product_Name")}</p>

                    <p className="product-info-description">
                      {rfqInfo.productName && Capitalize(rfqInfo.productName)}
                    </p>
                  </div>
                  <div className="product-info">
                    <p className="product-info-title">{t("Quantity")}</p>
                    <p className="product-info-description">
                      {rfqInfo && rfqInfo.quantityRequired}{" "}
                      {rfqInfo && rfqInfo.unit}
                    </p>
                  </div>
                  <div className="product-info">
                    <p className="product-info-title">{t("Targeted_price")}</p>
                    <p className="product-info-description">
                      {selectedCurrency}{" "}
                      {rfqInfo &&
                        convertPrice(rfqInfo.targetPrice, selectedCurrency)}
                    </p>
                  </div>
                  <div className="product-info">
                    <p className="product-info-title">{t("Incoterm")}</p>
                    <p className="product-info-description">
                      {rfqInfo && rfqInfo.termsOfTrade}
                    </p>
                  </div>
                  <div className="product-info">
                    <p className="product-info-title">{t("Destination")}</p>
                    <p className="product-info-description">
                      {rfqInfo && rfqInfo.destinationPort}
                    </p>
                  </div>

                  <div className="product-requirement">
                    <p className="product-info-title">
                      {t("Product_Requirement")}
                    </p>
                    <p>{rfqInfo && rfqInfo.productDescription}</p>
                  </div>
                </div>
              </div>
            </div>
            <>
              <h4 className="quote-titlee">{t("Recieved_Quotes")}</h4>
              <div className="product-quote">
                {rfqInfo.defaultResponse.length > 0 ? (
                  <div className="main-overview">
                    <div className="overview-card no-padding">
                      <div className="table-responsive">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col">{t("Date")}</th>
                              <th scope="col">{t("Supplier")}</th>
                              <th scope="col">{t("Price")}</th>
                              <th scope="col">{t("Quantity")}</th>
                              <th scope="col">{t("Prompt")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {responseData &&
                              responseData.map((rfq, index) => (
                                <tr key={index}>
                                  <td>
                                    {rfq.createdAt &&
                                      convertDateFormat(rfq.createdAt)}
                                  </td>
                                  <td>
                                    {rfq.user && rfq.user.firstName}{" "}
                                    {rfq.user && rfq.user.LastName}
                                  </td>

                                  <td>
                                    {selectedCurrency}{" "}
                                    {rfq.price &&
                                      convertPrice(rfq.price, selectedCurrency)}
                                  </td>
                                  <td>
                                    {rfqInfo && rfqInfo.quantityRequired}{" "}
                                    {rfqInfo && rfqInfo.unit}
                                  </td>
                                  <td>
                                    <p className="buyerviewquote">
                                      <a
                                        data-bs-toggle="modal"
                                        href="#exampleModalToggle"
                                        role="button"
                                        onClick={(e) =>
                                          getRfqResponseInfo(rfq.id)
                                        }
                                      >
                                        {t("View_Quote")}
                                      </a>
                                    </p>
                                    <div className="rfq-modaal">
                                      {" "}
                                      <ResponseInfo
                                        rfqInfo={rfqInfo}
                                        responseInfo={responseInfo}
                                        Capitalize={Capitalize}
                                        respInfoLoad={respInfoLoad}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>{t("No_response_from_seller_yet")}</div>
                )}
              </div>
            </>
            {noMatch === true ? (
              <div className="empty-state">
                <p>{t("No_RFQ_result")}</p>
              </div>
            ) : (
              <PaginationComponent
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            )}
          </main>
        )}
        {/* <MessageModal /> */}
      </div>
    </>
  );
};

export default ProtectedRoutes(BuyerMultipleInfo, ["BUYER", "SELLER"]);
