import { useContext } from "react";
import { axiosInstance } from "../baseUrl";
import { GlobalContext } from "../utils/GlobalState";

export const useSignOut = () => {
  const { setUser } = useContext(GlobalContext);

  const handleSignOut = () => {
    axiosInstance
      .get(`/auth/signout`)
      .then((response) => {
        setUser(null);
        localStorage.setItem("user", false);
        localStorage.removeItem("token");
        localStorage.clear();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return {
    handleSignOut,
  };
};
