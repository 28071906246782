import React, { useState, useEffect, useMemo, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GlobalContext } from "../../../../components/utils/GlobalState";
import { useTranslation } from "react-i18next";

import SellersSidebar from "../SellersComponent/SellerSideBar";
import { axiosInstance } from "../../../../components/baseUrl.jsx";

import { ProtectedRoutes } from "../../../../components/utils/ProtectedRoutes";
import SearchInput from "../../BuyersDashboard/BuyersComponent/SearchInput";
import PaginationComponent from "../../BuyersDashboard/BuyersComponent/Pagination";

import SellerMultipleModal from "./SellerMultipleModal";
import SellersHeader from "../SellersComponent/SellersHeader";
import { convertDateFormat } from "../../../../components/helpers/convertDate";
import {
  numberWithCommas,
  Capitalize,
} from "../../../../components/helpers/functionHelpers";
import CardOverviewLoader from "../../../../components/SkeletonLoader/CardOverviewLoader";
import TableSkeleton from "../../../../components/SkeletonLoader/TableSkeleton";
import { convertPrice } from "../../../../components/helpers/convertPrice";
import cookies from "js-cookie";

const MultipleRfq = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const ITEMS_PER_PAGE = 10;
  const [noMatch, setNoMatch] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [rfqLoading, setRfqLoading] = useState(true);
  const [rfqInfo, setRfqInfo] = useState({});
  const [allRfq, setAllRfq] = useState([]);
  const { user } = useContext(GlobalContext);

  const selectedCurrency = cookies.get("currency") || "NGN";

  const navigate = useNavigate();

  const handleClick = (event) => {
    setIsActive((current) => !current);
  };

  const viewRfqInfo = async (rfqId) => {
    setLoading(true);
    await axiosInstance
      .get(`/multiple-rfq/${rfqId}`)
      .then((response) => {
        setRfqInfo(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        if (error.message && error.message === "Network Error") {
          navigate("/no-connection");
        }
      });
  };

  const getAllRfq = async () => {
    try {
      const { data } = await axiosInstance.get("/multiple-rfq/all");
      setAllRfq(data.data);
      setRfqLoading(false);
    } catch (error) {
      console.log(error);
      if (error.message && error.message === "Network Error") {
        navigate("/no-connection");
      }
      setRfqLoading(false);
    }
  };

  useEffect(() => {
    getAllRfq();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  const rfqsData = useMemo(() => {
    let computedrfqs = allRfq;

    if (search) {
      computedrfqs = computedrfqs.filter(
        (rfq) =>
          rfq.productName.toLowerCase().includes(search.toLowerCase()) ||
          rfq.status.toLowerCase().includes(search.toLowerCase()) ||
          rfq.destinationPort.toLowerCase().includes(search.toLowerCase())
      );
      if (computedrfqs.length < 1) {
        setNoMatch(true);
        setTotalItems(0);
      } else if (computedrfqs.length > 0) {
        setNoMatch(false);
      }
    } else {
      setNoMatch(false);
    }

    setTotalItems(computedrfqs.length);

    return computedrfqs.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [allRfq, currentPage, search]);

  return (
    <>
      <div className="grid-container">
        <SellersHeader
          user={user}
          handleClick={handleClick}
          title="All RFQ's"
        />

        <SellersSidebar isActive={isActive} handleClick={handleClick} />

        {/* {rfqLoading ? (
          <CardSkeleton />
        ) : ( */}
        <main className="main">
          {rfqLoading ? (
            <CardOverviewLoader />
          ) : (
            <div className="row">
              <div className="col-lg-4 col-sm-6 col-12">
                <div className="overview-card">
                  <div>
                    <h2>{t("All_RFQ's")}</h2>
                    <p className="order-p">{t("Scroll_down")}</p>
                    <div className="d-flex justify-content-between mt-4">
                      <h3>{allRfq.length > 0 ? allRfq.length : 0}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="section-title-ctn">
            <h1 className="section-title">{t("All_RFQ's")}</h1>
            <div className="header__search">
              <SearchInput
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
                placeholder="Search for RFQs, RFQ status and more"
              />
            </div>
          </div>

          {rfqLoading ? (
            <TableSkeleton />
          ) : (
            <>
              {allRfq && allRfq.length > 0 ? (
                <div className="main-overview">
                  <div className="overview-card no-padding">
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">{t("Date")}</th>
                            <th scope="col">{t("Enquiry_Info")}</th>
                            <th scope="col">{t("Target_price")}</th>
                            <th scope="col">{t("Quantity")}</th>
                            <th scope="col">{t("Incoterms")}</th>
                            <th scope="col">{t("Payment_term")}</th>
                            <th scope="col">{t("Action")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {rfqsData.map((rfq, index) => (
                            <tr key={index}>
                              <td>
                                {rfq.createdAt &&
                                  convertDateFormat(rfq.createdAt)}
                              </td>
                              <td>
                                {rfq.productName && Capitalize(rfq.productName)}
                              </td>
                              <td>
                                {selectedCurrency}{" "}
                                {rfq.targetPrice &&
                                  convertPrice(
                                    rfq.targetPrice,
                                    selectedCurrency
                                  )}
                              </td>
                              <td>
                                {rfq && rfq.quantityRequired} {rfq.unit}
                              </td>
                              <td>{rfq && rfq.termsOfTrade}</td>
                              <td>{rfq && rfq.paymentTerms}</td>

                              <td>
                                <a
                                  data-bs-toggle="modal"
                                  href="#staticBackdrop"
                                  role="button"
                                  onClick={(e) => viewRfqInfo(rfq.id)}
                                >
                                  {t("Submit_response")}
                                </a>
                                <SellerMultipleModal
                                  rfqInfo={rfqInfo}
                                  Capitalize={Capitalize}
                                  numberWithCommas={numberWithCommas}
                                  loading={loading}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="empty-state">
                  <h3>{t("There_are_no_RFQs_created_for_your_product.")}</h3>
                  <p>
                    {t("Create a product")}{" "}
                    <Link to="/create-new-product">{t("here")}.</Link>{" "}
                    {t("All_your_RFQ_will")}
                  </p>
                </div>
              )}
              {noMatch === true ? (
                <div className="empty-state">
                  <h4>{t("No_results_found")}</h4>
                  <p>{t("No_RFQ_matched_your_criteria.")}</p>
                </div>
              ) : (
                <PaginationComponent
                  total={totalItems}
                  itemsPerPage={ITEMS_PER_PAGE}
                  currentPage={currentPage}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              )}
            </>
          )}
        </main>
        {/* )} */}
      </div>
    </>
  );
};

export default ProtectedRoutes(MultipleRfq, ["SELLER"]);
