import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./ReviewDetails.css";
import { useGetReviewDetailsForEachProduct } from "../../components/hooks/useGetReviewDetailsForEachProduct";
import { useParams } from "react-router-dom";
import {
  Capitalize,
  numberWithCommas,
} from "../../components/helpers/functionHelpers";
import {
  fiveStar,
  fourStar,
  noStar,
  oneStar,
  threeStar,
  twoStar,
} from "../../components/constants";
import { convertDateFormat } from "../../components/helpers/convertDate";
import ReviewLoader from "../../components/SkeletonLoader/ReviewLoader";
import NewHeader from "../../components/NewHeader/NewHeader";

const ReviewDetails = () => {
  const { t } = useTranslation();
  const { productId } = useParams();
  const { reviewDetails, isLoading, getProductReviews } =
    useGetReviewDetailsForEachProduct();

  const isImage = (url) => {
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "svg"];
    const extension = url.split(".").pop().toLowerCase();
    return imageExtensions.includes(extension);
  };

  useEffect(() => {
    getProductReviews(productId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  return (
    <div>
      <NewHeader />
      {isLoading ? (
        <div className="container">
          <ReviewLoader />
        </div>
      ) : (
        <div className="container">
          <div className="py-5">
            <div className="product-review-details-wrapper">
              <h2>
                {reviewDetails?.productName && reviewDetails?.productName}
              </h2>
              <div className="product-review-details-ctn">
                <div className="product-review-details-product-ctn">
                  <div className="product-review-details-product-name">
                    <h2>
                      {reviewDetails?.averageStarRating &&
                        reviewDetails.averageStarRating}
                      /5
                    </h2>
                    <div className="reviews-star-ctn">
                      {reviewDetails?.averageStarRating < 1
                        ? noStar.map((star, index) => (
                            <img src={star} alt="star" key={index} />
                          ))
                        : reviewDetails?.averageStarRating < 2 &&
                          reviewDetails?.averageStarRating > 0
                        ? oneStar.map((star, index) => (
                            <img src={star} alt="star" key={index} />
                          ))
                        : reviewDetails?.averageStarRating < 3 &&
                          reviewDetails?.averageStarRating > 1
                        ? twoStar.map((star, index) => (
                            <img src={star} alt="star" key={index} />
                          ))
                        : reviewDetails?.averageStarRating < 4 &&
                          reviewDetails?.averageStarRating > 2
                        ? threeStar.map((star, index) => (
                            <img src={star} alt="star" key={index} />
                          ))
                        : reviewDetails?.averageStarRating < 5 &&
                          reviewDetails?.averageStarRating > 3
                        ? fourStar.map((star, index) => (
                            <img src={star} alt="star" key={index} />
                          ))
                        : reviewDetails?.averageStarRating < 6 &&
                          reviewDetails?.averageStarRating > 4
                        ? fiveStar.map((star, index) => (
                            <img src={star} alt="star" key={index} />
                          ))
                        : null}
                    </div>
                    <h6>
                      {reviewDetails?.totalReviews &&
                        numberWithCommas(reviewDetails?.totalReviews)}{" "}
                      {t("Ratings")}
                    </h6>
                  </div>
                </div>
                {reviewDetails?.reviews?.length > 0 ? (
                  <div className="product-review-details-user-wrapper">
                    {reviewDetails?.reviews?.map((review, index) => (
                      <div className="product-review-details-user-content">
                        <div className="reviews-star-ctn">
                          {review?.starNumber < 1
                            ? noStar.map((star, index) => (
                                <img src={star} alt="star" key={index} />
                              ))
                            : review?.starNumber < 2 && review?.starNumber > 0
                            ? oneStar.map((star, index) => (
                                <img src={star} alt="star" key={index} />
                              ))
                            : review?.starNumber < 3 && review?.starNumber > 1
                            ? twoStar.map((star, index) => (
                                <img src={star} alt="star" key={index} />
                              ))
                            : review?.starNumber < 4 && review?.starNumber > 2
                            ? threeStar.map((star, index) => (
                                <img src={star} alt="star" key={index} />
                              ))
                            : review?.starNumber < 5 && review?.starNumber > 3
                            ? fourStar.map((star, index) => (
                                <img src={star} alt="star" key={index} />
                              ))
                            : review?.starNumber < 6 && review?.starNumber > 4
                            ? fiveStar.map((star, index) => (
                                <img src={star} alt="star" key={index} />
                              ))
                            : null}
                        </div>
                        <p>{review?.reviewMessage && review?.reviewMessage}</p>
                        {review.videoUrl ? (
                          <>
                            {isImage(review?.videoUrl) ? (
                              <a
                                href={review.videoUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {t("View_image")}
                              </a>
                            ) : (
                              <a
                                href={review.videoUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {t("Watch_video")}
                              </a>
                            )}
                          </>
                        ) : null}
                        <div className="review-date-ctn">
                          <h4>
                            {review?.createdAt &&
                              convertDateFormat(review?.createdAt)}{" "}
                          </h4>
                          <p>
                            {" "}
                            by{" "}
                            {review?.User?.firstName &&
                              Capitalize(review?.User?.firstName)}{" "}
                            {review?.User?.LastName &&
                              Capitalize(review?.User?.LastName)}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="no-review">
                    {t("No_review_for_this_product_yet")}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReviewDetails;
