import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { axiosInstance } from "../../../../components/baseUrl";
import { useNavigate } from "react-router-dom";
import cookies from "js-cookie";
import { convertPrice } from "../../../../components/helpers/convertPrice";
import TofaBankDetails from "../BuyersComponent/TofaBankDetails";

const BuyerRfqModal = ({
  rfqInfo,
  Capitalize,
  loader,
  handleSubmit,
  orderMsg,
}) => {
  const { t } = useTranslation();
  const [selectedCurrenci, setSelectedCurrenci] = useState("");
  const selectedCurrency = cookies.get("currency") || "NGN";
  const currentSelectedCurrency = selectedCurrenci || selectedCurrency;

  const navigate = useNavigate();

  const handleCurrencyChange = (e) => {
    setSelectedCurrenci(e.target.value);
  };

  const config = {
    public_key: process.env.REACT_APP_FLUTTERWAVE_API_KEY,
    tx_ref: Date.now(),
    amount: convertPrice(
      rfqInfo?.supplierResponse?.price,
      currentSelectedCurrency
    ),
    currency: currentSelectedCurrency,
    payment_options: "card,mobilemoney,ussd",
    customer: {
      email: rfqInfo.user.email,
      phone_number: rfqInfo.user.phoneNumber,
      name: rfqInfo.user.firstName + " " + rfqInfo.user.lastName,
    },
    customizations: {
      title: "Traders Of Africa",
      description: "Payment for items in cart",
      logo: "https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg",
    },
  };

  const handleFlutterPayment = useFlutterwave(config);

  const support = process.env.REACT_APP_SUPPORT;

  return (
    <div>
      <div
        className="modal fade"
        id="exampleModalToggle"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          {orderMsg && rfqInfo?.paymentTerms === "LC" ? (
            <TofaBankDetails />
          ) : orderMsg && rfqInfo?.paymentTerms === "TT" ? (
            <TofaBankDetails />
          ) : orderMsg && rfqInfo?.paymentTerms === "PAYNOW" ? (
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{t("Payment_details")}</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="payment-modal">
                  <h6 className="mt-3">{t("Your_order")} </h6>

                  <div className="nigeria-order-ctn">
                    <p className="title">{t("To_process_order.")}</p>
                    <ul>
                      <li>
                        <h1>{t("Select_the_currency")} </h1>
                        <div>
                          <label htmlFor="currency">
                            {t("Select_Currency")}:
                          </label>
                          <select
                            id="currency"
                            value={selectedCurrenci}
                            onChange={handleCurrencyChange}
                          >
                            <option value="USD">USD</option>
                            <option value="EUR">EUR</option>
                            <option value="GBP">GBP</option>
                            <option value="NGN">NGN</option>
                            <option value="XOF">XOF</option>
                          </select>
                        </div>
                        <button
                          onClick={() => {
                            handleFlutterPayment({
                              callback: (response) => {
                                // Payment successful
                                const transactionId =
                                  response.tx_ref.toString();
                                const totalAmountUSD = Number(
                                  rfqInfo?.supplierResponse?.price
                                );

                                const userId = rfqInfo.user.userId;

                                // Send payment information to your backend
                                axiosInstance
                                  .post("/payment", {
                                    transactionId,
                                    userId,
                                    totalAmountUSD,
                                    // orderId,
                                  })
                                  .then((response) => {
                                    navigate("/order/success-message");
                                  })
                                  .catch((error) => {
                                    console.error(
                                      "Error sending payment info",
                                      error
                                    );
                                  });
                                closePaymentModal();
                              },
                              onClose: () => {
                                // Payment canceled or closed
                                console.log("Payment canceled or closed.");
                              },
                            });
                          }}
                        >
                          {t("Pay_with_Card")}
                        </button>
                      </li>
                    </ul>
                  </div>
                  <p className="notice">
                    {t("For_more_info/")}{" "}
                    <span className="title">{support}</span>
                  </p>
                </div>
              </div>
            </div>
          ) : orderMsg && rfqInfo?.paymentTerms === "TRANSFER" ? (
            <TofaBankDetails />
          ) : (
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="quote-modal">
                  <div className="rfq-details">
                    <p className="px-2">{t("RFQ's_Detail")}</p>
                    <div className="product-info supplier-product-info">
                      <p className="product-info-title">{t("Product_Name")}</p>
                      <p className="product-info-description">
                        {rfqInfo.productName && Capitalize(rfqInfo.productName)}
                      </p>
                    </div>
                    <div className="product-info supplier-product-info">
                      <p className="product-info-title">{t("Incoterms")}</p>
                      <p className="product-info-description">
                        {rfqInfo && rfqInfo.termsOfTrade}
                      </p>
                    </div>
                    <div className="product-info supplier-product-info">
                      <p className="product-info-title">{t("Destination")}</p>
                      <p className="product-info-description">
                        {rfqInfo && rfqInfo.destinationPort}
                      </p>
                    </div>
                    <div className="product-requirement supplier-product-requirement">
                      <p className="product-info-title">
                        {t("Product_Requirement")}
                      </p>
                      <p>{rfqInfo && rfqInfo.productDescription}</p>
                    </div>
                  </div>

                  <div className="supplier-response">
                    <p className="px-2">{t("Supplier's_Response")}</p>
                    <div className="product-infoo supplier-product-info">
                      <p className="product-info-title">{t("Price")}</p>
                      <p className="product-info-description">
                        {currentSelectedCurrency}{" "}
                        {rfqInfo.supplierResponse &&
                          convertPrice(
                            rfqInfo.supplierResponse?.price,
                            currentSelectedCurrency
                          )}
                      </p>
                    </div>
                    <div className="product-infoo supplier-product-info">
                      <p className="product-info-title">{t("Quantity")}</p>
                      <p className="product-info-description">
                        {rfqInfo && rfqInfo.quantityRequired}{" "}
                        {rfqInfo && rfqInfo.unit}
                      </p>
                    </div>
                    <div className="product-infoo supplier-product-info">
                      <p className="product-info-title">{t("Total_cost")}</p>
                      <p className="product-info-description">
                        {currentSelectedCurrency}{" "}
                        {rfqInfo.supplierResponse &&
                          convertPrice(
                            rfqInfo.supplierResponse?.price,
                            currentSelectedCurrency
                          )}
                      </p>
                    </div>
                    <div className="product-infoo supplier-product-info">
                      <p className="product-info-title">{t("Notes")}:</p>
                      <p className="product-info-description">
                        {rfqInfo && rfqInfo.supplierResponse?.additionalNote}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer py-4">
                {loader ? (
                  <button
                    className="btn btn-primary py-2 px-4"
                    id="start-order-button"
                  >
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </button>
                ) : (
                  <button
                    className="btn btn-primary py-2 px-4 "
                    id="start-order-button"
                    onClick={(e) => handleSubmit(e)}
                  >
                    {t("Start_Order")}{" "}
                  </button>
                )}
                <button
                  className="btn btn-light py-2 px-4 add-radius"
                  id="message-button"
                  data-bs-target="#exampleModalToggle2"
                  data-bs-toggle="modal"
                  data-bs-dismiss="modal"
                >
                  {t("Message_Supplier")}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BuyerRfqModal;
