import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./SellersStore.css";

import Footer from "../../components/Footer/Footer";

import { axiosInstance } from "../../components/baseUrl";

import CardSkeleton from "../../components/SkeletonLoader/CardSkeleton";
import MessageSupplier from "./MessageSupplier";

import { GlobalContext } from "../../components/utils/GlobalState";
import CreateMultipleRfq from "../AllRfq/CreateMultipleRfq";
import {
  Capitalize,
  numberWithCommas,
} from "../../components/helpers/functionHelpers";
import cookies from "js-cookie";
import { ProtectedRoutes } from "../../components/utils/ProtectedRoutes";
import { CurrencyExchangeContext } from "../../components/utils/CurrencyExchangeState";
import NewHeader from "../../components/NewHeader/NewHeader";

const SellersStore = () => {
  const { t } = useTranslation();
  const selectedCurrency = cookies.get("currency") || "NGN";
  const { convertCurrency } = useContext(CurrencyExchangeContext);
  const ref = React.useRef(null);

  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  };
  const [sellerData, setSellerData] = useState({});
  const [loading, setLoading] = useState(true);
  const { sellerId } = useParams();
  const { user, userLoading } = useContext(GlobalContext);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  const getAllProducts = async () => {
    try {
      axiosInstance.get(`/auth/users/user/${sellerId}`).then((response) => {
        setSellerData(response.data.data);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error.message && error.message === "Network Error") {
        navigate("/no-connection");
      }
    }
  };

  useEffect(() => {
    getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerId]);

  if (loading || userLoading) {
    return <CardSkeleton />;
  }

  return (
    <>
      <NewHeader />
      <div>
        <div className="supplier-info-container">
          <div className="Browse-Product">
            <div className="container">
              <Link to="/category/all">
                {" "}
                <button className="back-btn">
                  {" "}
                  <i className="fas fa-arrow-left"></i> {t("Browse_Product")}
                </button>
              </Link>

              <div className="Browse-product-info-container">
                {/* <div className="">
                  <div className="Browse-Product-image">
                    <img src={brosweproduct} alt="" className="d-block w-100" />
                  </div>
                </div> */}
                <div className="Browse-Product-info-box">
                  <div className="Browse-Product-info">
                    <div className="">
                      <div className="browse-info-details">
                        <p>{t("First_Name")}:</p>
                        <h6>{Capitalize(sellerData?.firstName)}</h6>
                      </div>
                      <div className="browse-info-details">
                        <p>{t("Last_Name")}:</p>
                        <h6>{Capitalize(sellerData?.LastName)}</h6>
                      </div>
                      <div className="browse-info-details">
                        <p>{t("Company_Name")}:</p>
                        <h6>
                          {sellerData?.businessName &&
                            Capitalize(sellerData?.businessName)}
                        </h6>
                      </div>
                      <div className="browse-info-details">
                        <p>{t("Email_Address")}:</p>
                        <h6>
                          {sellerData?.email && Capitalize(sellerData?.email)}
                        </h6>
                      </div>
                      <div className="browse-info-details">
                        <p>{t("Phone_Number")}:</p>
                        <h6>{sellerData?.phoneNumber}</h6>
                      </div>
                      <div className="browse-info-details">
                        <p>{t("Country")}:</p>
                        <h6>
                          {sellerData.country && Capitalize(sellerData.country)}
                        </h6>
                      </div>
                      <div className="browse-info-details">
                        <p>{t("Products_Listed")}:</p>
                        <h6>{sellerData?.Product?.length}</h6>
                      </div>
                    </div>
                  </div>
                  {user?.role === "BUYER" && (
                    <div className="browse-info-buttons">
                      <button
                        className="browse-message"
                        data-bs-target="#exampleModalToggle2"
                        data-bs-toggle="modal"
                      >
                        {t("Message_Supplier")}
                      </button>
                      <button className="browse-share">
                        {t("Share_Contact")}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="Company-information-container">
              <div className="container">
                <div className="Company-information-box">
                  <ul className="all-information">
                    <li className="Company-info">
                      {t("Company_Summary")}
                      <span style={{ color: "#D92727", marginLeft: "10px" }}>
                        <i className="fas fa-street-view"></i>
                      </span>
                    </li>
                  </ul>
                  {/* <ul className="all-information">
                    <li className="Company-info">
                      Certifications & Awards
                      <span style={{ color: "#D92727", marginLeft: "10px" }}>
                        <i className="fas fa-exclamation-circle"></i>
                      </span>
                    </li>
                  </ul> */}
                  <ul className="all-information">
                    <li className="Company-info">
                      {t("Top_Transactions")}
                      <span style={{ color: "#D92727", marginLeft: "10px" }}>
                        <i className="fas fa-lock"></i>
                      </span>
                    </li>
                  </ul>
                </div>

                <div className="Description">
                  <ul>
                    <li>
                      {t("Business_Type")}:{" "}
                      {sellerData.businessType &&
                        Capitalize(sellerData.businessType)}
                    </li>
                    <li>
                      {t("Business_Description")}:{" "}
                      {sellerData.businessDescription &&
                        sellerData.businessDescription}
                    </li>
                    <li>
                      {t("Business_Address")}:{" "}
                      {sellerData.address && sellerData.address}
                    </li>
                    <li>
                      {t("Year_of_Establishment")}:{" "}
                      {sellerData.yearEstablished && sellerData.yearEstablished}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="Listed-Products">
                <div className="container">
                  <div className="section-head">
                    <h2>{t("Listed_Products")}</h2>
                    <div>
                      <i
                        className="fas fa-chevron-left me-4 scroll-icon"
                        onClick={() => scroll(-275)}
                      ></i>
                      <i
                        className="fas fa-chevron-right scroll-icon "
                        onClick={() => scroll(275)}
                      ></i>
                    </div>
                  </div>

                  <div className="product-wrap">
                    {sellerData.Product &&
                      sellerData.Product.map((product, index) => (
                        <Link to={`/product-info/${product.id}`} key={index}>
                          <div className="product-card">
                            <div className="product-card-img">
                              <img
                                src={product?.productImages[0]?.image}
                                className=""
                                alt="..."
                              />
                            </div>
                            <div className="product-card-info">
                              <h3 className="seller-name">
                                <>
                                  {sellerData?.businessName ? (
                                    <>{sellerData?.businessName}</>
                                  ) : (
                                    <>
                                      {product &&
                                        Capitalize(sellerData.firstName)}{" "}
                                      {product &&
                                        Capitalize(sellerData.LastName)}
                                    </>
                                  )}
                                </>
                              </h3>
                              <h2 className="Lp-product-name">
                                {product && Capitalize(product.productName)}
                              </h2>
                              <p className="Lp-product-price">
                                <span className="Lp-currency">
                                  {" "}
                                  {selectedCurrency}
                                </span>{" "}
                                {product.minPricePerUnit &&
                                  numberWithCommas(
                                    convertCurrency(
                                      product?.minPricePerUnit,
                                      product?.currencyType,
                                      selectedCurrency
                                    )
                                  )}
                                <span className="p-unit">
                                  /{product && product.unitForMinOrder}
                                </span>
                              </p>
                            </div>
                          </div>
                        </Link>
                      ))}
                  </div>
                </div>
                <div className="response-quote-box">
                  <div className="container">
                    <div className="response-quote">
                      <div className="quote-text">
                        <h5>{t("Get_Multiple")}</h5>
                        <p>{t("Are_you_interested")}</p>
                      </div>
                      {user ? (
                        <div className="request-quote">
                          <button
                            className="request-quotebtn"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            {t("Request_Quote")}
                          </button>
                        </div>
                      ) : (
                        <Link to="/login" className="request-quote">
                          <button className="request-quotebtn">
                            {t("Request_Quote")}
                          </button>
                        </Link>
                      )}
                    </div>
                    <CreateMultipleRfq />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MessageSupplier supplierInfo={sellerData} Capitalize={Capitalize} />
      <Footer />
    </>
  );
};

export default ProtectedRoutes(SellersStore, ["BUYER", "SELLER"]);
