import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const TableSkeleton = () => {
  return (
    <div>
      {/* Features */}

      <table className="table table-striped">
        <tbody className="my-5">
          <tr className="mb-4">
            <td>
              <Skeleton height={20} />
            </td>
          </tr>
          <tr className="mb-4">
            <td>
              <Skeleton height={20} />
            </td>
          </tr>
          <tr className="mb-4">
            <td>
              <Skeleton height={20} />
            </td>
          </tr>
          <tr className="mb-4">
            <td>
              <Skeleton height={20} />
            </td>
          </tr>
          <tr className="mb-4">
            <td>
              <Skeleton height={20} />
            </td>
          </tr>
          <tr className="mb-4">
            <td>
              <Skeleton height={20} />
            </td>
          </tr>
          <tr className="mb-4">
            <td>
              <Skeleton height={20} />
            </td>
          </tr>
          <tr className="mb-4">
            <td>
              <Skeleton height={20} />
            </td>
          </tr>
          <tr className="mb-4">
            <td>
              <Skeleton height={20} />
            </td>
          </tr>
          <tr className="mb-4">
            <td>
              <Skeleton height={20} />
            </td>
          </tr>
          <tr className="mb-4">
            <td>
              <Skeleton height={20} />
            </td>
          </tr>
          <tr className="mb-4">
            <td>
              <Skeleton height={20} />
            </td>
          </tr>
          <tr className="mb-4">
            <td>
              <Skeleton height={20} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TableSkeleton;
