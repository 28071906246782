import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../baseUrl";

export const useGetAllRfqs = () => {
  const navigate = useNavigate();

  const onRfqErr = (error) => {
    if (error) {
      navigate("/no-connection");
    }
  };

  const getAllRfss = () => {
    return axiosInstance
      .get(`/multiple-rfq/all`)
      .then((res) => res?.data?.data);
  };

  const { data: allRfq, isLoading: rfqLoading } = useQuery("rfq", getAllRfss, {
    onError: onRfqErr,
  });
  return {
    allRfq,
    rfqLoading,
  };
};
