import { useState } from "react";

export const useGetStoreLink = () => {
  const [copy, setCopy] = useState(false);

  const handleCopyStoreLink = (sellerId) => {
    const textToCopy = `https://marketplase.tradersofafrica.com/sellers-info/${sellerId}`;

    // Create a textarea element to hold the text
    const textarea = document.createElement("textarea");
    textarea.value = textToCopy;

    // Append the textarea to the body
    document.body.appendChild(textarea);

    // Select the text in the textarea
    textarea.select();

    // Execute the copy command
    document.execCommand("copy");

    // Remove the textarea from the body
    document.body.removeChild(textarea);

    // Set copied state to true
    setCopy(true);

    // Reset copied state after a short delay
    setTimeout(() => {
      setCopy(false);
    }, 1500);
  };
  return {
    copy,
    handleCopyStoreLink,
  };
};
