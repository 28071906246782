import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../../../components/baseUrl";

import toast from "react-hot-toast";

const ChangePasswordModal = () => {
  const { t } = useTranslation();
  const [inputType, setInputType] = useState("password");
  const [inputTypee, setInputTypee] = useState("password");
  const [passLoader, setPassLoader] = useState(false);
  const [editPassword, setEditPassword] = useState({
    currentPassword: "",
    newPassword: "",
  });

  const navigate = useNavigate();

  const handlePasswordTogglee = (e) => {
    inputTypee === "password"
      ? setInputTypee("text")
      : setInputTypee("password");
  };

  const handlePasswordToggle = (e) => {
    inputType === "password" ? setInputType("text") : setInputType("password");
  };

  const handlePasswordChange = (e) => {
    setEditPassword({ ...editPassword, [e.target.name]: e.target.value });
  };
  const handleUserPasswordChange = async (e) => {
    e.preventDefault();

    let toastId = "";
    toastId = toast.loading("loading...", {
      style: {
        background: "#353434",
        color: "#fff",
      },
    });

    setPassLoader(true);
    try {
      const editUserPassword = {
        oldPassword: editPassword.currentPassword,
        newPassword: editPassword.newPassword,
      };
      await axiosInstance.patch("/auth/update-password", editUserPassword);
      setPassLoader(false);
      toast.success("Your password has been successfully changed.", {
        id: toastId,
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
    } catch (error) {
      console.log(error);
      setPassLoader(false);
      if (!error.response.data.errors) {
        return navigate(`/no-connection`);
      }
      toast.error(`${error.response.data.errors[0].message}`, {
        id: toastId,
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
    }
  };
  return (
    <div>
      {" "}
      {/* modal for password*/}
      <div
        className="modal fade"
        id="exampleModal1"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog ">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body mx-2">
              <form>
                <div className="form-group password">
                  <label>{t("Current_password")}</label>
                  <div className="form-control passwordToggle">
                    <input
                      type={inputTypee}
                      id="exampleInputPassword1"
                      name="currentPassword"
                      value={editPassword.currentPassword}
                      onChange={handlePasswordChange}
                    />
                    <span
                      className={"password-icon"}
                      onClick={handlePasswordTogglee}
                    >
                      {inputTypee === "password" ? (
                        <i className="far fa-eye-slash mt-1 pt-1"></i>
                      ) : (
                        <i className="far fa-eye mt-1 pt-1"></i>
                      )}
                    </span>
                  </div>
                </div>
                <div className="form-group password">
                  <label>{t("New_password")}</label>
                  <div className="form-control passwordToggle">
                    <input
                      type={inputType}
                      id="exampleInputPassword"
                      name="newPassword"
                      value={editPassword.newPassword}
                      onChange={handlePasswordChange}
                    />
                    <span
                      className={"password-icon"}
                      onClick={handlePasswordToggle}
                    >
                      {inputType === "password" ? (
                        <i className="far fa-eye-slash mt-1 pt-1"></i>
                      ) : (
                        <i className="far fa-eye mt-1 pt-1"></i>
                      )}
                    </span>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer mx-2 mb-5">
              {!passLoader ? (
                <button
                  type="submit"
                  className="btn btn-primary changepassword"
                  onClick={handleUserPasswordChange}
                >
                  {t("Save_changes")}
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-primary changepassword"
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* end Modal */}
    </div>
  );
};

export default ChangePasswordModal;
