import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import "./NewHeader.css";

import cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { CgMenuRight } from "react-icons/cg";
import { MdOutlineArrowDropDown } from "react-icons/md";

import { languages } from "../../constants";
import { useSignOut } from "../hooks/useSignOut";
import { useEventTag } from "../hooks/useEventTag";
import { GlobalContext } from "../utils/GlobalState";
import { AliexpressCartContext } from "../utils/AliexpressCartState";
import { useGetAllCategories } from "../hooks/useGetAllCategories";

import SiteLogo from "../../assets/img/logo.png";
import UpperHeader from "../UpperHeader/UpperHeader";
import HeaderSearch from "../HeaderSearch/HeaderSearch";
import CategoryDropdown from "../CategoryDropdown/CategoryDropdown";
import useFixedNavbar from "../hooks/useFixedNavbar";

const NewHeader = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const dropdownRef = useRef(null);

  const navigate = useNavigate();

  const { handleSignOut } = useSignOut();
  const { handleEventTag } = useEventTag();
  const { cart } = useContext(AliexpressCartContext);
  const { user, totalCartQuantity } = useContext(GlobalContext);
  const { allCategories } = useGetAllCategories();
  const [clickSearch, setClickSearch] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [currentCurrencyCode, setCurrentCurrencyCode] = useState(
    cookies.get("currency") || "NGN"
  );

  const getTotalQuantity = () => {
    return cart.reduce((total, item) => total + item.quantity, 0);
  };

  const setCurrencyCode = (currency) => {
    cookies.set("currency", currency);
    setCurrentCurrencyCode(currency);
  };

  const handleCurrencyChange = (currency) => {
    const newCurrency = currency;
    setCurrencyCode(newCurrency);
    window.location.reload();
  };

  const handleMobileSearch = (e) => {
    setClickSearch(true);
  };

  const handleSearch = async (e) => {
    e.preventDefault();

    if (!searchInputValue) return;

    navigate(`/catalog/?q=${searchInputValue}`);
  };

  const isFixed = useFixedNavbar(
    typeof window !== "undefined" ? window.innerHeight * 0.1 : 0
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <>
      {!clickSearch ? (
        <div className={`new__header__ctn ${isFixed ? "fixedPosition" : ""}`}>
          <UpperHeader
            handleEventTag={handleEventTag}
            user={user}
            totalCartQuantity={totalCartQuantity}
            getTotalQuantity={getTotalQuantity}
          />
          <div className="new__header__lower">
            <div className="container new__header__lower__wrapper">
              <div className="new__header__logo__ctn">
                <Link to="/" className="new__header__logo__wrapper">
                  <img src={SiteLogo} alt="tofa logo" />
                </Link>
                <div className="category__meun__btn" ref={dropdownRef}>
                  <button onClick={() => setIsOpen(!isOpen)}>
                    <CgMenuRight />
                  </button>

                  {isOpen && allCategories && (
                    <CategoryDropdown allCategories={allCategories} />
                  )}
                </div>
              </div>
              <HeaderSearch
                handleSearch={handleSearch}
                searchInputValue={searchInputValue}
                setSearchInputValue={setSearchInputValue}
              />

              <div className="header___dropstart__ctn">
                <div className=" mobile-search">
                  <a href="#search" onClick={handleMobileSearch}>
                    <i className="fas fa-search nav-icon"></i>
                    <span>{t("Search")}</span>
                  </a>
                </div>
                <Link
                  to="/checkout"
                  className="cart__icon__wrapper mobile__cart__wrapper"
                >
                  {" "}
                  <p>{t("Cart")} </p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                    />
                  </svg>
                  {totalCartQuantity > 0 || getTotalQuantity() > 0 ? (
                    <span>{totalCartQuantity + getTotalQuantity()}</span>
                  ) : null}
                </Link>
                <div className="dropstart">
                  <Link
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    className="header___dropstart"
                  >
                    <i className="far fa-user nav-icon"></i>
                    <p>{t("Account")}</p>
                    <span>
                      <MdOutlineArrowDropDown />
                    </span>
                  </Link>
                  {user ? (
                    <ul
                      className="dropdown-menu dropstart__top animate slideIn"
                      aria-labelledby="navbarDropdown"
                    >
                      <li>
                        <Link
                          className="dropdown-item"
                          to={
                            user.role === "BUYER"
                              ? "/buyer/dashboard"
                              : "/seller/dashboard"
                          }
                        >
                          <i className="fas fa-envelope nav-icon"></i>
                          {t("My_Dashboard")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to={
                            user.role === "BUYER"
                              ? "/buyer/orders"
                              : "/seller/orders"
                          }
                        >
                          <i className="fas fa-shopping-cart nav-icon"></i>
                          {t("My_orders")}
                        </Link>
                      </li>

                      <li>
                        <Link
                          className="dropdown-item"
                          to={
                            user.role === "BUYER"
                              ? "/buyer/rfqs"
                              : "/seller/rfqs"
                          }
                        >
                          <i className="fas fa-sticky-note nav-icon"></i>{" "}
                          {t("Inquiries")}
                        </Link>
                      </li>
                      <li onClick={handleSignOut}>
                        <Link className="dropdown-item" to="/">
                          <i className="fas fa-sign-out-alt nav-icon"></i>
                          {t("Logout")}
                        </Link>
                      </li>
                    </ul>
                  ) : (
                    <ul
                      className="dropdown-menu custom-top animate slideIn"
                      aria-labelledby="navbarDropdown"
                    >
                      <li>
                        <Link className="dropdown-item" to="/login">
                          <button className="btn btn-danger navbar-btn">
                            {t("Login")}
                          </button>
                        </Link>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/registration">
                          <i className="fas fa-sign-in-alt nav-icon"></i>
                          {t("Sign_up")}
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>
                <div className="dropstart currency__header">
                  <Link
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    className="header___dropstart"
                  >
                    <p> {t("Currency")}</p>
                    <span>
                      <MdOutlineArrowDropDown />
                    </span>
                  </Link>

                  <ul
                    className="dropdown-menu dropstart__top animate slideIn"
                    aria-labelledby="navbarDropdown"
                  >
                    {languages.map(({ code, name, country_code }) => (
                      <li key={country_code} className="my-0">
                        <button
                          className="dropdown-item "
                          onClick={() => handleCurrencyChange(code)}
                          disabled={code === currentCurrencyCode}
                        >
                          {name}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <form id="search" onSubmit={handleSearch}>
          <div className="custom__search">
            <input
              type="text"
              className="form-control custom-style"
              id=""
              placeholder={t("Search_for_Products")}
              value={searchInputValue}
              onChange={(e) => setSearchInputValue(e.target.value)}
            />
            <button style={{ backgroundColor: "transparent", border: "none" }}>
              <i className="fas fa-search nav-icon"></i>
            </button>
          </div>
        </form>
      )}
    </>
  );
};

export default NewHeader;
