import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../baseUrl";
import cookies from "js-cookie";

export const useGetAliexpressProductInfo = () => {
  const [productInfo, setProductInfo] = useState({});
  const [productImages, setProductImages] = useState([]);
  const [currentItem, setCurrentItem] = useState({});
  const [loading, setLoading] = useState(true);
  const selectedCurrency = cookies.get("currency") || "NGN";

  const navigate = useNavigate();

  const getInfo = async (productId) => {
    try {
      setLoading(true);
      const bodyData = {
        target_currency: selectedCurrency === "NGN" ? "NGN" : "USD",
        target_language: "en",
        ship_to_country: "NG",
      };

      const { data } = await axiosInstance.post(
        `/ali-express/product/${productId}`,
        bodyData
      );
      setProductInfo(data?.aliexpress_ds_product_get_response?.result);
      setCurrentItem(
        data?.aliexpress_ds_product_get_response?.result?.ae_item_sku_info_dtos
          ?.ae_item_sku_info_d_t_o[0]
      );
      const otherImages =
        data?.aliexpress_ds_product_get_response?.result?.ae_multimedia_info_dto?.image_urls?.split(
          ";"
        );
      setProductImages(otherImages);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.message && error.message === "Network Error") {
        navigate("/no-connection");
      }
    }
  };
  return {
    getInfo,
    loading,
    productInfo,
    productImages,
    currentItem,
    setCurrentItem,
  };
};
