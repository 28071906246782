import { Modal } from "antd";
import { useTranslation } from "react-i18next";

import { Toaster } from "react-hot-toast";
import { usePostReview } from "../../../../components/hooks/usePostReview";
import { useEffect } from "react";

const ReviewModal = ({ isModalOpen, handleCancel, userId, productId }) => {
  const { t } = useTranslation();
  const {
    handleUploadReview,
    handleChange,
    isLoading,
    handleFileChange,
    createReview,
    success,
  } = usePostReview();

  useEffect(() => {
    if (success) {
      handleCancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  return (
    <Modal
      open={isModalOpen}
      onCancel={handleCancel}
      title="Awaiting your brief review. Thanks!"
    >
      <Toaster />
      <form className="enter-review-ctn">
        <div className="">
          <label htmlFor="review-rating">{t("Select_reveiw_rating")}</label>
          <select
            name="reviewRating"
            id="review-rating"
            value={createReview.reviewRating}
            onChange={handleChange}
          >
            <option>{t("Select_rating")}</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
        </div>
        <div className="">
          <label htmlFor="comment">{t("Drop_comment")}</label>
          <input
            type="text"
            name="comment"
            id="comment"
            value={createReview.comment}
            onChange={handleChange}
          />
        </div>
        <div className="review-file">
          <label htmlFor="file">{t("Drop_a_video_or_picture_(optional)")}</label>
          <input
            type="file"
            name="file"
            id="file"
            onChange={handleFileChange}
          />
        </div>
        {isLoading ? (
          <button>
            {" "}
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </button>
        ) : (
          <button
            type="button"
            onClick={(e) => handleUploadReview(e, userId, productId)}
          >
            {t("Submit")}
          </button>
        )}
      </form>
    </Modal>
  );
};

export default ReviewModal;
