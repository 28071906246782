import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import toast from "react-hot-toast";

import { axiosInstance } from "../baseUrl";
import { GlobalContext } from "../utils/GlobalState";
import { toastOptions } from "../helpers/functionHelpers";

export const useCreateTransactPay = () => {
  const { user } = useContext(GlobalContext);

  const navigate = useNavigate();

  const [loadTransact, setLoadTransact] = useState(false);

  const handleCreateTransactPay = async (
    grandTotal,
    currentSelectedCurrency,
    country,
    transactionId
  ) => {
    setLoadTransact(true);
    try {
      const transactPayPayload = {
        customer: {
          firstname: user?.firstName,
          lastname: user?.LastName,
          mobile: user?.phoneNumber,
          country: country,
          email: user?.email,
        },
        order: {
          amount: grandTotal(),
          reference: transactionId,
          description: "Payment for products",
          currency: currentSelectedCurrency,
        },
        payment: {
          RedirectUrl: "https://marketplase.tradersofafrica.com/payment-status",
        },
      };
      await axiosInstance.post(`/transact-pay/create`, transactPayPayload);
      navigate(
        `/transact-pay/${currentSelectedCurrency}/${encodeURIComponent(
          grandTotal()
        )}/${country}/${transactionId}`
      );
    } catch (error) {
      console.log("error creating transact pay transaction", error);
      toast.error("Payment failed! Contact support", toastOptions);
      setLoadTransact(false);
    } finally {
      setLoadTransact(false);
    }
  };
  return {
    handleCreateTransactPay,
    loadTransact,
  };
};
