import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../baseUrl";

export const useEventTag = () => {
  const navigate = useNavigate();

  const handleEventTag = async (tag, userId, productId) => {
    try {
      if (!userId && !productId) {
        const eventTag = {
          tag: tag,
        };
        await axiosInstance.post(`/event-tagging`, eventTag);
      } else if (!userId && productId) {
        const eventTag = {
          tag: tag,
          productId,
        };
        await axiosInstance.post(`/event-tagging`, eventTag);
      } else if (userId && !productId) {
        const eventTag = {
          tag: tag,
          userId: userId,
        };
        await axiosInstance.post(`/event-tagging`, eventTag);
      } else {
        const eventTag = {
          tag: tag,
          userId: userId,
          productId,
        };
        await axiosInstance.post(`/event-tagging`, eventTag);
      }
    } catch (error) {
      if (!error.response.data.errors) {
        return navigate(`/no-connection`);
      }
    }
  };

  return {
    handleEventTag,
  };
};
