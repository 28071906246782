import { useContext, useState } from "react";
import { axiosInstance } from "../baseUrl";
import toast from "react-hot-toast";
import { GlobalContext } from "../utils/GlobalState";

export const useUpdateProductQuantityInCart = () => {
  const { setRerender } = useContext(GlobalContext);
  const [qtyLoader, setQtyLoader] = useState(false);
  let toastId = "";

  const updateQuantity = async (cartId, quantity) => {
    setQtyLoader(true);
    toastId = toast.loading("loading...", {
      style: {
        background: "#353434",
        color: "#fff",
      },
    });
    try {
      const updateQty = {
        quantity,
      };
      await axiosInstance.patch(`/cart/change-quantity/${cartId}`, updateQty);
      setQtyLoader(false);
      toast.success("You have successfully updated product quantity", {
        id: toastId,
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
      setRerender((prev) => !prev);
    } catch (error) {
      console.log(error);
      setQtyLoader(false);
      toast.error(`${error.response.data.errors[0].message}`, {
        id: toastId,
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
    }
  };

  return {
    updateQuantity,
    qtyLoader,
  };
};
