import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import "./PaymentPage.css";

import { Toaster } from "react-hot-toast";

import Logo from "../../assets/img/logo.png";
import TransactPay from "../../assets/img/transactpay.jpeg";

import { numberWithCommas } from "../../components/helpers/functionHelpers";
import { useGetTransactPayBanks } from "../../components/hooks/useGetTransactPayBanks";
import { usePayWithCardTransactPay } from "../../components/hooks/usePayWithCardTransactPay";
import { usePayWithTransferTransactPay } from "../../components/hooks/usePayWithTransferTransactPay";
import { useGetPaymentFee } from "../../components/hooks/useGetPaymentFee";

const TransactPayPaymentPage = () => {
  const { currency, amount, country, transactionId } = useParams();
  const [paymentMethod, setPaymentMethod] = useState("C");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [saveCard, setSaveCard] = useState(false);

  const { paymentFee, totalCost, isLoad, getPaymentFee } = useGetPaymentFee();

  const {
    trnasferLoad,
    handlePayWithTransfer,
    setSelectedBankCode,
    selectedBankCode,
    accountNumber,
  } = usePayWithTransferTransactPay();
  const {
    cardLoad,
    handlePayWithCard,
    setCardDetails,
    cardDetails,
    handleCardInputChange,
  } = usePayWithCardTransactPay();
  const {
    isLoading,
    transactPayBanks,
    getTransactPayBanks,
    filteredBanks,
    searchTerm,
    setSearchTerm,
  } = useGetTransactPayBanks();

  const handlePaymentMethodChange = (method) => {
    setPaymentMethod(method);
  };

  useEffect(() => {
    getPaymentFee(currency, amount, paymentMethod);
  }, [paymentMethod, currency, amount]);

  useEffect(() => {
    if (paymentMethod === "bank-transfer") {
      getTransactPayBanks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethod, country]);

  return (
    <div className="payment-page">
      <Toaster />
      {isLoad ? (
        <div className="container pay__loader">
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        </div>
      ) : (
        <div className="container">
          <div className="payment-page-wrapper">
            <div className="logos">
              <img src={Logo} alt="Company Logo" className="company-logo" />
            </div>
            <div className="product-cost">
              <p>
                Cost of product: {currency && currency}{" "}
                {amount && numberWithCommas(amount)}
              </p>
              <p>
                Transaction fee: {currency && currency}{" "}
                {paymentFee ? numberWithCommas(paymentFee) : 0}
              </p>
              <p>
                Total cost: {currency && currency}{" "}
                {totalCost ? numberWithCommas(totalCost) : amount}
              </p>
            </div>

            {accountNumber ? (
              <div className="account__number__details">
                <div className="account__number__wrapper">
                  <div className="account__content">
                    <p>BANK NAME</p>
                    <p className="__cont">Wema Bank</p>
                  </div>
                  <div className="account__content">
                    <p>ACCOUNT NUMBER</p>
                    <p className="__cont">{accountNumber}</p>
                  </div>
                  <div className="account__content">
                    <p>ACCOUNT NAME</p>
                    <p className="__cont">TPAY/Traders of Africa (TOFA)</p>
                  </div>
                  <div className="account__content">
                    <p>AMOUNT</p>
                    <p className="__cont">
                      {currency && currency}{" "}
                      {totalCost ? numberWithCommas(totalCost) : amount}
                    </p>
                  </div>

                  <p className="__note">
                    This account is for this transaction only!
                  </p>
                </div>
              </div>
            ) : (
              <div className="payment-page-container">
                <div className="payment-left">
                  {paymentMethod === "C" && (
                    <form
                      className="card-details"
                      onSubmit={(e) =>
                        handlePayWithCard(e, country, transactionId)
                      }
                    >
                      <p className="py-4">Enter Your card details</p>
                      <input
                        type="text"
                        name="cardNumber"
                        placeholder="Card Number"
                        value={cardDetails.cardNumber}
                        onChange={handleCardInputChange}
                      />

                      <input
                        type="text"
                        name="expiryDate"
                        placeholder="Expiry Date (MM/YY)"
                        maxLength="5"
                        value={cardDetails.expiryDate}
                        onChange={(e) => {
                          let value = e.target.value.replace(/\D/g, "");
                          if (value.length > 2) {
                            value = value.slice(0, 2) + "/" + value.slice(2, 4);
                          }
                          setCardDetails({ ...cardDetails, expiryDate: value });
                        }}
                      />
                      <input
                        type="text"
                        name="cvv"
                        placeholder="CVV"
                        maxLength="3"
                        value={cardDetails.cvv}
                        onChange={handleCardInputChange}
                      />
                      <div className="save-card">
                        <input
                          type="checkbox"
                          checked={saveCard}
                          onChange={() => setSaveCard(!saveCard)}
                        />
                        <label>Save card for future payments</label>
                      </div>
                      {cardLoad ? (
                        <button
                          type="button"
                          className="continue-button"
                          disabled
                        >
                          {" "}
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </button>
                      ) : (
                        <button type="submit" className="continue-button">
                          Continue with Payment
                        </button>
                      )}
                    </form>
                  )}

                  {paymentMethod === "bank-transfer" && (
                    <div className="bank-transfer">
                      <p className="py-4">Choose a bank to make payment to</p>
                      <div className="bank-transfer-dropdown">
                        {/* Dropdown Header */}
                        <div
                          className="dropdown-header"
                          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                        >
                          {selectedBankCode
                            ? transactPayBanks?.find(
                                (bank) => bank?.bankCode === selectedBankCode
                              )?.name || "Select Bank"
                            : "Select Bank"}
                        </div>

                        {isDropdownOpen && (
                          <>
                            {isLoading ? (
                              <p>Loading banks...</p>
                            ) : (
                              <div className="dropdown-content">
                                {/* <input
                              type="text"
                              placeholder="Search for a bank..."
                              value={searchTerm}
                              onChange={(e) => setSearchTerm(e.target.value)}
                              className="search-input"
                            /> */}
                                <ul className="bank-list">
                                  <li
                                    onClick={() => {
                                      setSelectedBankCode("000017");
                                      setIsDropdownOpen(false);
                                    }}
                                    className="bank-item"
                                  >
                                    <span>WEMA BANK</span>
                                  </li>
                                  {/* {filteredBanks?.map((bank) => (
                                <li
                                  key={bank?.id}
                                  onClick={() => {
                                    setSelectedBankCode(bank?.bankCode);
                                    setIsDropdownOpen(false);
                                  }}
                                  className="bank-item"
                                >
                                  <span>{bank?.name}</span>
                                </li>
                              ))} */}
                                </ul>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                      {trnasferLoad ? (
                        <button
                          type="button"
                          className="continue-button"
                          disabled
                        >
                          {" "}
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </button>
                      ) : (
                        <button
                          className="proceed-button"
                          onClick={() =>
                            handlePayWithTransfer(country, transactionId)
                          }
                        >
                          Proceed
                        </button>
                      )}
                    </div>
                  )}
                </div>

                <div className="payment-right">
                  <div className="payment-method">
                    <button
                      className={`method-button ${
                        paymentMethod === "C" ? "active" : ""
                      }`}
                      onClick={() => handlePaymentMethodChange("C")}
                    >
                      Card
                    </button>
                    <button
                      className={`method-button ${
                        paymentMethod === "bank-transfer" ? "active" : ""
                      }`}
                      onClick={() => handlePaymentMethodChange("bank-transfer")}
                    >
                      Bank Transfer
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="poweredBy">
            <p>Powered by </p>{" "}
            <img
              src={TransactPay}
              alt="Payment Service Logo"
              className="payment-service-logo"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default TransactPayPaymentPage;
