import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../../../components/utils/GlobalState";

import SellersSidebar from "../SellersComponent/SellerSideBar";
import { ProtectedRoutes } from "../../../../components/utils/ProtectedRoutes";

import { Toaster } from "react-hot-toast";

import SellerBankDetails from "./SellerBankDetails";
import SellersProfile from "./SellersProfile";
import SellersHeader from "../SellersComponent/SellersHeader";
import { useNavigate } from "react-router-dom";

const SellersSettings = () => {
  const { t } = useTranslation();
  const { user, userLoading } = useContext(GlobalContext);
  const [isActive, setIsActive] = useState(false);
  const [activated, setActivated] = useState(false);

  const handleClick = (event) => {
    setIsActive((current) => !current);
  };

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  return (
    <>
      <Toaster />
      <div>
        <div className="grid-container">
          <SellersHeader
            handleClick={handleClick}
            user={user}
            title="Settings"
          />

          <SellersSidebar isActive={isActive} handleClick={handleClick} />

          <main className="main">
            <div className="main-overview">
              <div className="overview-card">
                <div className="seller-profile-card">
                  <div className="seller-profile-pic"></div>
                  <div className="seller-setting-profile">
                    <div className="seller-setting-header-ctn">
                      <h4
                        className={!activated ? "active" : ""}
                        onClick={() => setActivated(false)}
                      >
                        {t("My_Profile")}
                      </h4>
                      <h4
                        className={activated ? "active" : ""}
                        onClick={() => setActivated(true)}
                      >
                        {t("Bank_details")}
                      </h4>
                    </div>

                    <p>{t("Update_your_profile")}</p>
                  </div>
                </div>

                <div
                  className={activated ? "deactivated" : "seller-setting-form"}
                >
                  {userLoading ? (
                    <div className="lds-ctn">
                      <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  ) : (
                    <SellersProfile user={user} />
                  )}
                </div>
                <div
                  className={activated ? "seller-setting-form" : "deactivated"}
                >
                  <SellerBankDetails />
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default ProtectedRoutes(SellersSettings, ["SELLER"]);
