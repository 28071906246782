import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../../../components/baseUrl";
import { useTranslation } from "react-i18next";

import toast, { Toaster } from "react-hot-toast";

const MessageBuyer = ({ rfqInfo, Capitalize, loading, convertDateFormat }) => {
  const { t } = useTranslation();
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState({
    msg: " ",
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    e.preventDefault();
    setMessage({ ...message, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    setLoader(true);
    try {
      const createMessage = {
        to: rfqInfo.user.id,
        message: message.msg,
      };
      await axiosInstance.post("/message/send-message", createMessage);

      toast.success("Message sent", {
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
      setLoader(false);
      setMessage({
        msg: " ",
      });
      setTimeout(() => {
        window.location.reload();
      }, 6300);
    } catch (err) {
      console.log(err);
      setLoader(false);
      if (!err.response.data.errors) {
        return navigate(`/no-connection`);
      }
      toast.error(`${err.response.data.errors[0].message}`, {
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
    }
  };

  return (
    <div>
      <Toaster />
      <div
        className="modal fade"
        id="exampleModalToggle2"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel2"
        tabIndex="-1"
      >
        {loading ? (
          <div className="gooeey">
            <span className="dot"></span>
            <div className="dots">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        ) : (
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content px-2">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalToggleLabel">
                  {t("Message_Supplier")}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="buyer-info-details">
                      <div className="buyer-info">
                        <span>{t("Date_created")}:</span>
                        <p>{rfqInfo && convertDateFormat(rfqInfo.createdAt)}</p>
                      </div>
                      <div className="buyer-info">
                        <span>
                          <i className="fas fa-envelope-open-text"></i>
                        </span>
                        <p>{rfqInfo && rfqInfo.user.email}</p>
                      </div>
                      <div className="buyer-info">
                        <span>
                          <i className="fas fa-phone-alt"></i>
                        </span>
                        <p>{rfqInfo && rfqInfo.user.phoneNumber}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 px-4">
                    {" "}
                    <form>
                      <div className="mb-3">
                        <p>
                          <span> {t("Recipient_Name")}: </span>
                          {rfqInfo.user &&
                            Capitalize(rfqInfo.user.firstName)}{" "}
                          {rfqInfo.user && Capitalize(rfqInfo.user.LastName)}{" "}
                        </p>
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="message-text"
                          className="col-form-label"
                        >
                          {t("Message")}:
                        </label>
                        <textarea
                          className="form-control"
                          id="message-text"
                          rows="6"
                          name="msg"
                          value={message.msg}
                          onChange={handleChange}
                          required
                        ></textarea>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                {!loader ? (
                  <button
                    type="button"
                    className=" msg-btn "
                    onClick={handleSubmit}
                  >
                    {t("Send_message")}
                  </button>
                ) : (
                  <button type="button" className="msg-btn">
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MessageBuyer;
