import { axiosInstance } from "../baseUrl";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useContext, useState } from "react";
import toast from "react-hot-toast";
import { GlobalContext } from "../utils/GlobalState";

export const useRemoveProductFromCart = () => {
  const { setRerender } = useContext(GlobalContext);
  const [deleteLoader, setDeleteLoader] = useState(false);
  let toastId = "";

  const removeFromCart = (productID) => {
    confirmAlert({
      title: "Remove product",
      message: "Are you sure you want to remove this product from your cart?",
      buttons: [
        {
          label: "Yes",
          onClick: (e) => handleDelete(productID),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleDelete = async (productId) => {
    toastId = toast.loading("loading...", {
      style: {
        background: "#353434",
        color: "#fff",
      },
    });
    setDeleteLoader(true);
    try {
      await axiosInstance.delete(`/cart/delete-item/${productId}`);
      setDeleteLoader(false);
      toast.success("You have successfully deleted product from your cart.", {
        id: toastId,
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
      setRerender((prev) => !prev);
    } catch (error) {
      console.log(error);
      setDeleteLoader(false);
      toast.error(`${error.response.data.errors[0].message}`, {
        id: toastId,
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
    }
  };
  return {
    removeFromCart,
    deleteLoader,
  };
};
