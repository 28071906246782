import React from "react";
import { useTranslation } from "react-i18next";
import logo from "./../../../../assets/img/logo.png";
import { Link } from "react-router-dom";

const ErrorConfirm = () => {
  const { t } = useTranslation();
  return (
    <div className="bank-ctn">
      <div className="p-4 logo">
        <Link to="/">
          <img src={logo} alt="TOFA" />
        </Link>
      </div>
      <div className="satisfaction-container">
        <h1>{t("Order_Delivered!")}</h1>
        <p> {t("You've_previously")}</p>
        <p>{t("Thank_you.")}</p>
      </div>
    </div>
  );
};

export default ErrorConfirm;
