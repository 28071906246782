import { useState } from "react";

export const useGetReferralLink = () => {
  const [copied, setCopied] = useState(false);

  const handleCopyClick = (referralCode) => {
    const textToCopy = `https://marketplase.tradersofafrica.com/registration/${referralCode}`;

    // Create a textarea element to hold the text
    const textarea = document.createElement("textarea");
    textarea.value = textToCopy;

    // Append the textarea to the body
    document.body.appendChild(textarea);

    // Select the text in the textarea
    textarea.select();

    // Execute the copy command
    document.execCommand("copy");

    // Remove the textarea from the body
    document.body.removeChild(textarea);

    // Set copied state to true
    setCopied(true);

    // Reset copied state after a short delay
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  };

  return {
    handleCopyClick,
    copied,
  };
};
