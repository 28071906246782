import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../baseUrl";
import { useQuery } from "react-query";

export const useGetAllProduct = () => {
  const navigate = useNavigate();

  const onProductErr = (error) => {
    if (error) {
      navigate("/no-connection");
    }
  };

  const getAllProducts = () => {
    return axiosInstance
      .get(`/product?productStatus=APPROVED`)
      .then((res) => res?.data?.data.products);
  };

  const { data: allProducts, isLoading: loading } = useQuery(
    "all-product",
    getAllProducts,
    {
      onError: onProductErr,
      staleTime: 24 * 60 * 60 * 1000,
      cacheTime: 24 * 60 * 60 * 1000,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
  return {
    loading,
    allProducts,
  };
};
