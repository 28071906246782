export function convertToDollars(amount, currency) {
  const exchangeRates = {
    AUD: 1.566015,
    CAD: 1.560132,
    EUR: parseFloat(process.env.REACT_APP_CURRENCY_EUR),
    CHF: 1.154727,
    CNY: 7.827874,
    GBP: parseFloat(process.env.REACT_APP_CURRENCY_GBP),
    GHS: parseFloat(process.env.REACT_APP_CURRENCY_GHS),
    JPY: 132.360679,
    USD: parseFloat(process.env.REACT_APP_CURRENCY_USD),
    NGN: parseFloat(process.env.REACT_APP_CURRENCY_NGN),
    XOF: parseFloat(process.env.REACT_APP_CURRENCY_XOF),
  };

  // Check if the specified currency is supported
  if (exchangeRates.hasOwnProperty(currency)) {
    const exchangeRate = exchangeRates[currency];
    const dollars = amount / exchangeRate;
    return dollars?.toFixed(5); // Round to 2 decimal places
  } else {
    return null;
  }
}
