import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import BuyersSidebar from "./BuyersComponent/BuyerSideBar";
import "../Dashboard.css";
import { ProtectedRoutes } from "../../../components/utils/ProtectedRoutes";
import { GlobalContext } from "../../../components/utils/GlobalState";

import Pagination from "react-responsive-pagination";
import { useGetBuyerOrders } from "../../../components/hooks/useGetBuyerOrders";
import CardOverviewLoader from "../../../components/SkeletonLoader/CardOverviewLoader";
import TableSkeleton from "../../../components/SkeletonLoader/TableSkeleton";
import BuyersHeader from "./BuyersComponent/BuyersHeader";
import {
  Capitalize,
  numberWithCommas,
} from "../../../components/helpers/functionHelpers";
import cookies from "js-cookie";
import { convertDateFormat } from "../../../components/helpers/convertDate";
import { useGetReferralLink } from "../../../components/hooks/useGetReferralLink";
import {
  WhatsappShareButton,
  WhatsappIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import { CurrencyExchangeContext } from "../../../components/utils/CurrencyExchangeState";

const BuyersDashboard = () => {
  const { t } = useTranslation();
  const { user, userLoading } = useContext(GlobalContext);
  const { convertCurrency } = useContext(CurrencyExchangeContext);
  const [isActive, setIsActive] = useState(false);
  const textToCopy =
    "Shop, Sell, Thrive! 🛍️💼 Join TOFA Marketplace—where buyers find treasures and sellers unlock success. 🚀✨ Ready to thrive together? Sign up now:";
  const shareUrl = `https://marketplase.tradersofafrica.com/registration/${user?.referralCode}`;

  const selectedCurrency = cookies.get("currency") || "NGN";

  const navigate = useNavigate();

  const { handleCopyClick, copied } = useGetReferralLink();

  const {
    handlePageChange,
    summary,
    orderLoading,
    totalPages,
    loading,
    allUserOrder,
    currentPage,
    handleSubmit,
    search,
    handleChange,
  } = useGetBuyerOrders();

  const handleClick = (event) => {
    setIsActive((current) => !current);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  return (
    <div>
      <div className="grid-container">
        <BuyersHeader
          title="Account Overview"
          handleClick={handleClick}
          user={user}
          userLoading={userLoading}
          Capitalize={Capitalize}
        />
        <BuyersSidebar isActive={isActive} handleClick={handleClick} />

        <main className="main">
          <h1 className="section-title">{t("Activity_Summary")}</h1>
          {orderLoading ? (
            <CardOverviewLoader />
          ) : (
            <div className="row">
              <div className="col-lg-4 col-sm-6 col-12">
                <div className="overview-card">
                  <div>
                    <h2>{t("Total_Orders")}</h2>
                    {/* <p>Detailed transaction history is on the order page</p> */}
                    <div className="d-flex justify-content-between mt-4">
                      <h3>
                        {summary.total_number_of_orders &&
                          summary.total_number_of_orders}
                      </h3>
                      <Link className="overview-card-link" to="/buyer/orders">
                        {t("View_all")}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-12">
                <div className="overview-card">
                  <div>
                    <h2>{t("Total_RFQs")}</h2>
                    {/* <p>Detailed transaction history is on the order page</p> */}
                    <div className="d-flex justify-content-between mt-4">
                      <h3>
                        {summary.total_number_of_enquiries &&
                          summary.total_number_of_enquiries}
                      </h3>
                      <Link className="overview-card-link" to="/buyer/rfqs">
                        {t("View_all")}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-12">
                <div className="overview-card">
                  <div>
                    <h2>{t("Pending_Orders")}</h2>
                    {/* <p>Detailed transaction history is on the order page</p> */}
                    <div className="d-flex justify-content-between mt-4">
                      <h3>
                        {summary.total_pending_orders &&
                          summary.total_pending_orders}
                      </h3>
                      <Link className="overview-card-link" to="/buyer/rfqs">
                        {t("View_all")}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-12">
                <div className="overview-card">
                  <div>
                    <h2>{t("Total_Referral_Points")}</h2>
                    <div className="referral-ctn">
                      {copied ? (
                        <p className="referral-copy">{t("Link_copied!")}</p>
                      ) : (
                        <p
                          className="referral-link"
                          onClick={() => handleCopyClick(user?.referralCode)}
                        >
                          {t("Copy_referral_link")}{" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5A3.375 3.375 0 006.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0015 2.25h-1.5a2.251 2.251 0 00-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 00-9-9z"
                            />
                          </svg>
                        </p>
                      )}
                      <p
                        className="referral-link"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        {t("Share_referral_link")}{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z"
                          />
                        </svg>
                      </p>
                      <div>
                        {" "}
                        <div
                          className="modal fade"
                          id="exampleModal"
                          tabIndex="-1"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5
                                  className="modal-title"
                                  id="exampleModalLabel"
                                >
                                  {t("Share_referral_link")}
                                </h5>
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div className="modal-body px-3">
                                <div className="mx-3 share-referral">
                                  <EmailShareButton
                                    url={shareUrl}
                                    title={textToCopy}
                                  >
                                    <button> {t("Share_on_Email")}</button>
                                    <EmailIcon />
                                  </EmailShareButton>

                                  <FacebookShareButton
                                    url={shareUrl}
                                    title={textToCopy}
                                  >
                                    <button> {t("Share_on")}</button>
                                    <FacebookIcon />
                                  </FacebookShareButton>
                                  <TwitterShareButton
                                    url={shareUrl}
                                    title={textToCopy}
                                  >
                                    <button> {t("Share_on")} </button>
                                    <TwitterIcon />
                                  </TwitterShareButton>
                                  <WhatsappShareButton
                                    url={shareUrl}
                                    title={textToCopy}
                                  >
                                    <button>{t("Share_on")} </button>
                                    <WhatsappIcon />
                                  </WhatsappShareButton>
                                  <LinkedinShareButton
                                    url={shareUrl}
                                    title={textToCopy}
                                  >
                                    <button> {t("Share_on")} </button>
                                    <LinkedinIcon />
                                  </LinkedinShareButton>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between mt-4">
                      <h3>{user?.point && numberWithCommas(user.point)}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="section-title-ctn">
            <h1 className="section-title">{t("Recent_Orders")}</h1>
            <div className="header__search">
              <form onSubmit={handleSubmit}>
                <div className="custom__search">
                  <button type="submit">
                    {" "}
                    <i className="fas fa-search"></i>
                  </button>
                  <input
                    type="text"
                    className="form-control custom-style"
                    id=""
                    placeholder="Search orders, order status..."
                    value={search}
                    onChange={handleChange}
                  />
                </div>
              </form>
            </div>
          </div>
          {loading ? (
            <TableSkeleton />
          ) : (
            <>
              {allUserOrder?.length < 1 ? (
                <>
                  {search ? (
                    <div className="empty-state">
                      <p>{t("No_order_matched")}</p>
                    </div>
                  ) : (
                    <div className="empty-state">
                      <h3>{t("Welcome_to_your_Dashboard")}</h3>
                      <p>
                        {t("Access_history_to_all_your")}{" "}
                        <Link to="/orders">{t("Orders")}</Link> {t("and")}{" "}
                        <Link to="/buyer/rfqa">
                          {" "}
                          {t("Responses_to_your_Rfqs")}
                        </Link>{" "}
                        ,{t("chat_a")}{" "}
                        <Link to="buyer/message-center">
                          {t("Chat_with_sellers")}
                        </Link>
                        ,{" "}
                        <Link to="/buyer/settings">{t("edit_your_name")}</Link>
                      </p>
                    </div>
                  )}
                </>
              ) : (
                <div className="main-overview">
                  <div className="overview-card no-padding">
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">{t("Date")}</th>
                            <th scope="col">{t("Order_No")}</th>
                            <th scope="col">{t("Product")}</th>
                            <th scope="col">{t("Price")} </th>
                            <th scope="col">{t("Shipping_Terms")}</th>
                            <th scope="col">{t("Payment_Terms")}</th>
                            <th scope="col">{t("Status")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {allUserOrder &&
                            allUserOrder?.map((orders, index) => (
                              <tr key={index}>
                                <td>
                                  {orders.createdAt &&
                                    convertDateFormat(orders.createdAt)}
                                </td>
                                <td>{orders.orderNumber}</td>
                                <td>
                                  <p>
                                    <Link to={`/buyer/order-info/${orders.id}`}>
                                      {orders.productName
                                        ? Capitalize(orders.productName)
                                        : orders.product
                                        ? Capitalize(orders.product.productName)
                                        : " "}
                                    </Link>
                                  </p>
                                </td>
                                <td>
                                  {selectedCurrency}{" "}
                                  {orders?.cost &&
                                    numberWithCommas(
                                      convertCurrency(
                                        orders.cost,
                                        orders?.currencyType,
                                        selectedCurrency
                                      )
                                    )}
                                </td>
                                <td>
                                  {" "}
                                  {orders.incoterm &&
                                  orders.incoterm === "LOCAL_DELIVERY"
                                    ? "Local delivery"
                                    : orders.incoterm}
                                </td>
                                <td>
                                  {" "}
                                  {orders.paymentTerm && orders.paymentTerm}
                                </td>
                                <td>
                                  <div className="text-warning">
                                    {orders.status === "PENDING" && (
                                      <div className="text-warning ">
                                        {t("Pending")}
                                      </div>
                                    )}
                                    {orders.status === "PROCESSING" && (
                                      <div className="text-primary ">
                                        {t("Processing")}
                                      </div>
                                    )}
                                    {orders.status === "SHIPPED" && (
                                      <div className="text-info">
                                        {t("Shipped")}
                                      </div>
                                    )}
                                    {orders.status === "DELIVERED" && (
                                      <div className="text-success">
                                        {t("Delivered")}
                                      </div>
                                    )}
                                    {orders.status === "RECEIVED" && (
                                      <div className="text-secondary">
                                        {t("Received")}
                                      </div>
                                    )}
                                    {orders.status === "NOT RECEIVED" && (
                                      <div className="text-secondary">
                                        {t("Not_Received")}
                                      </div>
                                    )}
                                    {orders.status === "CANCELLED" && (
                                      <div className="text-danger">
                                        {t("Cancelled")}
                                      </div>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          <Pagination
            current={currentPage}
            total={totalPages}
            onPageChange={handlePageChange}
            extraClassName="justify-content-start"
          />
        </main>
      </div>
    </div>
  );
};

export default ProtectedRoutes(BuyersDashboard, ["BUYER", "SELLER"]);
