import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import SellersSidebar from "../SellersComponent/SellerSideBar";
import Ellipse from "../../../../assets/img/Ellipse.png";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import { ProtectedRoutes } from "../../../../components/utils/ProtectedRoutes";
import { axiosInstance } from "../../../../components/baseUrl";

import toast, { Toaster } from "react-hot-toast";

import MessageBuyer from "./MessageBuyer";
import { BigCard } from "../../../../components/SkeletonLoader/BigCard";
import { convertPrice } from "../../../../components/helpers/convertPrice";
import { convertToDollars } from "../../../../components/helpers/convertToDollars";
import cookies from "js-cookie";
import { Capitalize } from "../../../../components/helpers/functionHelpers";
import { convertDateFormat } from "../../../../components/helpers/convertDate";
import { convertPriceWithoutCommas } from "../../../../components/helpers/covertPriceWithoutCommas";

const SellersRfqsInfo = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [rfqInfo, setRfqInfo] = useState({});
  const [showquote, setShowQuote] = useState(false);
  const [loading, setLoading] = useState(true);
  const [priceResponse, setPriceResponse] = useState("");
  const [addNoteResponse, setAddNoteResponse] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [respondRfq, setRespondRfq] = useState({
    price: "",
    additionalNote: "",
  });
  const { rfqId } = useParams();
  const navigate = useNavigate();

  const selectedCurrency = cookies.get("currency") || "NGN";

  const handleCloseQuote = () => setShowQuote(false);
  const handleShowQuote = () => setShowQuote(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClick = (event) => {
    setIsActive((current) => !current);
  };

  const handleChange = (e) => {
    setRespondRfq({
      ...respondRfq,
      [e.target.name]: e.target.value,
    });
  };

  const getRfqInfo = async () => {
    try {
      axiosInstance.get(`/rfq/${rfqId}`).then((response) => {
        setRfqInfo(response.data.data);
        setLoading(false);
        if (response.data.data.supplierResponse) {
          const newPrice = convertPriceWithoutCommas(
            response.data.data.supplierResponse.price,
            selectedCurrency
          );
          setPriceResponse(newPrice);
          setAddNoteResponse(
            response.data.data.supplierResponse.additionalNote
          );
        }
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error.message && error.message === "Network Error") {
        navigate("/no-connection");
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getRfqInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rfqId, navigate]);

  const handleRfqSubmit = async (e) => {
    e.preventDefault();

    setLoader(true);
    try {
      const createRfqResponse = {
        price: convertToDollars(respondRfq.price, selectedCurrency),
        additionalNote: respondRfq.additionalNote,
        targetPrice: rfqInfo.targetPrice,
        unit: rfqInfo.unit,
        rfqId: rfqInfo.id,
        userId: rfqInfo.userId,
      };
      await axiosInstance.post("/rfq/response", createRfqResponse);
      setRespondRfq({
        price: "",
        additionalNote: "",
      });
      setLoader(false);
      toast.success("RFQ response sent!", {
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
      setShow(false);
    } catch (err) {
      console.log(err);
      setLoader(false);
      if (!err.response.data.errors) {
        return navigate(`/no-connection`);
      }
      toast.error(`${err.response.data.errors[0].message}`, {
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
    }
  };

  const handleRfqUpdate = async (e) => {
    e.preventDefault();

    setLoader(true);
    try {
      const updateRfqResponse = {
        price: convertToDollars(priceResponse, selectedCurrency),
        additionalNote: addNoteResponse,
        targetPrice: rfqInfo.targetPrice,
        unit: rfqInfo.unit,
      };

      await axiosInstance.put(
        `/rfq/response/${rfqInfo.supplierResponse.id}`,
        updateRfqResponse
      );
      setLoader(false);

      toast.success("RFQ response updated.", {
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });

      setShowQuote(false);
    } catch (err) {
      setLoader(false);
      console.log(err);
      if (!err.response.data.errors) {
        return navigate(`/no-connection`);
      }
      toast.error(`${err.response.data.errors[0].message}`, {
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
    }
  };

  return (
    <>
      <Toaster />
      <div className="grid-container">
        <div className={"menuu-icon"} onClick={handleClick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-6 h-6"
          >
            <path
              fillRule="evenodd"
              d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <header className="header">
          <div className="header__message"></div>
        </header>

        <SellersSidebar isActive={isActive} handleClick={handleClick} />

        {loading ? (
          <BigCard />
        ) : (
          <main className="main">
            <div className="chat-main">
              <div className="main-overview">
                <div className="overview-card-buyers-info">
                  <div className="buyer-info-container">
                    <div className="buyer-info-pic">
                      <img src={Ellipse} alt="" />
                      <h4>{t("Supplier's_Marketplace")}</h4>
                      <p>{t("General_RFQ_Listing")}</p>
                    </div>

                    <div className="buyer-info-details">
                      <h4>{t("Buyer's_Info")}</h4>
                      <div className="buyer-info">
                        <span>
                          <i className="fas fa-user"></i>
                        </span>
                        <p>
                          {rfqInfo.user && Capitalize(rfqInfo.user.firstName)}{" "}
                          {rfqInfo.user && Capitalize(rfqInfo.user.LastName)}{" "}
                        </p>
                      </div>
                      <div className="buyer-info">
                        <span>
                          <i className="fas fa-envelope-open-text"></i>
                        </span>
                        <p>{rfqInfo && rfqInfo.user.email}</p>
                      </div>
                      <div className="buyer-info">
                        <span>
                          <i className="fas fa-calendar-alt"></i>
                        </span>
                        <p className="mx-2">
                          {rfqInfo && convertDateFormat(rfqInfo.createdAt)}
                        </p>
                      </div>

                      <div className="buyer-info messagebuyer">
                        <span>
                          <i className="fas fa-envelope"></i>
                        </span>
                        <button
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModalToggle2"
                        >
                          {t("Message_Buyer")}
                        </button>
                        <MessageBuyer
                          rfqInfo={rfqInfo}
                          loading={loading}
                          Capitalize={Capitalize}
                          convertDateFormat={convertDateFormat}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="main-overview">
                <div className="overview-card">
                  <div className="product-info">
                    <p className="product-info-title">{t("Product_Name")}</p>
                    <p>
                      {rfqInfo.productName && Capitalize(rfqInfo.productName)}
                    </p>
                  </div>
                  <div className="product-info">
                    <p className="product-info-title">{t("Quantity")}</p>
                    <p>
                      {rfqInfo && rfqInfo.quantityRequired}{" "}
                      {rfqInfo && rfqInfo.unit}
                    </p>
                  </div>
                  <div className="product-info">
                    <p className="product-info-title">{t("Proposed_price")}</p>
                    <p>
                      {selectedCurrency}{" "}
                      {rfqInfo &&
                        convertPrice(rfqInfo.targetPrice, selectedCurrency)}
                    </p>
                  </div>
                  <div className="product-info">
                    <p className="product-info-title">{t("Incoterms")}</p>
                    <p>{rfqInfo && rfqInfo.termsOfTrade}</p>
                  </div>
                  <div className="product-info">
                    <p className="product-info-title">{t("Destination")}</p>
                    <p>{rfqInfo && rfqInfo.destinationPort}</p>
                  </div>
                  <div className="product-requirement">
                    <p className="product-info-title">
                      {t("Product_Requirement")}
                    </p>
                    <p>{rfqInfo && rfqInfo.productDescription}</p>
                  </div>
                  {rfqInfo.status !== "RESPONDED" && (
                    <Button
                      variant="primary"
                      className="respond-btn"
                      onClick={handleShow}
                    >
                      {t("Respond")}
                    </Button>
                  )}
                </div>
              </div>
            </div>
            {rfqInfo.status === "RESPONDED" && (
              <div className="product-quote">
                <h5>{t("My_Quote")}</h5>
                <div className="product-info">
                  <p className="product-info-title">{t("Price")}</p>
                  <p className="product-info-description">
                    {selectedCurrency}{" "}
                    {rfqInfo.supplierResponse &&
                      convertPrice(
                        rfqInfo.supplierResponse.price,
                        selectedCurrency
                      )}{" "}
                  </p>
                </div>
                <div className="product-info">
                  <p className="product-info-title">{t("Additional_Notes")}:</p>
                  <p className="product-info-description">
                    {rfqInfo.supplierResponse &&
                      rfqInfo.supplierResponse.additionalNote}
                  </p>
                </div>
              </div>
            )}
            {rfqInfo.status === "RESPONDED" && (
              <Button
                variant="primary"
                className="respond-btn quote"
                onClick={handleShowQuote}
              >
                {t("Edit_your_Quote")}
              </Button>
            )}
            <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              centered
              size="lg"
              keyboard={false}
            >
              <Modal.Body>
                <Modal.Header closeButton></Modal.Header>
                <div className="quote-modal">
                  <div className="rfq-details">
                    <p>{t("RFQ's_Detail")}</p>
                    <div className="product-info supplier-product-info">
                      <p className="product-info-title">{t("Product_Name")}</p>
                      <p className="product-info-description">
                        {rfqInfo.productName && Capitalize(rfqInfo.productName)}
                      </p>
                    </div>
                    <div className="product-info supplier-product-info">
                      <p className="product-info-title">{t("Incoterm")}</p>
                      <p className="product-info-description">
                        {rfqInfo && rfqInfo.termsOfTrade}
                      </p>
                    </div>
                    <div className="product-info supplier-product-info">
                      <p className="product-info-title">{t("Destination")}</p>
                      <p className="product-info-description">
                        {rfqInfo && rfqInfo.destinationPort}
                      </p>
                    </div>
                    <div className="product-requirement supplier-product-requirement">
                      <p>{t("Product_Requirement")}</p>
                      <p>{rfqInfo && rfqInfo.productDescription}</p>
                    </div>
                  </div>
                  <div className="supplier-response">
                    <Form onSubmit={handleRfqSubmit}>
                      <p>{t("Supplier's_Response")}</p>

                      <div className="form-group form-group supplier-form-group">
                        <label>
                          ({selectedCurrency}) {t("Price")}
                        </label>
                        <input
                          className="form-control"
                          type="number"
                          id="price"
                          name="price"
                          value={respondRfq.price}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group supplier-form-group">
                        <label>{t("Additional_Details")}</label>
                        <textarea
                          rows="5"
                          className="form-control"
                          type="text"
                          id="additional_details"
                          name="additionalNote"
                          value={respondRfq.additionalNote}
                          onChange={handleChange}
                        />
                      </div>
                      <Modal.Footer>
                        {!loader ? (
                          <Button
                            variant="secondary"
                            className="respond-btn"
                            type="submit"
                          >
                            {t("Send_Quote")}
                          </Button>
                        ) : (
                          <Button
                            variant="secondary"
                            className="respond-btn"
                            type="submit"
                          >
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </Button>
                        )}
                      </Modal.Footer>
                    </Form>
                  </div>
                </div>
              </Modal.Body>
            </Modal>

            <Modal
              show={showquote}
              onHide={handleCloseQuote}
              backdrop="static"
              centered
              size="lg"
              keyboard={false}
            >
              <Modal.Body>
                <Modal.Header closeButton></Modal.Header>
                <div className="quote-modal">
                  <div className="rfq-details">
                    <p>{t("RFQ's_Detail")}</p>
                    <div className="product-info supplier-product-info">
                      <p className="product-info-title">{t("Product_Name")}</p>
                      <p className="product-info-description">
                        {rfqInfo.productName && Capitalize(rfqInfo.productName)}
                      </p>
                    </div>
                    <div className="product-info supplier-product-info">
                      <p className="product-info-title">{t("Incoterm")}</p>
                      <p className="product-info-description">
                        {rfqInfo && rfqInfo.termsOfTrade}
                      </p>
                    </div>
                    <div className="product-info supplier-product-info">
                      <p className="product-info-title">{t("Destination")}</p>
                      <p className="product-info-description">
                        {rfqInfo && rfqInfo.destinationPort}
                      </p>
                    </div>
                    <div className="product-requirement supplier-product-requirement">
                      <p>{t("Product_Requirement")}</p>
                      <p>{rfqInfo && rfqInfo.productDescription}</p>
                    </div>
                  </div>
                  <div className="supplier-response">
                    <Form onSubmit={handleRfqUpdate} className="mx-0">
                      <p>{t("Supplier's_Response")}</p>

                      <div className="form-group form-group supplier-form-group">
                        <label>
                          ({selectedCurrency}) {t("Price")}
                        </label>
                        <input
                          className="form-control"
                          type="number"
                          id="price"
                          name="priceResponse"
                          value={priceResponse}
                          onChange={(e) => setPriceResponse(e.target.value)}
                        />
                      </div>
                      <div className="form-group supplier-form-group">
                        <label>{t("Additional_Details")}</label>
                        <textarea
                          rows="5"
                          className="form-control"
                          type="text"
                          id="additional_details"
                          name="addNoteResponse"
                          value={addNoteResponse}
                          onChange={(e) => setAddNoteResponse(e.target.value)}
                        />
                      </div>
                      <Modal.Footer>
                        {!loader ? (
                          <Button
                            variant="secondary"
                            className="respond-btn"
                            type="submit"
                          >
                            {t("Update_Quote")}
                          </Button>
                        ) : (
                          <Button
                            variant="secondary"
                            className="respond-btn"
                            type="submit"
                          >
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </Button>
                        )}
                      </Modal.Footer>
                    </Form>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </main>
        )}
      </div>
    </>
  );
};

export default ProtectedRoutes(SellersRfqsInfo, ["SELLER"]);
