import React, { useState, useEffect, useMemo, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import BuyersSidebar from "../BuyersComponent/BuyerSideBar";

import { ProtectedRoutes } from "../../../../components/utils/ProtectedRoutes";
import { axiosInstance } from "../../../../components/baseUrl";

import SearchInput from "../BuyersComponent/SearchInput";
import PaginationComponent from "../BuyersComponent/Pagination";
import { GlobalContext } from "../../../../components/utils/GlobalState";
import BuyersHeader from "../BuyersComponent/BuyersHeader";
import CardOverviewLoader from "../../../../components/SkeletonLoader/CardOverviewLoader";
import TableSkeleton from "../../../../components/SkeletonLoader/TableSkeleton";
import cookies from "js-cookie";
import { convertPrice } from "../../../../components/helpers/convertPrice";
import {
  numberWithCommas,
  Capitalize,
} from "../../../../components/helpers/functionHelpers";
import { useGetBuyerOrders } from "../../../../components/hooks/useGetBuyerOrders";
import { convertDateFormat } from "../../../../components/helpers/convertDate";

const BuyersRfqs = () => {
  const { t } = useTranslation();
  const { user, userLoading } = useContext(GlobalContext);
  const { summary, orderLoading } = useGetBuyerOrders();
  const [allRfq, setAllRfq] = useState([]);
  const [rfqLoading, setRfqLoading] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const ITEMS_PER_PAGE = 10;
  const [noMatch, setNoMatch] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();

  const selectedCurrency = cookies.get("currency") || "NGN";

  const handleClick = (event) => {
    setIsActive((current) => !current);
  };

  const getAllRfq = async () => {
    try {
      axiosInstance.get("/rfq").then((response) => {
        setAllRfq(response.data.data.rfqs);
        setRfqLoading(false);
      });
    } catch (error) {
      console.log(error);
      setRfqLoading(false);
      if (error.message && error.message === "Network Error") {
        navigate("/no-connection");
      }
    }
  };

  useEffect(() => {
    getAllRfq();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rfqsData = useMemo(() => {
    let computedrfqs = allRfq;

    if (search) {
      computedrfqs = computedrfqs.filter(
        (rfq) =>
          rfq.productName.toLowerCase().includes(search.toLowerCase()) ||
          rfq.status.toLowerCase().includes(search.toLowerCase()) ||
          rfq.destinationPort.toLowerCase().includes(search.toLowerCase())
      );
      if (computedrfqs.length < 1) {
        setNoMatch(true);
        setTotalItems(0);
      } else if (computedrfqs.length > 0) {
        setNoMatch(false);
      }
    } else {
      setNoMatch(false);
    }

    setTotalItems(computedrfqs.length);

    return computedrfqs.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [allRfq, currentPage, search]);

  return (
    <>
      <div className="grid-container">
        <BuyersHeader
          title="RFQs"
          handleClick={handleClick}
          user={user}
          userLoading={userLoading}
          Capitalize={Capitalize}
        />

        <BuyersSidebar isActive={isActive} handleClick={handleClick} />

        <main className="main">
          {orderLoading ? (
            <CardOverviewLoader />
          ) : (
            <div className="row">
              <div className="col-lg-4 col-sm-6 col-12">
                <div className="overview-card">
                  <div>
                    <h2>{t("All_RFQ's")}</h2>
                    <p className="order-p">
                      {t("Scroll_down_for_Detailed_RfQ’s_history")}
                    </p>
                    <div className="d-flex justify-content-between mt-4">
                      <h3>
                        {summary.total_number_of_enquiries &&
                          numberWithCommas(summary.total_number_of_enquiries)}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-12">
                <div className="overview-card">
                  <div>
                    <div className="orderspan">
                      <h2>{t("Awaiting_Responses")} </h2>
                      <span className="orderspan">
                        <i className="fas fa-ellipsis-v"></i>
                      </span>
                    </div>

                    <p className="order-p">{t("Click_to_view")}</p>
                    <div className="d-flex justify-content-between mt-4">
                      <h3>
                        {summary.total_responded_quote &&
                          numberWithCommas(
                            summary.total_number_of_enquiries -
                              summary.total_responded_quote
                          )}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-12">
                <div className="overview-card">
                  <div>
                    <div className="orderspan">
                      <h2>{t("Recieved_Quotes")}</h2>
                      <span>
                        <i className="fas fa-ellipsis-v"></i>
                      </span>
                    </div>

                    <p className="order-p">{t("Detailed_transaction")}</p>
                    <div className="d-flex justify-content-between mt-4">
                      <h3>
                        {summary.total_responded_quote &&
                          numberWithCommas(summary.total_responded_quote)}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="section-title-ctn">
            <h1 className="section-title">{t("All_RFQ's")}</h1>
            <div className="header__search">
              <SearchInput
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
                placeholder="Search for rfqs, product name and more"
              />
            </div>
          </div>

          {rfqLoading ? (
            <TableSkeleton />
          ) : (
            <>
              {allRfq && allRfq.length > 0 ? (
                <div className="main-overview">
                  <div className="overview-card no-padding">
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">{t("Date")}</th>
                            <th scope="col">{t("Enquiry_Info")}</th>
                            <th scope="col">{t("Quantity")}</th>
                            <th scope="col">{t("Expected_Price")}</th>
                            <th scope="col">{t("Country")}</th>
                            <th scope="col">{t("Status")}</th>
                            <th scope="col">{t("Action")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {rfqsData &&
                            rfqsData.map((rfq, index) => (
                              <tr key={index}>
                                <td>
                                  {rfq.createdAt &&
                                    convertDateFormat(rfq.createdAt)}
                                </td>
                                <td>
                                  <div className="d-flex">
                                    <div className="flex-shrink-0">
                                      <p>
                                        {rfq.productName &&
                                          Capitalize(rfq.productName)}
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  {rfq.quantityRequired && rfq.quantityRequired}
                                  {rfq.unit && rfq.unit}
                                </td>
                                <td>
                                  {selectedCurrency}{" "}
                                  {rfq.targetPrice &&
                                    convertPrice(
                                      rfq.targetPrice,
                                      selectedCurrency
                                    )}
                                </td>
                                <td>
                                  {" "}
                                  {rfq.destinationPort && rfq.destinationPort}
                                </td>

                                <td>
                                  {" "}
                                  {rfq.status === "AWAITING_RESPONSE" && (
                                    <div className="text-warning">
                                      {t("Awaiting_Response")}
                                    </div>
                                  )}
                                  {rfq.status === "RESPONDED" && (
                                    <div className="text-success">
                                      {t("Responded")}
                                    </div>
                                  )}
                                </td>

                                <td>
                                  <Link to={`/buyer/rfq-info/${rfq.id}`}>
                                    {t("View")}
                                  </Link>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="empty-state">
                  <h3>{t("There_are_no_RFQs")}</h3>
                  <p>
                    {t("Request_for_quote")} <Link to="/">{t("Here.")}</Link>{" "}
                    {t("All_your_RFQs")}
                  </p>
                </div>
              )}
            </>
          )}

          {noMatch === true ? (
            <div className="empty-state">
              <p>{t("No_RFQ_result_matched_your_criteria.")}</p>
            </div>
          ) : (
            <PaginationComponent
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          )}
        </main>
      </div>
    </>
  );
};

export default ProtectedRoutes(BuyersRfqs, ["BUYER", "SELLER"]);
