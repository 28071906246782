import React from "react";
import { useTranslation } from "react-i18next";
import Footer from "../../components/Footer/Footer";
import "./Support.css";
import NewHeader from "../../components/NewHeader/NewHeader";

const Support = () => {
  const { t } = useTranslation();
  const support = process.env.REACT_APP_SUPPORT;
  return (
    <div>
      <NewHeader />
      <div className="container support-ctn">
        <div className="main-banner"></div>
        <div className="service-box-ctn row">
          <div className="service-box-wrapper col-md-6   col-12">
            <a
              href="https://wa.me/+2349166417373"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="service-box">
                <div className="service-box-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M14.25 9.75v-4.5m0 4.5h4.5m-4.5 0l6-6m-3 18c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z"
                    />
                  </svg>
                </div>
                <div className="service-box-content">
                  <h4>{t("Online_Service")}</h4>
                  <p>{t("24/7_Service_for_Buyer_or_Seller")}</p>
                </div>
              </div>
            </a>
          </div>
          <div className="service-box-wrapper col-md-6 col-12">
            {" "}
            <div className="service-box">
              <div className="service-box-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21.75 9v.906a2.25 2.25 0 01-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 001.183 1.981l6.478 3.488m8.839 2.51l-4.66-2.51m0 0l-1.023-.55a2.25 2.25 0 00-2.134 0l-1.022.55m0 0l-4.661 2.51m16.5 1.615a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V8.844a2.25 2.25 0 011.183-1.98l7.5-4.04a2.25 2.25 0 012.134 0l7.5 4.04a2.25 2.25 0 011.183 1.98V19.5z"
                  />
                </svg>
              </div>
              <div className="service-box-content">
                <h4>{t("Contact_us_via_email")}</h4>
                <p>{support}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Support;
