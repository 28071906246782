import { useState } from "react";
import { axiosInstance } from "../baseUrl";
import { useNavigate } from "react-router-dom";

export const useGetReviewDetailsForEachProduct = () => {
  const [reviewDetails, setReviewDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const getProductReviews = async (productId) => {
    try {
      setIsLoading(true);
      const { data } = await axiosInstance.get(
        `/reviews/get-product-reviews/${productId}`
      );
      console.log("data", data);
      setReviewDetails(data?.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error.message && error.message === "Network Error") {
        navigate("/no-connection");
      }
    }
  };
  return {
    reviewDetails,
    isLoading,
    getProductReviews,
  };
};
