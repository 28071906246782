import { useState } from "react";
import { axiosInstance } from "../baseUrl";

export const useGetTransactPayBanks = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [transactPayBanks, setTransactPayBanks] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const getTransactPayBanks = async () => {
    setIsLoading(true);
    try {
      const { data } = await axiosInstance.get(`/transact-pay/banks`);
      setTransactPayBanks(data?.data?.data);
    } catch (error) {
      console.log("error getting transactPay banks", error);
    } finally {
      setIsLoading(false);
    }
  };

  const filteredBanks = transactPayBanks?.filter((bank) =>
    bank?.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return {
    isLoading,
    transactPayBanks,
    getTransactPayBanks,
    filteredBanks,
    searchTerm,
    setSearchTerm,
  };
};
