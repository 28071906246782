import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const UserProfileSkeleton = () => {
  return (
    <header className="header">
      <div className="header__message me-5">
        <h2>
          <Skeleton height={20} />
        </h2>
      </div>
      <div className="header__search"></div>

      <div className="user-area ms-auto">
        <div className="d-flex align-items-center">
          <div className="flex-shrink-0 user-area-art">
            {" "}
            <Skeleton height={20} circle={true} />
          </div>
          <div className="flex-grow-1 ms-2">
            <p>
              {" "}
              <Skeleton height={20} />
            </p>
          </div>
        </div>
      </div>
    </header>
  );
};

export default UserProfileSkeleton;
