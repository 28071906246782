import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import "./HelpCenter.css";
import Footer from "../../components/Footer/Footer";
import SearchIssue from "./SearchIssue";
import PaginationComponent from "../Dashboard/BuyersDashboard/BuyersComponent/Pagination";
import NewHeader from "../../components/NewHeader/NewHeader";

const HelpCenter = () => {
  const { t } = useTranslation();
  const companyNameAbr = process.env.REACT_APP_COMPANY_NAME_ABR;
  const companyNameShr = process.env.REACT_APP_COMPANY_NAME_CAPS;
  const [show, setShow] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const ITEMS_PER_PAGE = 15;
  const [noMatch, setNoMatch] = useState(false);

  const handleClick = (index) => {
    setShow(index);
    setIsActive((current) => !current);
  };

  const hcData = useMemo(() => {
    const hcContent = [
      {
        header: "Who is Traders of Africa?",
        content: [
          " Traders of Africa – TOFA is a Pan African hub for facilitating trade with, within and for Africa through technology.",
          "We have built the infrastructure for global buyers and African suppliers to trade.",
        ],
      },
      {
        header: "How to place an order from cart",
        content: [
          " Step 1: Browse Products",
          "Step 2: Add product to cart.",
          "Step 3: Navigate to heckout, follow the prompts to enter your personal and shipping",
          "Step 4: Select mode of payment (MoMo, Flutterwave, LC  or TT)",
          "Step 5: Make payment",
        ],
      },
      {
        header: "How to place an order from RFQ",
        content: [
          " Step 1: Open the product you want to order.",
          "Step 2: Request for quote by providing details of the product you want and how much you are willing to pay for the product.",
          "Step 3: Seller responds to your quote. ",
          "Step 4: Go to RFQ page in your dashboard and place your order using seller's response. ",
          "Step 5: Check your mail for payment details and make payment",
          "Step 6: Go to your dashboard, in order info page, you will see where to upload proof of payment, upload proof of payment.",
          "Step 7: Once proof of payment is verified, your order will be processed.",
        ],
      },
      {
        header: "How do I track my order status",
        content: [
          "Step 1: Go to your dashboard,",
          "Step 2: Click on orders,",
          "Step 3: Click on order details/ order info, all details of your order is listed on the page.",
          "For aliexpress products, An email update on every movement is sent to the buyer.",
        ],
      },
      {
        header: "My order is delayed what should I do?",
        content: [
          "We aim to avoid delays however due to unforeseen circumstances such as severe weather, delay in payment confirmation or custom restrictions etc. packages can face delays. If your delivery is more than 24 hours late please contact us here. We will also send you an update via email as soon as it’s available for delivery",
        ],
      },
      {
        header: "Can I pay before delivery?",
        content: [
          `No, ${companyNameAbr} doesn't accept payment before delivery. Payments are first confirmed before seller releases product.`,
        ],
      },
      {
        header:
          "What happens if after payment, the seller doesn't release my products?",
        content: [
          `Your money is being paid into ${companyNameAbr}'s account. If after making payment and payment is confirmed, then seller by any chance chooses not to release product to you, TOFA refunds you your money after it has been confirmed that the goods wasn't released to you.`,
        ],
      },
    ];

    let computedHc = hcContent;

    if (search) {
      computedHc = computedHc.filter((content) =>
        content.header.toLowerCase().includes(search.toLowerCase())
      );
      if (computedHc.length < 1) {
        setNoMatch(true);
        setTotalItems(0);
      } else if (computedHc.length > 0) {
        setNoMatch(false);
      }
    } else {
      setNoMatch(false);
    }

    setTotalItems(computedHc.length);

    return computedHc.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [currentPage, search, companyNameAbr]);

  return (
    <div>
      {/* <Header /> */}
      <NewHeader />
      <div className="container">
        <div className="help-center-title">
          <h2>{t("Help_Center")}</h2>
          <p>
            {companyNameShr} {t("customer_care")}
          </p>
        </div>
        <div className="help-center-wrapper">
          <div className="hc-content">
            <div className="hc-search-ctn">
              <SearchIssue
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
                placeholder="Enter your Issue"
              />
            </div>
          </div>
          {hcData &&
            hcData.map((content, index) => (
              <div className="hc-content" key={index + 1}>
                <div
                  className="hc-title-ctn"
                  onClick={(e) => handleClick(index + 1)}
                >
                  <h4 className="hc-title">{content.header}</h4>
                  {show && show === index + 1 && isActive ? (
                    <i className="fas fa-chevron-up"></i>
                  ) : (
                    <i className="fas fa-chevron-down"></i>
                  )}
                </div>
                <div
                  className={
                    show && show === index + 1 && isActive
                      ? "hc-paragraph-ctn-show"
                      : "hc-paragraph-ctn-hide"
                  }
                >
                  {content.content &&
                    content.content.map((data, index) => (
                      <p key={index}>{data}</p>
                    ))}
                </div>
              </div>
            ))}
          {noMatch === true ? (
            <div className="empty-state">
              <p>{t("No_issue_matched")}</p>
            </div>
          ) : (
            <PaginationComponent
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HelpCenter;
