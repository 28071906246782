import React from "react";
import { useParams, Link } from "react-router-dom";
import SiteLogo from "../../assets/img/logo.png";
import { useTranslation } from "react-i18next";

export default function EmailVerification() {
  const { email } = useParams();
  const { t } = useTranslation();

  return (
    <>
      <div>
        <section className="auth">
          <div className="auth-lhs">
            <div className="auth-lhs-header">
              <Link to="/">
                <img className="site-logo" src={SiteLogo} alt="" />
              </Link>
            </div>
          </div>
          <div className="auth-rhs">
            <div className="auth-rhs-header d-flex justify-content-between">
              <img className="site-logo mobile-only" src={SiteLogo} alt="" />
              <p>
                <Link to="/support">
                  <i className="fas fa-info-circle support-icon me-2">
                    {" "}
                    {t("Customer_Support")}
                  </i>
                </Link>
              </p>
            </div>
            <div className="auth-rhs-content d-flex align-items-center">
              <div className="rhs-inner">
                <h2>{t("Success")}</h2>
                <p className="phone-v-inst">{t("You_followed")}</p>
                <div className="auth-account-wrap">
                  <p>
                    {t("Please_verify")} {email}. {t("To_continue")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
