import React from "react";
import { useTranslation } from "react-i18next";

const TofaBankDetails = () => {
  const { t } = useTranslation();
  const support = process.env.REACT_APP_SUPPORT;
  const bankName = process.env.REACT_APP_BANK_NAME;
  const accountNumber = process.env.REACT_APP_ACCOUNT_NUMBER_2ND;
  const accountName = process.env.REACT_APP_ACCOUNT_NUMBER_2ND;

  return (
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">{t("Payment_details")}</h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div className="modal-body">
        <div className="payment-modal">
          <h6 className="mt-3">{t("Your_order_was_succefully_placed.")} </h6>
          <div className="nigeria-order-ctn mt-4">
            <p className="title">
            {t("To_process")}
            </p>
            <ul>
              <li>
                <span>{t("Bank_name")}:</span> {bankName}{" "}
              </li>
              <li>
                <span>{t("Account_name")}:</span> {accountName}{" "}
              </li>
              <li>
                <span>{t("Account_number")}:</span> {accountNumber}{" "}
              </li>
            </ul>
          </div>
          <p className="notice">
            <span className="danger">{t("Important_Notice")}:</span> {t("After_payment,")}{" "}
            {t("visit_your_dashboard")}{" "}
            {t("info_page_loads")}{" "}
            {t("processed")}{" "}
            <span className="title">{support}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TofaBankDetails;
