import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SellersHeader from "../SellersComponent/SellersHeader";
import SellersSidebar from "../SellersComponent/SellerSideBar";
import { GlobalContext } from "../../../../components/utils/GlobalState";
import { useNavigate } from "react-router-dom";
import { ProtectedRoutes } from "../../../../components/utils/ProtectedRoutes";
import { Toaster, toast } from "react-hot-toast";
import { axiosInstance } from "../../../../components/baseUrl";
import { useJsApiLoader, StandaloneSearchBox } from "@react-google-maps/api";

const SellerEditProfile = () => {
  const { t } = useTranslation();
  const { user } = useContext(GlobalContext);
  const [isActive, setIsActive] = useState(false);
  const [updateLoader, setUpdateLoader] = useState(false);
  const [rerender, setRerender] = useState(true);
  const [businessName, setBusinessName] = useState("");
  const [country, setCountry] = useState("");
  const [businessType, setBusinessType] = useState("");
  const [businessDescription, setBusinessDescription] = useState("");
  const [totalAnnualRevenue, setTotalAnnualRevenue] = useState("");
  const [supplyCapacity, setSupplyCapacity] = useState("");
  const [address, setAddress] = useState("");
  const [yearEstablished, setYearEstablished] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const getUser = () => {
    axiosInstance
      .get(`/auth/current-user`)
      .then((response) => {
        setIsLoading(false);
        if (response.data.currentUser.businessName) {
          setBusinessName(response.data.currentUser.businessName);
        }
        if (response.data.currentUser.country) {
          setCountry(response.data.currentUser.country);
        }
        if (response.data.currentUser.address) {
          setAddress(response.data.currentUser.address);
        }
        if (response.data.currentUser.businessType) {
          setBusinessType(response.data.currentUser.businessType);
        }
        if (response.data.currentUser.businessDescription) {
          setBusinessDescription(response.data.currentUser.businessDescription);
        }
        if (response.data.currentUser.totalAnnualRevenue) {
          setTotalAnnualRevenue(response.data.currentUser.totalAnnualRevenue);
        }
        if (response.data.currentUser.supplyCapacity) {
          setSupplyCapacity(response.data.currentUser.supplyCapacity);
        }
        if (response.data.currentUser.yearEstablished) {
          setYearEstablished(response.data.currentUser.yearEstablished);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        if (error.message && error.message === "Network Error") {
          navigate("/no-connection");
        }
      });
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rerender]);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });

  const handlePlacesChanged = () => {
    // Access the Autocomplete instance using refs
    const autocomplete = autocompleteRef.current;

    if (autocomplete) {
      const places = autocomplete.getPlaces();
      if (places && places.length > 0) {
        const selectedPlace = places[0];
        setAddress(selectedPlace.formatted_address || "");
      }
    }
  };

  // Ref to hold the Autocomplete instance
  const autocompleteRef = React.useRef(null);

  const handleEditProfile = async (e) => {
    e.preventDefault();

    if (address) {
      // to check if address exist with google before upgating the user profile
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          address
        )}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
      );

      const data = await response.json();

      if (data.status !== "OK") {
        toast.error(
          `Invalid address. Please enter a valid address from the suggestions.`,
          {
            duration: 4000,
            style: {
              background: "#353434",
              color: "#fff",
            },
          }
        );
        return;
      } else {
        setUpdateLoader(true);
        try {
          const editUserDetails = {
            country,
            businessName,
            businessType,
            businessDescription,
            totalAnnualRevenue,
            supplyCapacity,
            address,
            yearEstablished,
          };
          await axiosInstance.patch(
            "/auth/user/update-profile",
            editUserDetails
          );
          setUpdateLoader(false);
          toast.success("Your profile has been successful updated", {
            duration: 4000,
            style: {
              background: "#353434",
              color: "#fff",
            },
          });
          setRerender((prev) => !prev);
        } catch (error) {
          console.log(error);
          setUpdateLoader(false);
          if (!error.response.data.errors) {
            return navigate(`/no-connection`);
          }
          toast.error(`${error.response.data.errors[0].message}`, {
            duration: 4000,
            style: {
              background: "#353434",
              color: "#fff",
            },
          });
        }
      }
    } else {
      toast.error(`Please enter an address.`, {
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
    }
  };

  const handleClick = (event) => {
    setIsActive((current) => !current);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  return (
    <>
      <Toaster />
      <div>
        <div className="grid-container">
          <SellersHeader
            handleClick={handleClick}
            user={user}
            title="Settings"
          />

          <SellersSidebar isActive={isActive} handleClick={handleClick} />

          <main className="main">
            <div className="main-overview">
              <div className="overview-card">
                <div className="seller-profile-card">
                  <div className="seller-profile-pic"></div>
                  <div className="seller-setting-profile">
                    <p>{t("Accouint_details")}</p>
                  </div>
                  {!isLoaded || isLoading ? (
                    <div className="lds-ctn">
                      <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  ) : (
                    <form>
                      <div className="row g-4">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>{t("Business_Name")}</label>
                            <input
                              className="form-control"
                              type="text"
                              id="fullname"
                              name="businessName"
                              value={businessName}
                              onChange={(e) => setBusinessName(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>{t("Business_type")}</label>
                            <input
                              className="form-control"
                              type="text"
                              id="businessType"
                              name="businessType"
                              value={businessType}
                              onChange={(e) => setBusinessType(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="form-group">
                            <label>{t("Business_Description")}</label>
                            <textarea
                              className="form-control"
                              type="text"
                              rows={5}
                              name="businessDescription"
                              value={businessDescription}
                              onChange={(e) =>
                                setBusinessDescription(e.target.value)
                              }
                            />
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>{t("Total_Annual_Revenues")}</label>
                            <input
                              className="form-control"
                              type="text"
                              name="totalAnnualRevenue"
                              value={totalAnnualRevenue}
                              onChange={(e) =>
                                setTotalAnnualRevenue(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>{t("Supply_Capacity")}</label>
                            <input
                              className="form-control"
                              type="text"
                              name="supplyCapacity"
                              value={supplyCapacity}
                              onChange={(e) =>
                                setSupplyCapacity(e.target.value)
                              }
                            />
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>{t("Country")}</label>
                            <input
                              className="form-control"
                              id="country"
                              name="country"
                              value={country}
                              onChange={(e) => setCountry(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>{t("Year_Established")}</label>
                            <input
                              className="form-control"
                              type="text"
                              id="subscription"
                              name="yearEstablished"
                              value={yearEstablished}
                              onChange={(e) =>
                                setYearEstablished(e.target.value)
                              }
                            />
                          </div>
                        </div>

                        <div className="form-group">
                          <label>{t("Address")}</label>

                          <StandaloneSearchBox
                            onLoad={(ref) => (autocompleteRef.current = ref)}
                            onPlacesChanged={handlePlacesChanged}
                          >
                            <input
                              className="form-control address"
                              type="text"
                              id="address"
                              name="address"
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                            />
                          </StandaloneSearchBox>
                        </div>
                      </div>
                      <div className="seller-footer">
                        <div className="seller-seting-submit">
                          {!updateLoader ? (
                            <button
                              type="submit"
                              className=" savechanges mt-3"
                              onClick={handleEditProfile}
                            >
                              {t("Save_Changes")}
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="savechanges px-3 mt-3"
                            >
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            </button>
                          )}
                        </div>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default ProtectedRoutes(SellerEditProfile, ["SELLER"]);
