import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../baseUrl";

export const useGetBuyerOrders = () => {
  const [allUserOrder, setAllUserOrder] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState("");
  const itemsPerPage = 10;
  const navigate = useNavigate();

  const onErrorSummary = (error) => {
    if (error) {
      navigate("/no-connection");
    }
  };

  const getOrderSummary = () => {
    return axiosInstance
      .get(`/dashboard/activity-summary`)
      .then((res) => res.data.data);
  };

  const { data: summary, isLoading: orderLoading } = useQuery(
    "buyer-order-summary",
    getOrderSummary,
    {
      onError: onErrorSummary,
    }
  );

  const getAllOrders = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `/order/buyer?page=${currentPage}`
      );
      setAllUserOrder(response?.data?.data?.orders);
      const totalOrders = response?.data?.data?.pagination.totalOrders;
      setTotalPages(Math.ceil(totalOrders / itemsPerPage));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      if (error.message && error.message === "Network Error") {
        navigate("/no-connection");
      }
    }
  };

  useEffect(() => {
    getAllOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, !search]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (search) {
      setLoading(true);
      try {
        const data = await axiosInstance.get(
          `/order/buyer?page=${currentPage}&productName=${search}&cost=${search}&orderNumber=${search}`
        );
        setLoading(false);
        setAllUserOrder(data?.data?.data?.orders);
        const totalOrders = data?.data?.data?.pagination.totalOrders;
        setTotalPages(Math.ceil(totalOrders / itemsPerPage));
        setLoading(false);
      } catch (error) {
        console.log(error);
        if (error.message && error.message === "Network Error") {
          navigate("/no-connection");
        }
      }
    }
  };

  return {
    handlePageChange,
    summary,
    orderLoading,
    totalPages,
    loading,
    allUserOrder,
    currentPage,
    handleSubmit,
    search,
    setSearch,
    handleChange,
  };
};
