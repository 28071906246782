import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import "../Authentication/Authentication.css";
import SiteLogo from "../../assets/img/logo.png";
import { axiosInstance } from "../../components/baseUrl.jsx";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const OldMutualRegistration = () => {
  const { t } = useTranslation();
  const [sellerRegisterDetail, setSellerRegisterDetail] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    howDidYouHearAboutUs: "",
  });
  const [phoneValue, setPhoneValue] = useState("");
  const [formattedErrors, setFormattedErrors] = useState({});
  const [customError, setCustomError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const navigate = useNavigate();
  const [inputType, setInputType] = useState("password");
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setSellerRegisterDetail({
      ...sellerRegisterDetail,
      [e.target.name]: e.target.value,
    });
  };

  const handleValidation = (evnt) => {
    const passwordInputValue = evnt.target.value.trim();
    const passwordInputFieldName = evnt.target.name;
    //htmlFor password
    if (passwordInputFieldName === "password") {
      const uppercaseRegExp = /(?=.*?[A-Z])/;
      const lowercaseRegExp = /(?=.*?[a-z])/;
      const digitsRegExp = /(?=.*?[0-9])/;
      const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
      const minLengthRegExp = /.{8,}/;
      const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
      const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
      const digitsPassword = digitsRegExp.test(passwordInputValue);
      const specialCharPassword = specialCharRegExp.test(passwordInputValue);
      const minLengthPassword = minLengthRegExp.test(passwordInputValue);
      let errMsg = "";
      if (!uppercasePassword) {
        errMsg = "At least one uppercase";
      } else if (!lowercasePassword) {
        errMsg = "At least one lowercase";
      } else if (!digitsPassword) {
        errMsg = "At least one digit";
      } else if (!specialCharPassword) {
        errMsg = "At least one special character";
      } else if (!minLengthPassword) {
        errMsg = "password should not be 8 characters ";
      } else {
        errMsg = "";
      }
      setPasswordError(errMsg);
    }
  };
  const handlePasswordToggle = (e) => {
    inputType === "password" ? setInputType("text") : setInputType("password");
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const sellerRegistration = {
        firstName: sellerRegisterDetail.firstName,
        LastName: sellerRegisterDetail.lastName,
        email: sellerRegisterDetail.email,
        phoneNumber: phoneValue,
        role: "SELLER",
        password: sellerRegisterDetail.password,
        hearAboutUs: "OLD_MUTUAL",
      };
      const {
        data: { data },
      } = await axiosInstance.post(`/auth/register-user`, sellerRegistration);

      navigate(`/verify-phone-number`);
      localStorage.setItem("userData", JSON.stringify(data));
    } catch (error) {
      setLoading(false);
      if (error.response.data.errors[0].field) {
        if (!error.response.data.errors) {
          return navigate(`/no-connection`);
        }
        console.log(error.response.data.errors);
        setFormattedErrors(
          error.response.data.errors.reduce(function (obj, err) {
            obj[err.field] = err.message;
            return obj;
          }, {})
        );
      } else {
        console.log(error.response.data.errors[0].message);
        setCustomError(error.response.data.errors[0].message);
      }
    }
  };

  return (
    <>
      <div>
        <section className="auth">
          <div className="auth-lhs" id="old-mutual-background">
            <div className="auth-lhs-header">
              <img className="site-logo" src={SiteLogo} alt="" />
            </div>
          </div>
          <div className="auth-rhs">
            <div className="auth-rhs-header d-flex justify-content-between ">
              <img className="site-logo mobile-only" src={SiteLogo} alt="" />
              <p>
              {t("Already_have_an_account?")}{" "}
                <Link to="/login" className="user-link">
                  {t("Login")}
                </Link>
              </p>
            </div>
            <div className="auth-rhs-content d-flex align-items-center">
              <div className="rhs-inner">
                <h2>{t("Register_as_a_Seller")}</h2>
                <p>{t("You_are_a_few_steps")}</p>
                <div className="auth-account-wrap">
                  <form className="auth-form" onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                      >
                        {t("First_name")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        name="firstName"
                        value={sellerRegisterDetail.firstName}
                        onChange={handleChange}
                      />
                      {formattedErrors.firstName && (
                        <p className="errors">{formattedErrors.firstName}</p>
                      )}
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                      >
                        {t("Last_name")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        name="lastName"
                        value={sellerRegisterDetail.lastName}
                        onChange={handleChange}
                      />
                      {formattedErrors.LastName && (
                        <p className="errors">{formattedErrors.LastName}</p>
                      )}
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                      >
                        {t("Email_address")}
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        name="email"
                        value={sellerRegisterDetail.email}
                        onChange={handleChange}
                      />
                      {formattedErrors.email && (
                        <p className="errors">{formattedErrors.email}</p>
                      )}
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                      >
                        {t("Phone_number")}
                      </label>
                      <PhoneInput
                        onChange={setPhoneValue}
                        value={phoneValue}
                        name="phoneValue"
                        className="form-control"
                        defaultCountry="ZA"
                      />
                      {formattedErrors.phoneNumber && (
                        <p className="errors">{formattedErrors.phoneNumber}</p>
                      )}
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputPassword1"
                        className="form-label"
                      >
                        {t("Password")}
                      </label>
                      <div className="form-control passwordToggle">
                        <input
                          type={inputType}
                          id="exampleInputPassword1"
                          name="password"
                          value={sellerRegisterDetail.password}
                          onChange={handleChange}
                          onKeyUp={handleValidation}
                          required
                        />
                        <span
                          className={"password-icon"}
                          onClick={handlePasswordToggle}
                        >
                          {inputType === "password" ? (
                            <i className="far fa-eye-slash mt-1 pt-1"></i>
                          ) : (
                            <i className="far fa-eye mt-1 pt-1"></i>
                          )}
                        </span>
                      </div>
                      <p className="error-message">{passwordError}</p>
                    </div>

                    {customError && (
                      <p className="errors">{t("User_already_exist")}</p>
                    )}
                    <div className="mb-3 form-check">
                      <input
                        type="checkbox"
                        className="form-check-input custom-checkbox"
                        id="exampleCheck1"
                        required
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleCheck1"
                      >
                        {t("I_agree_to_the")}{" "}
                        <Link className="user-link-doc">
                          {t("Terms_&_Conditions")}
                        </Link>{" "}
                        {t("and")}{" "}
                        <Link className="user-link-doc">{t("Privacy_Policy")}</Link>
                      </label>
                    </div>

                    {loading ? (
                      <button
                        type="submit"
                        className="btn btn-danger"
                        style={{
                          backgroundColor: "#5EBB49",
                          border: "1px solid #5EBB49",
                        }}
                      >
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-danger"
                        style={{
                          backgroundColor: "#5EBB49",
                          border: "1px solid #5EBB49",
                        }}
                      >
                        {t("Signup")}
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default OldMutualRegistration;
