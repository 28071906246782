import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../baseUrl";

export const useGetRfqById = () => {
  const [rfqInfoLoad, setRfqInfoLoad] = useState(false);
  const [rfqInfo, setRfqInfo] = useState({});

  const navigate = useNavigate();

  const viewRfqInfo = async (rfqId) => {
    setRfqInfoLoad(true);
    await axiosInstance
      .get(`/multiple-rfq/${rfqId}`)
      .then((response) => {
        setRfqInfo(response.data.data);
        setRfqInfoLoad(false);
      })
      .catch((error) => {
        setRfqInfoLoad(false);
        if (error.message && error.message === "Network Error") {
          navigate("/no-connection");
        }
      });
  };
  return { rfqInfoLoad, rfqInfo, viewRfqInfo };
};
