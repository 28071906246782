import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../../../components/baseUrl";
import toast, { Toaster } from "react-hot-toast";

const MessageModal = ({ supplierInfo, Capitalize }) => {
  const { t } = useTranslation();
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState({
    msg: " ",
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    e.preventDefault();
    setMessage({ ...message, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    let toastId = "";
    setLoader(true);
    toastId = toast.loading("loading...", {
      style: {
        background: "#353434",
        color: "#fff",
      },
    });
    try {
      const createMessage = {
        to: supplierInfo.seller.id,
        message: message.msg,
      };
      await axiosInstance.post("/message/send-message", createMessage);

      toast.success("Your message was successful sent.", {
        id: toastId,
        duration: 3000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
      setLoader(false);
      setMessage({
        msg: " ",
      });
      setTimeout(() => {
        window.location.reload();
      }, 6300);
    } catch (err) {
      console.log(err);
      setLoader(false);
      if (!err.response.data.errors) {
        return navigate(`/no-connection`);
      }
      toast.error(`${err.response.data.errors[0].message}`, {
        id: toastId,
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
    }
  };

  return (
    <div>
      <div
        className="modal fade"
        id="exampleModalToggle2"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel2"
        tabIndex="-1"
      >
        <Toaster />
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content px-2">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalToggleLabel">
              {t("Message_Supplier")}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-12 px-4">
                  {" "}
                  <form>
                    <div className="mb-3">
                      <p>
                        <span> {t("Recipient_Name")}: </span>
                        {supplierInfo.seller &&
                          Capitalize(supplierInfo.seller.firstName)}{" "}
                        {supplierInfo.seller &&
                          Capitalize(supplierInfo.seller.LastName)}
                      </p>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="message-text" className="col-form-label">
                        {t("Message")}:
                      </label>
                      <textarea
                        className="form-control"
                        id="message-text"
                        rows="6"
                        name="msg"
                        value={message.msg}
                        onChange={handleChange}
                        required
                      ></textarea>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              {!loader ? (
                <button
                  type="button"
                  className="btn btn-primary px-4"
                  onClick={handleSubmit}
                  id="message-button"
                >
                  {t("Send_message")}
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary px-4"
                  id="message-button"
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageModal;
