import React from "react";
import UserProfileSkeleton from "../../../../components/SkeletonLoader/UserProfileSkeleton";
import { Capitalize } from "../../../../components/helpers/functionHelpers";

const SellersHeader = ({ user, handleClick, title, userLoading }) => {
  return (
    <>
      {" "}
      <div className={"menuu-icon"} onClick={handleClick}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-6 h-6"
        >
          <path
            fillRule="evenodd"
            d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
            clipRule="evenodd"
          />
        </svg>
      </div>
      {userLoading ? (
        <UserProfileSkeleton />
      ) : (
        <header className="header">
          <div className="header__message me-5">
            <h2>{title}</h2>
          </div>
          <div className="header__search"></div>

          <div className="user-area ms-auto">
            {user ? (
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0 user-area-art">
                  {" "}
                  {user.firstName && user.firstName.charAt(0).toUpperCase()}
                </div>
                <div className="flex-grow-1 ms-2">
                  {user.firstName &&
                  user.firstName.length + " " + user.LastName.length > 15 ? (
                    <p>{Capitalize(user.firstName).slice(0, 15)}...</p>
                  ) : (
                    <p>
                      {user.firstName && Capitalize(user.firstName)}{" "}
                      {user.LastName}{" "}
                    </p>
                  )}
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </header>
      )}
    </>
  );
};

export default SellersHeader;
