import { axiosInstance } from "../baseUrl";

export const useSendOrderInvoiceAsEmail = () => {
  const handleSendInvoice = async (invoiceDetails) => {
    try {
      await axiosInstance.post(`/ali-express/order/message`, invoiceDetails);
      localStorage.removeItem("invoiceDetails");
    } catch (error) {
      console.log("error sending invoice", error);
    }
  };
  return {
    handleSendInvoice,
  };
};
