import { axiosInstance } from "../baseUrl";

export const useUpdateOrderPayment = () => {
  const handleOrderPaymentUpdate = async (
    paymentService,
    transactionId,
    status,
    orderIds
  ) => {
    try {
      const payload = {
        paymentOption: paymentService,
        referenceNumber: transactionId,
        paymentStatus: status,
        metaData: "",
        orderIds,
      };
      const data = await axiosInstance.post(`/pay/order-payment`, payload);

      console.log("data updating payment", data);
    } catch (error) {
      console.log("error updating order payment", error);
    }
  };

  return { handleOrderPaymentUpdate };
};
