import { useState } from "react";

import toast from "react-hot-toast";

import { axiosInstance } from "../baseUrl";
import { toastOptions } from "../helpers/functionHelpers";

export const useGetPaymentFee = () => {
  const [isLoad, setIsLoading] = useState(true);
  const [paymentFee, setPaymentFee] = useState(0);
  const [totalCost, setTotalCost] = useState(undefined);

  const getPaymentFee = async (currency, amount, paymentoption) => {
    if (!amount || !currency || !paymentoption) {
      toast.error("Contact support for assistance", toastOptions);
      return;
    }
    try {
      const payload = {
        amount,
        currency,
        paymentoption,
      };
      const { data } = await axiosInstance.post(`/transact-pay/fee`, payload);
      setPaymentFee(data?.data?.data?.fee);
      setTotalCost(data?.data?.data?.totalChargedAmount);
    } catch (error) {
      console.log("error getting transactpay payment fee", error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    paymentFee,
    isLoad,
    getPaymentFee,
    totalCost,
  };
};
