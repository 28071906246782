import React from "react";

import { Link } from "react-router-dom";

import { IoIosArrowForward } from "react-icons/io";

const CategoryDropdown = ({ allCategories }) => {
  return (
    <div className="category__dropdown__ctn">
      {allCategories &&
        allCategories?.map((category, index) => (
          <Link
            to={`/category/${category.id}`}
            key={index}
            className="category__dropdown__content"
          >
            <div className="category__dropdown__wrap">
              <img src={category.icon} alt={category.category} />
              <p>{category.category}</p>
            </div>

            <span>
              <IoIosArrowForward />
            </span>
          </Link>
        ))}
    </div>
  );
};

export default CategoryDropdown;
