import React, { useState, useMemo, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./Authentication.css";
import { axiosInstance } from "../../components/baseUrl.jsx";
import SiteLogo from "../../assets/img/logo.png";
import toast, { Toaster } from "react-hot-toast";
import { GlobalContext } from "../../components/utils/GlobalState";
import { useTranslation } from "react-i18next";
import { useEventTag } from "../../components/hooks/useEventTag.js";

const Login = () => {
  const { t } = useTranslation();
  const { setUser } = useContext(GlobalContext);
  const { handleEventTag } = useEventTag();
  const [inputType, setInputType] = useState("password");
  const { userId, token } = useParams();
  const [error, setError] = useState({});
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loginDetail, setLoginDetail] = useState({
    email: "",
    password: "",
  });

  const handlePasswordToggle = (e) => {
    inputType === "password" ? setInputType("text") : setInputType("password");
  };

  useMemo(() => {
    (async () => {
      if (userId && token) {
        await axiosInstance.post(`/auth/verify-email`, {
          userId,
          token,
        });
        toast.success("Your email has been successfully verified.", {
          duration: 4000,
          style: {
            background: "#353434",
            color: "#fff",
          },
        });
      }
    })();
  }, [userId, token]);

  const handleChange = (e) => {
    setLoginDetail({ ...loginDetail, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const loginDetails = {
        email: loginDetail.email,
        password: loginDetail.password,
      };
      const {
        data: { data },
      } = await axiosInstance.post(`/auth/signin-user`, loginDetails);

      setUser(data?.user);
      localStorage.setItem("user", true);
      localStorage.setItem("token", data?.token);

      handleEventTag(`login`, data.user.id);

      if (data?.user?.role === "SELLER" && data?.user?.Bank?.length < 1) {
        navigate("/seller/settings");
      } else if (data.user.role === "SELLER") {
        navigate("/seller/dashboard");
      } else {
        navigate("/");
      }
      setLoading(false);
    } catch (err) {
      console.log("error from login", err);
      setLoading(false);
      if (!err.response.data.errors) {
        handleEventTag(`e_login`);
        return navigate(`/no-connection`);
      }
      if (err.response.data.errors[0].field) {
        setError(
          err.response.data.errors.reduce(function (obj, err) {
            obj[err.field] = err.message;
            handleEventTag(`e_login_${obj}`);
            return obj;
          }, {})
        );
      } else {
        setError(err.response.data.errors[0]);
        handleEventTag(`e_login_${err.response.data.errors[0]}`);
      }
    }
  };

  return (
    <>
      <div>
        <Toaster />
        <section className="auth">
          <div className="auth-lhs">
            <div className="auth-lhs-header">
              <Link to="/">
                <img className="site-logo" src={SiteLogo} alt="" />
              </Link>
            </div>
          </div>
          <div className="auth-rhs">
            <div className="auth-rhs-header d-flex justify-content-between">
              <img className="site-logo mobile-only" src={SiteLogo} alt="" />
              <p>
                {t("Dont_have_an_account")}?{" "}
                <Link to="/registration" className="user-link">
                  {t("Register")}
                </Link>
              </p>
            </div>
            <div className="auth-rhs-content d-flex align-items-center">
              <div className="rhs-inner">
                <h2 className="mb-4">{t("Login_to_continue")}</h2>
                <div className="auth-account-wrap">
                  <form className="auth-form" onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                      >
                        {t("Email_address")}
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        name="email"
                        value={loginDetail.email}
                        onChange={handleChange}
                      />
                      <p className="errors">{error.email}</p>
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputPassword1"
                        className="form-label"
                      >
                        {t("Password")}
                      </label>
                      <div className="form-control passwordToggle">
                        <input
                          type={inputType}
                          id="exampleInputPassword1"
                          name="password"
                          value={loginDetail.password}
                          onChange={handleChange}
                        />
                        <span
                          className={"password-icon"}
                          onClick={handlePasswordToggle}
                        >
                          {inputType === "password" ? (
                            <i className="far fa-eye-slash mt-1 pt-1"></i>
                          ) : (
                            <i className="far fa-eye mt-1 pt-1"></i>
                          )}
                        </span>
                      </div>
                      <p className="errors">{error.password}</p>
                      <p className="errors">{error.message}</p>
                    </div>
                    <div className="mb-3 form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck1"
                      />
                      <div className="d-flex justify-content-between">
                        <label
                          className="form-check-label"
                          htmlFor="exampleCheck1"
                        >
                          {t("Remember_me")}
                        </label>
                        <span className="forgot-pwd">
                          <Link to="/forgot-password" className="user-link-doc">
                            {t("Forgot_Password")}?
                          </Link>
                        </span>
                      </div>
                    </div>
                    {loading ? (
                      <button type="submit" className="btn btn-danger">
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </button>
                    ) : (
                      <button type="submit" className="btn btn-danger">
                        {t("Login")}
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Login;
