import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const ReviewLoader = () => {
  return (
    <div className="product-review-details-wrapper gap-5">
      <h2>
        {" "}
        <Skeleton height={20} />
      </h2>
      <div className="product-review-details-ctn">
        <div className="product-review-details-product-ctn">
          <div className="product-review-details-product-name">
            {" "}
            <Skeleton height={100} />
          </div>
        </div>
        <div className="product-review-details-user-wrapper">
          <div className="product-review-details-user-content">
            <div className="reviews-star-ctn">
              <Skeleton height={20} />
            </div>
            <p>
              {" "}
              <Skeleton height={20} />
            </p>

            <p>
              {" "}
              <Skeleton height={20} />
            </p>

            <div className="review-date-ctn">
              <h4>
                <Skeleton height={20} />
              </h4>
              <p>
                <Skeleton height={20} />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="product-review-details-ctn">
        <div className="product-review-details-product-ctn">
          <div className="product-review-details-product-name">
            {" "}
            <Skeleton height={100} />
          </div>
        </div>
        <div className="product-review-details-user-wrapper">
          <div className="product-review-details-user-content">
            <div className="reviews-star-ctn">
              <Skeleton height={20} />
            </div>
            <p>
              {" "}
              <Skeleton height={20} />
            </p>

            <p>
              {" "}
              <Skeleton height={20} />
            </p>

            <div className="review-date-ctn">
              <h4>
                <Skeleton height={20} />
              </h4>
              <p>
                <Skeleton height={20} />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="product-review-details-ctn">
        <div className="product-review-details-product-ctn">
          <div className="product-review-details-product-name">
            {" "}
            <Skeleton height={100} />
          </div>
        </div>
        <div className="product-review-details-user-wrapper">
          <div className="product-review-details-user-content">
            <div className="reviews-star-ctn">
              <Skeleton height={20} />
            </div>
            <p>
              {" "}
              <Skeleton height={20} />
            </p>

            <p>
              {" "}
              <Skeleton height={20} />
            </p>

            <div className="review-date-ctn">
              <h4>
                <Skeleton height={20} />
              </h4>
              <p>
                <Skeleton height={20} />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="product-review-details-ctn">
        <div className="product-review-details-product-ctn">
          <div className="product-review-details-product-name">
            {" "}
            <Skeleton height={100} />
          </div>
        </div>
        <div className="product-review-details-user-wrapper">
          <div className="product-review-details-user-content">
            <div className="reviews-star-ctn">
              <Skeleton height={20} />
            </div>
            <p>
              {" "}
              <Skeleton height={20} />
            </p>

            <p>
              {" "}
              <Skeleton height={20} />
            </p>

            <div className="review-date-ctn">
              <h4>
                <Skeleton height={20} />
              </h4>
              <p>
                <Skeleton height={20} />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="product-review-details-ctn">
        <div className="product-review-details-product-ctn">
          <div className="product-review-details-product-name">
            {" "}
            <Skeleton height={100} />
          </div>
        </div>
        <div className="product-review-details-user-wrapper">
          <div className="product-review-details-user-content">
            <div className="reviews-star-ctn">
              <Skeleton height={20} />
            </div>
            <p>
              {" "}
              <Skeleton height={20} />
            </p>

            <p>
              {" "}
              <Skeleton height={20} />
            </p>

            <div className="review-date-ctn">
              <h4>
                <Skeleton height={20} />
              </h4>
              <p>
                <Skeleton height={20} />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewLoader;
