import React from "react";
import { Link } from "react-router-dom";
import "./Internet.css";
import logo from "./../../assets/img/logo.png";
import { useTranslation } from "react-i18next";

const Internet = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="p-4 logo">
        <Link to="/">
          <img src={logo} alt="TOFA" />
        </Link>
      </div>
      <div className="internet-ctn">
        <h1>{t("NO_INTERNET_CONNECTION_OR_SERVER_DOWN")}</h1>
        <p>{t("Check_your_internet")}</p>

        <div>
          <Link to="/">
            <button className="request-quotebtn">{t("Return_home")}</button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Internet;
