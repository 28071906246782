import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const CardOverviewLoader = () => {
  return (
    <div>
      {" "}
      <div className="main-overview">
        <div className="overview-card">
          {" "}
          <Skeleton height={100} />
        </div>
        <div className="overview-card">
          {" "}
          <Skeleton height={100} />
        </div>
        <div className="overview-card">
          {" "}
          <Skeleton height={100} />
        </div>
      </div>
    </div>
  );
};

export default CardOverviewLoader;
