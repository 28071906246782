import React from "react";
import { useTranslation } from "react-i18next";

const EditShipping = ({ userDetails, setUserDetails }) => {
  const { t } = useTranslation();
  const handleChange = (e) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  };
  return (
    <>
      {" "}
      <div
        className="modal fade"
        id="exampleModal2"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {t("Update_delivery_details")}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form className="shipping-form">
                <div className="row">
                  <div className="col-12">
                    <div className="address-ctn">
                      <label>{t("Type_of_delivery")} </label>
                      <select
                        name="shippingType"
                        value={userDetails.shippingType}
                        onChange={handleChange}
                      >
                        <option defaultValue>{t("Select")}</option>
                        <option value="LOCAL">{t("Local")}</option>
                        <option value="FOREIGN">{t("Foreign")}</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="address-ctn">
                      <label>Incoterm</label>
                      {userDetails.shippingType === "LOCAL" ? (
                        <select
                          name="incoterm"
                          value={userDetails.incoterm}
                          onChange={handleChange}
                        >
                          <option defaultValue>Select</option>{" "}
                          <option value="LOCAL_DELIVERY">{t("Local_Delivery")}</option>
                        </select>
                      ) : (
                        <select
                          name="incoterm"
                          value={userDetails.incoterm}
                          onChange={handleChange}
                        >
                          <option defaultValue>{t("Select")}</option>{" "}
                          <option value="FOB">FOB</option>
                          <option value="CIF">CIF</option>
                          <option value="CFR">CFR</option>
                        </select>
                      )}
                    </div>
                  </div>
                  {userDetails.shippingType !== "LOCAL" &&
                  userDetails.incoterm !== "LOCAL_DELIVERY" ? (
                    <div className="col-12">
                      <div className="address-ctn">
                        <label>{t("Port")}</label>
                        <input
                          type="text"
                          name="port"
                          value={userDetails.port}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Ok
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditShipping;
