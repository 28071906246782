import { axiosInstance } from "../baseUrl";
import toast from "react-hot-toast";
import { useContext } from "react";
import { GlobalContext } from "../utils/GlobalState";

export const useDeleteBulkProductCart = () => {
  const { setRerender } = useContext(GlobalContext);

  const handleDelete = async (cart) => {
    for (let i = 0; i < cart.length; i++) {
      const productId = cart[i].id;
      try {
        await axiosInstance.delete(`/cart/delete-item/${productId}`);
        setRerender((prev) => !prev);
      } catch (error) {
        console.log(error);
        toast.error(`${error.response.data.errors[0].message}`, {
          duration: 4000,
          style: {
            background: "#353434",
            color: "#fff",
          },
        });
      }
    }
  };
  return {
    handleDelete,
  };
};
