import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const CategorySkeleton = () => {
  return (
    <div className="main-container">
      <div className="cat-wrap">
        <div className="product-card">
          <div className="product-card-img">
            <Skeleton height={100} />
          </div>
        </div>
        <div className="product-card">
          <div className="product-card-img">
            <Skeleton height={100} />
          </div>
        </div>
        <div className="product-card">
          <div className="product-card-img">
            <Skeleton height={100} />
          </div>
        </div>
        <div className="product-card">
          <div className="product-card-img">
            <Skeleton height={100} />
          </div>
        </div>
        <div className="product-card">
          <div className="product-card-img">
            <Skeleton height={100} />
          </div>
        </div>
        <div className="product-card">
          <div className="product-card-img">
            <Skeleton height={100} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategorySkeleton;
