import React from "react";
import { useTranslation } from "react-i18next";

const PaymentMethod = ({ paymentTerm }) => {
  const { t } = useTranslation();

  return (
    <>
      {" "}
      <div className="col-12">
        <div className="over-card user-profile-ctn">
          <h5>{t("Payment_method")}</h5>
          <div className="user-details-ctn">
            <div className="user-ctn">
              <div className="user-address">
                <p>{paymentTerm ? paymentTerm : "Select mode of payment"}</p>
              </div>
            </div>
            <div className="user-ctn">
              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal1"
              >
                {t("Change")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentMethod;
