import React from "react";
import { convertPrice } from "../../components/helpers/convertPrice";
import { useTranslation } from "react-i18next";

function MultipleRfqModal({
  rfqInfo,
  Capitalize,
  numberWithCommas,
  convertDateFormat,
  rfqInfoLoad,
  selectedCurrency,
}) {
  const { t } = useTranslation();

  return (
    <>
      <div
        className="modal fade"
        id="exampleModalToggle"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabIndex="-1"
      >
        {rfqInfoLoad ? (
          <div className="gooeey">
            <span className="dot"></span>
            <div className="dots">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        ) : (
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content p-2">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalToggleLabel">
                  {rfqInfo.productName && Capitalize(rfqInfo.productName)}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body p-1">
                <div className="container">
                  <div className="rfq-modal">
                    <div className="d-flex justify-content-start">
                      <h5>{t("Quantity")}:</h5>
                      <p className="px-3">
                        {rfqInfo.quantityRequired &&
                          numberWithCommas(rfqInfo.quantityRequired)}
                      </p>
                    </div>
                    <div className="d-flex justify-content-start">
                      <h5>{t("Country_of_destination")}:</h5>
                      <p className="px-3">
                        {rfqInfo.destinationPort &&
                          Capitalize(rfqInfo.destinationPort)}
                      </p>
                    </div>

                    <div className="d-flex justify-content-start">
                      <h5>{t("Target_price")}:</h5>
                      <p className="px-3">
                        {selectedCurrency}
                        <span
                          style={{ fontWeight: "bold", paddingLeft: "5px" }}
                        >
                          {" "}
                          {rfqInfo.targetPrice &&
                            convertPrice(rfqInfo.targetPrice, selectedCurrency)}
                        </span>
                      </p>
                    </div>
                    <div className="d-flex justify-content-start">
                      <h5>{t("Payment_term")}:</h5>
                      <p className="px-3">{rfqInfo && rfqInfo.paymentTerms}</p>
                    </div>
                    <div className="d-flex justify-content-start">
                      <h5>{t("Shipping_term")}:</h5>
                      <p className="px-3">{rfqInfo && rfqInfo.termsOfTrade}</p>
                    </div>
                    <div className="d-flex justify-content-start">
                      <h5>{t("Date_created")}:</h5>
                      <p className="px-3">
                        {rfqInfo.createdAt &&
                          convertDateFormat(rfqInfo.createdAt)}
                      </p>
                    </div>

                    <h5>{t("Requirements")}:</h5>
                    <p>{rfqInfo && rfqInfo.productDescription}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default MultipleRfqModal;
