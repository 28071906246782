import React, { useContext, useState } from "react";
import { numberWithCommas } from "../../components/helpers/functionHelpers";
import { CurrencyExchangeContext } from "../../components/utils/CurrencyExchangeState";
import { useTranslation } from "react-i18next";

const AliexpressCartItems = ({
  cart,
  removeFromCart,
  addToCart,
  currentSelectedCurrency,
}) => {
  const { t } = useTranslation();
  const { convertCurrency } = useContext(CurrencyExchangeContext);

  const selectProductQuantity = [1, 2, 3, 4];

  const [show, setShow] = useState("");
  const [qtyInput, setQtyInput] = useState("");
  const [showQuantity, setShowQuantity] = useState(false);
  const [quantityInput, setQuantityInput] = useState(false);

  const exchangeCurrency = (cost, quanity, productCurrency) => {
    const totalCost = cost * quanity;

    return convertCurrency(totalCost, productCurrency, currentSelectedCurrency);
  };

  return (
    <>
      {" "}
      {cart && cart.length > 0 && (
        <div className="col-12">
          <div className="over-card">
            <h2 className="user__title">Products from Aliexpress</h2>
            <div className="user-product-ctn">
              {cart?.map((product, index) => (
                <div className="user-product-wrap" key={index}>
                  <div className="user-product-details">
                    <div className="user-product-img">
                      <img src={product?.imgUrl} alt="product" />
                    </div>
                    {product?.shipping && (
                      <>
                        {product?.shipping !==
                          "DELIVERY NOT AVAILABLE TO YOUR ADDRESS" && (
                          <>
                            <p>{t("Estimated_delivery")}</p>
                            <p>
                              {product?.minDeliveryDuration} days -{" "}
                              {product?.maxDeliveryDuration} days
                            </p>
                          </>
                        )}
                      </>
                    )}
                  </div>
                  <div className="user-product-name">
                    <div className="product-name">
                      <p>{product?.productName}</p>
                    </div>
                    <div className="product-amount">
                      <p>
                        {currentSelectedCurrency}{" "}
                        {numberWithCommas(
                          exchangeCurrency(
                            product?.cost,
                            product?.quantity,
                            product?.currency
                          )
                        )}
                      </p>

                      {/* <div className="product-count-aliexpress">
                        <button
                          className="negative"
                          onClick={() => removeFromCart(product?.productId)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="size-6"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4.25 12a.75.75 0 0 1 .75-.75h14a.75.75 0 0 1 0 1.5H5a.75.75 0 0 1-.75-.75Z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                        <h2 className="quanity">{product?.quantity}</h2>
                        <button
                          className="positive"
                          onClick={() => addToCart(product)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="size-6"
                          >
                            <path
                              fillRule="evenodd"
                              d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                      </div> */}
                      <div className="product-count">
                        {quantityInput && show === product?.productId ? (
                          <div className="qty-input-ctn">
                            <input
                              type="number"
                              name="qtyInput"
                              value={qtyInput}
                              onChange={(e) => setQtyInput(e.target.value)}
                            />
                            <button
                              onClick={() => {
                                addToCart(product, qtyInput);
                                setShow("'");
                              }}
                            >
                              {t("Update")}
                            </button>
                          </div>
                        ) : (
                          <div
                            className="cttn"
                            onClick={() =>
                              setShowQuantity(
                                showQuantity === product?.productId
                                  ? null
                                  : product?.productId
                              )
                            }
                          >
                            <p>Qty: {product.quantity && product.quantity} </p>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                              />
                            </svg>
                            {showQuantity === product?.productId && (
                              <ul className="qty-dropdown">
                                <li
                                  onClick={() =>
                                    removeFromCart(product?.productId)
                                  }
                                >
                                  0 ({t("Delete")})
                                </li>
                                {selectProductQuantity.map((qty) => (
                                  <li
                                    onClick={() => {
                                      addToCart(product, qty);
                                    }}
                                    key={qty}
                                  >
                                    {qty}
                                  </li>
                                ))}
                                <li
                                  onClick={(e) => {
                                    setShow(product?.productId);
                                    setQuantityInput(true);
                                  }}
                                >
                                  5+
                                </li>
                              </ul>
                            )}
                          </div>
                        )}
                        <p onClick={() => removeFromCart(product?.productId)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                            />
                          </svg>
                        </p>
                      </div>
                    </div>
                    {!product?.shipping ? (
                      <div className="product-name">
                        <p className="text-danger">
                          ENTER ADDRESS TO GET SHIPPING DETAILS
                        </p>
                      </div>
                    ) : (
                      <>
                        {product?.shipping ===
                        "DELIVERY NOT AVAILABLE TO YOUR ADDRESS" ? (
                          <div className="product-name">
                            <p className="text-danger">
                              DELIVERY FOR THIS PRODUCT NOT AVAILABLE TO YOUR
                              ADDRESS
                            </p>
                          </div>
                        ) : (
                          <>
                            {product?.freeShipping ? (
                              <div className="product-name">
                                <p className="text-success">FREE SHIPPING</p>
                              </div>
                            ) : (
                              <>
                                <div className="product-name">
                                  <p>SHIPPING FEE</p>
                                </div>
                                <div className="product-amount">
                                  <p>
                                    {currentSelectedCurrency}{" "}
                                    {product?.shippingFee &&
                                      numberWithCommas(
                                        convertCurrency(
                                          product?.shippingFee,
                                          "USD",
                                          currentSelectedCurrency
                                        )
                                      )}
                                  </p>
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AliexpressCartItems;
