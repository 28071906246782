import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Authentication.css";
import SiteLogo from "../../assets/img/logo.png";
import { axiosInstance } from "../../components/baseUrl.jsx";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useEventTag } from "../../components/hooks/useEventTag.js";

const PhoneVerification = () => {
  const { t } = useTranslation();
  const [userData, setUserData] = useState({});
  const [pinId, setPinId] = useState("");
  const [verificationCode, setVerificationCode] = useState({
    code: "",
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { handleEventTag } = useEventTag();

  useEffect(() => {
    const localUserData = JSON.parse(localStorage.getItem("userData"));
    const pinId = localStorage.getItem("pinId");
    setPinId(pinId);
    setUserData(localUserData);
  }, []);

  const handleChange = (e) => {
    setVerificationCode({
      ...verificationCode,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let toastId = "";
    toastId = toast.loading("loading...", {
      style: {
        background: "#353434",
        color: "#fff",
      },
    });
    try {
      const phoneNumberVerification = {
        phoneNumber: userData.phoneNumber,
        code: verificationCode.code,
        userId: userData.id,
        pinId,
      };
      await axiosInstance.post(
        `/auth/verify-phonenumber`,
        phoneNumberVerification
      );
      setLoading(false);
      toast.success("Your phone number has been successfully verified.", {
        id: toastId,
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
      handleEventTag(`phone_verification`, userData.id);
      localStorage.removeItem("userData");
      localStorage.removeItem("pinId");
      setTimeout(() => {
        navigate(`/verify-email/${userData.email}`);
      }, 4500);
    } catch (error) {
      setLoading(false);
      console.log(error);
      if (!error.response.data.errors) {
        handleEventTag(`e_phone_verification`);
        return navigate(`/no-connection`);
      }
      handleEventTag(
        `e_phone_verification_${error.response.data.errors[0].message}`
      );
      toast.error(`${error.response.data.errors[0].message}`, {
        id: toastId,
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
    }
  };

  const handleResendCode = async () => {
    let toastId = "";
    toastId = toast.loading("loading...", {
      style: {
        background: "#353434",
        color: "#fff",
      },
    });
    try {
      await axiosInstance.post(`/auth/resend-phone-verification`, {
        phoneNumber: userData.phoneNumber,
      });
      toast.success(
        `A verification code has been resent to ${userData.phoneNumber}`,
        {
          id: toastId,
          duration: 4000,
          style: {
            background: "#353434",
            color: "#fff",
          },
        }
      );
      handleEventTag(`resend_verifiaction_code`, userData.id);
    } catch (error) {
      console.log(error);
      if (!error.response.data.errors) {
        handleEventTag(`e_resend_verifiaction_code`, userData.id);
        return navigate(`/no-connection`);
      }
      handleEventTag(
        `e_resend_verifiaction_code_${error.response.data.errors[0].message}`,
        userData.id
      );
      toast.error(`${error.response.data.errors[0].message}`, {
        id: toastId,
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
    }
  };

  return (
    <>
      <div>
        <Toaster />
        <section className="auth">
          <div className="auth-lhs">
            <div className="auth-lhs-header">
              <Link to="/">
                <img className="site-logo" src={SiteLogo} alt="" />
              </Link>
            </div>
          </div>
          <div className="auth-rhs">
            <div className="auth-rhs-header d-flex justify-content-between">
              <img className="site-logo mobile-only" src={SiteLogo} alt="" />
              <p>
                <Link to="/support">
                  <i className="fas fa-info-circle support-icon me-2">
                    {" "}
                    {t("Customer_Support")}
                  </i>
                </Link>
              </p>
            </div>
            <div className="auth-rhs-content d-flex align-items-center">
              <div className="rhs-inner">
                <h2>
                  <span>{t("Welcome")}</span> {userData && userData.firstName}{" "}
                  {userData && userData.LastName}
                </h2>
                <h3>{t("Verify_your_phone_number")}</h3>
                <p className="phone-v-inst">
                {t("Please_enter")}
                  <br />
                  <span>{userData && userData.phoneNumber}</span>
                </p>
                <div className="auth-account-wrap">
                  <form className="auth-form" onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <input
                        type="number"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Enter 6 Digit Code"
                        name="code"
                        onChange={handleChange}
                        value={verificationCode.code}
                      />
                    </div>
                    {loading ? (
                      <button type="submit" className="btn btn-danger">
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </button>
                    ) : (
                      <button type="submit" className="btn btn-danger">
                        {t("Continue")}
                      </button>
                    )}

                    <p>
                      {t("Didn't_get_a_code?")}{" "}
                      <Link
                        className="user-link "
                        onClick={() => handleResendCode(userData.phoneNumber)}
                      >
                        {t("Resend_code")}
                      </Link>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default PhoneVerification;
