import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../components/baseUrl";

import toast, { Toaster } from "react-hot-toast";
import { useContext } from "react";
import { GlobalContext } from "../../components/utils/GlobalState";
import cookies from "js-cookie";
import { convertToDollars } from "../../components/helpers/convertToDollars";
import { Modal } from "antd";
import { useEventTag } from "../../components/hooks/useEventTag";

const CreateMultipleRfq = ({ isModalOpen, handleOk, setIsModalOpen }) => {
  const { t } = useTranslation();
  const { user } = useContext(GlobalContext);
  const navigate = useNavigate();
  const { handleEventTag } = useEventTag();

  const [loader, setLoader] = useState(false);
  const selectedCurrency = cookies.get("currency") || "NGN";
  const [inquiry, setInquiry] = useState({
    productName: "",
    productDescription: "",
    quantityRequired: "1",
    termsOfTrade: "",
    paymentTerms: "",
    unit: "",
    price: "",
    destinationPort: "",
  });

  const handleChange = (e) => {
    e.preventDefault();
    setInquiry({
      ...inquiry,
      [e.target.name]: e.target.value,
    });
  };

  const handleInquirySubmit = async (e) => {
    e.preventDefault();
    let toastId = "";
    toastId = toast.loading("loading...", {
      style: {
        background: "#353434",
        color: "#fff",
      },
    });
    setLoader(true);
    try {
      const createInquiry = {
        userId: user.id,
        productName: inquiry.productName,
        productDescription: inquiry.productDescription,
        quantityRequired: inquiry.quantityRequired,
        destinationPort: inquiry.destinationPort,
        termsOfTrade: inquiry.termsOfTrade,
        paymentTerms: inquiry.paymentTerms,
        targetPrice: convertToDollars(inquiry.price, selectedCurrency),
        unit: inquiry.unit,
      };
      await axiosInstance.post("/multiple-rfq", createInquiry);
      setLoader(false);
      setInquiry({
        productName: "",
        productDescription: "",
        quantityRequired: "1",
        termsOfTrade: "",
        paymentTerms: "",
        unit: "",
        price: "",
        destinationPort: "",
      });
      toast.success("You have successfully created an RFQ.", {
        id: toastId,
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
      handleEventTag(`Created RFQ`, user.id);
      setIsModalOpen(false);
      navigate("/rfq/success-message");
    } catch (err) {
      setLoader(false);
      console.log(err);
      if (!err.response.data.errors) {
        return navigate(`/no-connection`);
      }
      handleEventTag(`re_${err.response.data.errors[0].message}`, user.id);
      toast.error(`${err.response.data.errors[0].message}`, {
        id: toastId,
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
    }
  };

  return (
    <div>
      <Toaster />
      <Modal
        title=" Product Inquiry"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleOk}
        width={850}
        style={{
          top: 20,
        }}
      >
        <div className="p-4">
          <div className="row">
            <div className="col-lg-12">
              <form className="w-100" onSubmit={handleInquirySubmit}>
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1">
                    {t("Product_Name")}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id=""
                    placeholder="Enter Product Name"
                    name="productName"
                    value={inquiry.productName}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1">
                    {t("Product_Requirements")}
                  </label>
                  <textarea
                    className="form-control"
                    id=""
                    rows="3"
                    placeholder="Enter product requirements"
                    name="productDescription"
                    value={inquiry.productDescription}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>

                <div className="row">
                  <div className="col-lg-6 mb-3">
                    <label htmlFor="exampleInputPassword1">
                      {t("Quantity")}
                    </label>
                    <div className="custom-input form-control">
                      <div className="row">
                        <div className="col-lg-7 col">
                          <input
                            type="number"
                            className="form-control custom-style"
                            id=""
                            placeholder="Enter quantity"
                            name="quantityRequired"
                            value={inquiry.quantityRequired}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="col-lg-5 col">
                          <select
                            className="form-select "
                            aria-label="Default select example"
                            name="unit"
                            value={inquiry.unit}
                            onChange={handleChange}
                            placeholder="enter unit"
                            style={{ border: "none" }}
                          >
                            <option defaultValue="selected">
                              {t("select_unit")}{" "}
                            </option>
                            <option value="Acre(s)">Acre</option>
                            <option value="Ampere(s)">Ampere</option>
                            <option value="Bag(s)">Bags</option>
                            <option value="Barrel(s)">Barrel</option>
                            <option value="Box(es)">Boxes</option>
                            <option value="Bundle(s)">Bundle</option>
                            <option value="Bushel">Bushel</option>
                            <option value="Carat(s)">Carat</option>
                            <option value="Carton(s)">Carton</option>
                            <option value="Case">Case</option>
                            <option value="Centimeter(s)">Centimeter</option>
                            <option value="Chain">Chain</option>
                            <option value="Container(s)">Container</option>
                            <option value="Cubic Foot(s)">Cubic Foot</option>
                            <option value="Cubic Inch(s)">Cubic Inch</option>
                            <option value="Cubic Meter(s)">Cubic Meter</option>
                            <option value="Cubic Yard(s)">Cubic Yard</option>
                            <option value="Dozen(s)">Dozen</option>
                            <option value="Dram(s)">Dram</option>
                            <option value="Fluid Ounce(s)">Fluid Ounce</option>
                            <option value="Foot">Foot</option>
                            <option value="Gallon(s)">Gallon</option>
                            <option value="Gill(s)">Gill</option>
                            <option value="Grain(s)">Grains</option>
                            <option value="Gram(s)">Gram</option>
                            <option value="Hectare(s)">Hectare</option>
                            <option value="Hertz">Hertz</option>
                            <option value="Inch(s)">Inch</option>
                            <option value="Kiloampere(s)">Kiloampere</option>
                            <option value="Kilogram(s)">Kilogram</option>
                            <option value="Kilohertz">Kilohertz</option>
                            <option value="Kilometer(s)">Kilometer</option>
                            <option value="Kiloohm(s)">Kiloohm</option>
                            <option value="Kilovolt(s)">Kilovolt</option>
                            <option value="Kilowatt(s)">Kilowatt</option>
                            <option value="Liter(s)">Liter</option>
                            <option value="Long Ton(s)">Long Ton</option>
                            <option value="Megahertz">Megahertz</option>
                            <option value="Meter(s)">Meter</option>
                            <option value="Metric Ton(s)">Metric Ton</option>
                            <option value="Mile(s)">Mile</option>
                            <option value="Milliampere(s)">Milliampere</option>
                            <option value="Milligram(s)">Milligram</option>
                            <option value="Millihertz">Millihertz</option>
                            <option value="Milliliter(s)">Milliliter</option>
                            <option value="Millimeter(s)">Millimeter </option>
                            <option value="Milliohm(s)">Milliohm</option>
                            <option value="Millivolt(s)">Millivolt</option>
                            <option value="Milliwatt(s)">Milliwatt</option>
                            <option value="Ohm(s)">Ohm</option>
                            <option value="Ounce(s)">Ounce</option>
                            <option value="Pack(s)">Pack</option>
                            <option value="Pair(s)">Pairs</option>
                            <option value="Pallet(s)">Pallet</option>
                            <option value="Parcel(s)">Parcel</option>
                            <option value="Perch(s)">Perch</option>
                            <option value="Piece(s)">Piece</option>
                            <option value="Pint(s)">Pint</option>
                            <option value="Plant(s)">Plant</option>
                            <option value="Pole(s)">Pole</option>
                            <option value="Pound(s)">Pound</option>
                            <option value="Quart(s)">Quart</option>
                            <option value="Quarter(s)">Quarter</option>
                            <option value="Ream(s)">Reams</option>
                            <option value="Rod(s)">Rod</option>
                            <option value="Roll(s)">Rolls</option>
                            <option value="Set(s)">Sets</option>
                            <option value="Sheet(s)">Sheet</option>
                            <option value="Short Ton(s)">Short Ton</option>
                            <option value="Square Feet(s)">Square Feet</option>
                            <option value="Square Inch(s)">Square Inch</option>
                            <option value="Square Meter(s)">
                              {t("Square_Meters")}
                            </option>
                            <option value="Square Mile(s)">Square Mile</option>
                            <option value="Square Yard(s)">Square Yard</option>
                            <option value="Stone(s)">Stone</option>
                            <option value="Strand(s)">Strand</option>
                            <option value="Tonne(s)">Tonne</option>
                            <option value="Tray(s)">Tray</option>
                            <option value="Unit(s)">Unit</option>
                            <option value="Volt(s)">Volt</option>
                            <option value="Watt(s)">Watt</option>
                            <option value="Wp(s)">Wp</option>
                            <option value="Yard(s)">Yard</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mb-3">
                    <label htmlFor="exampleInputPassword1">
                      {t("Shipping_Terms")}
                    </label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      name="termsOfTrade"
                      value={inquiry.termsOfTrade}
                      onChange={handleChange}
                    >
                      <option defaultValue="selected">
                        {t("Select_shipping_terms")}
                      </option>
                      <option value="FOB">FOB</option>
                      <option value="CIF">CIF</option>
                      <option value="CFR">CFR</option>
                      <option value="LOCAL_DELIVERY">
                        {t("Local_Delivery")}
                      </option>
                    </select>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6 mb-3">
                    <label htmlFor="exampleInputPassword1">
                      {t("Payment_Terms")}
                    </label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      name="paymentTerms"
                      value={inquiry.paymentTerms}
                      onChange={handleChange}
                    >
                      <option defaultValue="selected">
                        {t("Select_payment_terms")}
                      </option>
                      <option value="PAYNOW">{t("Pay_Now")}</option>
                      <option value="TRANSFER">{t("Transfer")}</option>
                      <option value="LC">LC</option>
                      <option value="TT">TT</option>
                    </select>
                  </div>
                  <div className="col-lg-6 mb-3">
                    <label htmlFor="exampleInputPassword1">
                      {t("Destination")}{" "}
                    </label>
                    <div className="custom-input form-control">
                      <div className="row">
                        <div className="col-lg-7 col">
                          <input
                            type="text"
                            className="form-control custom-style"
                            id=""
                            placeholder="Enter destination port"
                            name="destinationPort"
                            value={inquiry.destinationPort}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mb-3">
                    <label htmlFor="exampleInputPassword1">
                      {t("Price")} ({selectedCurrency})
                    </label>
                    <div className="custom-input form-control">
                      <div className="row">
                        <div className="col-lg-7 col">
                          <input
                            type="text"
                            className="form-control custom-style"
                            id=""
                            placeholder="Enter proposed price"
                            name="price"
                            value={inquiry.price}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {!loader ? (
                  <button className="mt-3" type="submit">
                    {t("Submit_Inquiry")}
                  </button>
                ) : (
                  <button className="mt-3" type="submit">
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </div>

    // <div
    //   className="modal fade place-order-modal "
    //   id="exampleModal"
    //   tabIndex="-1"
    //   aria-labelledby="exampleModalLabel"
    //   aria-hidden="true"
    // >
    //   <Toaster />
    //   <div className="modal-dialog modal-dialog-centered modal-lg">
    //     <div className="modal-content p-4">
    //       <div className="modal-header">
    //         <h5 className="modal-title" id="exampleModalLabel">
    //           Product Inquiry
    //         </h5>
    //         <button
    //           type="button"
    //           className="btn-close"
    //           data-bs-dismiss="modal"
    //           aria-label="Close"
    //         ></button>
    //       </div>
    //       <div className="modal-body p-4">
    //         <div className="row">
    //           <div className="col-lg-12">
    //             <form className="w-100" onSubmit={handleInquirySubmit}>
    //               <div className="mb-3">
    //                 <label htmlFor="exampleInputEmail1">Product Name</label>
    //                 <input
    //                   type="text"
    //                   className="form-control"
    //                   id=""
    //                   placeholder="Enter Product Name"
    //                   name="productName"
    //                   value={inquiry.productName}
    //                   onChange={handleChange}
    //                   required
    //                 />
    //               </div>

    //               <div className="mb-3">
    //                 <label htmlFor="exampleInputEmail1">
    //                   Product Requirements
    //                 </label>
    //                 <textarea
    //                   className="form-control"
    //                   id=""
    //                   rows="3"
    //                   placeholder="Enter product requirements"
    //                   name="productDescription"
    //                   value={inquiry.productDescription}
    //                   onChange={handleChange}
    //                   required
    //                 ></textarea>
    //               </div>

    //               <div className="row">
    //                 <div className="col-lg-6 mb-3">
    //                   <label htmlFor="exampleInputPassword1">Quantity</label>
    //                   <div className="custom-input form-control">
    //                     <div className="row">
    //                       <div className="col-lg-7 col">
    //                         <input
    //                           type="number"
    //                           className="form-control custom-style"
    //                           id=""
    //                           placeholder="Enter quantity"
    //                           name="quantityRequired"
    //                           value={inquiry.quantityRequired}
    //                           onChange={handleChange}
    //                           required
    //                         />
    //                       </div>
    //                       <div className="col-lg-5 col">
    //                         <select
    //                           className="form-select "
    //                           aria-label="Default select example"
    //                           name="unit"
    //                           value={inquiry.unit}
    //                           onChange={handleChange}
    //                           placeholder="enter unit"
    //                           style={{ border: "none" }}
    //                         >
    //                           <option defaultValue="selected">
    //                             select unit{" "}
    //                           </option>
    //                           <option value="Acre(s)">Acre</option>
    //                           <option value="Ampere(s)">Ampere</option>
    //                           <option value="Bag(s)">Bags</option>
    //                           <option value="Barrel(s)">Barrel</option>
    //                           <option value="Box(es)">Boxes</option>
    //                           <option value="Bundle(s)">Bundle</option>
    //                           <option value="Bushel">Bushel</option>
    //                           <option value="Carat(s)">Carat</option>
    //                           <option value="Carton(s)">Carton</option>
    //                           <option value="Case">Case</option>
    //                           <option value="Centimeter(s)">Centimeter</option>
    //                           <option value="Chain">Chain</option>
    //                           <option value="Container(s)">Container</option>
    //                           <option value="Cubic Foot(s)">Cubic Foot</option>
    //                           <option value="Cubic Inch(s)">Cubic Inch</option>
    //                           <option value="Cubic Meter(s)">
    //                             Cubic Meter
    //                           </option>
    //                           <option value="Cubic Yard(s)">Cubic Yard</option>
    //                           <option value="Dozen(s)">Dozen</option>
    //                           <option value="Dram(s)">Dram</option>
    //                           <option value="Fluid Ounce(s)">
    //                             Fluid Ounce
    //                           </option>
    //                           <option value="Foot">Foot</option>
    //                           <option value="Gallon(s)">Gallon</option>
    //                           <option value="Gill(s)">Gill</option>
    //                           <option value="Grain(s)">Grains</option>
    //                           <option value="Gram(s)">Gram</option>
    //                           <option value="Hectare(s)">Hectare</option>
    //                           <option value="Hertz">Hertz</option>
    //                           <option value="Inch(s)">Inch</option>
    //                           <option value="Kiloampere(s)">Kiloampere</option>
    //                           <option value="Kilogram(s)">Kilogram</option>
    //                           <option value="Kilohertz">Kilohertz</option>
    //                           <option value="Kilometer(s)">Kilometer</option>
    //                           <option value="Kiloohm(s)">Kiloohm</option>
    //                           <option value="Kilovolt(s)">Kilovolt</option>
    //                           <option value="Kilowatt(s)">Kilowatt</option>
    //                           <option value="Liter(s)">Liter</option>
    //                           <option value="Long Ton(s)">Long Ton</option>
    //                           <option value="Megahertz">Megahertz</option>
    //                           <option value="Meter(s)">Meter</option>
    //                           <option value="Metric Ton(s)">Metric Ton</option>
    //                           <option value="Mile(s)">Mile</option>
    //                           <option value="Milliampere(s)">
    //                             Milliampere
    //                           </option>
    //                           <option value="Milligram(s)">Milligram</option>
    //                           <option value="Millihertz">Millihertz</option>
    //                           <option value="Milliliter(s)">Milliliter</option>
    //                           <option value="Millimeter(s)">Millimeter </option>
    //                           <option value="Milliohm(s)">Milliohm</option>
    //                           <option value="Millivolt(s)">Millivolt</option>
    //                           <option value="Milliwatt(s)">Milliwatt</option>
    //                           <option value="Ohm(s)">Ohm</option>
    //                           <option value="Ounce(s)">Ounce</option>
    //                           <option value="Pack(s)">Pack</option>
    //                           <option value="Pair(s)">Pairs</option>
    //                           <option value="Pallet(s)">Pallet</option>
    //                           <option value="Parcel(s)">Parcel</option>
    //                           <option value="Perch(s)">Perch</option>
    //                           <option value="Piece(s)">Piece</option>
    //                           <option value="Pint(s)">Pint</option>
    //                           <option value="Plant(s)">Plant</option>
    //                           <option value="Pole(s)">Pole</option>
    //                           <option value="Pound(s)">Pound</option>
    //                           <option value="Quart(s)">Quart</option>
    //                           <option value="Quarter(s)">Quarter</option>
    //                           <option value="Ream(s)">Reams</option>
    //                           <option value="Rod(s)">Rod</option>
    //                           <option value="Roll(s)">Rolls</option>
    //                           <option value="Set(s)">Sets</option>
    //                           <option value="Sheet(s)">Sheet</option>
    //                           <option value="Short Ton(s)">Short Ton</option>
    //                           <option value="Square Feet(s)">
    //                             Square Feet
    //                           </option>
    //                           <option value="Square Inch(s)">
    //                             Square Inch
    //                           </option>
    //                           <option value="Square Meter(s)">
    //                             Square Meters
    //                           </option>
    //                           <option value="Square Mile(s)">
    //                             Square Mile
    //                           </option>
    //                           <option value="Square Yard(s)">
    //                             Square Yard
    //                           </option>
    //                           <option value="Stone(s)">Stone</option>
    //                           <option value="Strand(s)">Strand</option>
    //                           <option value="Tonne(s)">Tonne</option>
    //                           <option value="Tray(s)">Tray</option>
    //                           <option value="Unit(s)">Unit</option>
    //                           <option value="Volt(s)">Volt</option>
    //                           <option value="Watt(s)">Watt</option>
    //                           <option value="Wp(s)">Wp</option>
    //                           <option value="Yard(s)">Yard</option>
    //                         </select>
    //                       </div>
    //                     </div>
    //                   </div>
    //                 </div>
    //                 <div className="col-lg-6 mb-3">
    //                   <label htmlFor="exampleInputPassword1">
    //                     Shipping Terms
    //                   </label>
    //                   <select
    //                     className="form-select"
    //                     aria-label="Default select example"
    //                     name="termsOfTrade"
    //                     value={inquiry.termsOfTrade}
    //                     onChange={handleChange}
    //                   >
    //                     <option defaultValue="selected">
    //                       Select shipping terms
    //                     </option>
    //                     <option value="FOB">FOB</option>
    //                     <option value="CIF">CIF</option>
    //                     <option value="CFR">CFR</option>
    //                     <option value="LOCAL_DELIVERY">Local Delivery</option>
    //                   </select>
    //                 </div>
    //               </div>

    //               <div className="row">
    //                 <div className="col-lg-6 mb-3">
    //                   <label htmlFor="exampleInputPassword1">
    //                     Payment Terms
    //                   </label>
    //                   <select
    //                     className="form-select"
    //                     aria-label="Default select example"
    //                     name="paymentTerms"
    //                     value={inquiry.paymentTerms}
    //                     onChange={handleChange}
    //                   >
    //                     <option defaultValue="selected">
    //                       Select payment terms
    //                     </option>
    //                     <option value="PAYNOW">Pay Now</option>
    //                     <option value="TRANSFER">Transfer</option>
    //                     <option value="LC">LC</option>
    //                     <option value="TT">TT</option>
    //                   </select>
    //                 </div>
    //                 <div className="col-lg-6 mb-3">
    //                   <label htmlFor="exampleInputPassword1">
    //                     Destination{" "}
    //                   </label>
    //                   <div className="custom-input form-control">
    //                     <div className="row">
    //                       <div className="col-lg-7 col">
    //                         <input
    //                           type="text"
    //                           className="form-control custom-style"
    //                           id=""
    //                           placeholder="Enter destination port"
    //                           name="destinationPort"
    //                           value={inquiry.destinationPort}
    //                           onChange={handleChange}
    //                           required
    //                         />
    //                       </div>
    //                     </div>
    //                   </div>
    //                 </div>
    //                 <div className="col-lg-6 mb-3">
    //                   <label htmlFor="exampleInputPassword1">
    //                     Price ({selectedCurrency})
    //                   </label>
    //                   <div className="custom-input form-control">
    //                     <div className="row">
    //                       <div className="col-lg-7 col">
    //                         <input
    //                           type="text"
    //                           className="form-control custom-style"
    //                           id=""
    //                           placeholder="Enter proposed price"
    //                           name="price"
    //                           value={inquiry.price}
    //                           onChange={handleChange}
    //                           required
    //                         />
    //                       </div>
    //                     </div>
    //                   </div>
    //                 </div>
    //               </div>
    //               {!loader ? (
    //                 <button className="mt-3" type="submit">
    //                   Submit Inquiry
    //                 </button>
    //               ) : (
    //                 <button className="mt-3" type="submit">
    //                   <span
    //                     className="spinner-border spinner-border-sm"
    //                     role="status"
    //                     aria-hidden="true"
    //                   ></span>
    //                 </button>
    //               )}
    //             </form>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default CreateMultipleRfq;
