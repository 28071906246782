import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./ConfirmOrder.css";

import { Toaster } from "react-hot-toast";
import { useParams, Link } from "react-router-dom";
import { useSatisfyOrder } from "../../../../components/hooks/useSatisfyOrder";

import logo from "./../../../../assets/img/logo.png";
import { useCreateDispute } from "../../../../components/hooks/useCreateDispute";
import ReviewModal from "../BuyersComponent/ReviewModal";

const ConfirmOrder = () => {
  const { t } = useTranslation();
  const [satisfied, setSatisfied] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const { updateSatisfiedStatus } = useSatisfyOrder();
  const [subject, setSubject] = useState("");
  const [complaints, setComplaints] = useState("");
  const { handleSubmitDispute, disputeLoading } = useCreateDispute();

  const { userId, productId, orderId, token, status } = useParams();

  const handleSatisfaction = (isSatisfied, satisfiedStatus) => {
    setSatisfied(isSatisfied);
    updateSatisfiedStatus(orderId, satisfiedStatus);
  };

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (token) {
      localStorage.setItem("token", token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, productId, orderId, token, status]);

  return (
    <div className="bank-ctn">
      <Toaster />
      <div className="p-4 logo">
        <Link to="/">
          <img src={logo} alt="TOFA" />
        </Link>
      </div>
      {status && status === "confirmed" ? (
        <div className="satisfaction-container">
          <h1>{t("Order_Successfully_Delivered")}</h1>
          <p> {t("Thank_you_for_confirming")}</p>
          <p>
          {t("Could_you_please")}
          </p>
          <div className="button-container">
            <button
              className={`satisfied-button ${
                satisfied === true ? "selected" : ""
              }`}
              onClick={() => {
                handleSatisfaction(true, "SATISFIED");
                handleOpenModal();
              }}
            >
              {t("Satisfied")}
            </button>
            <button
              className={`unsatisfied-button ${
                satisfied === false ? "selected" : ""
              }`}
              onClick={() => {
                handleSatisfaction(false, "DISSATISFIED");
                handleOpenModal();
              }}
            >
              {t("Dissatisfied")}
            </button>
          </div>
          {/* {satisfied && (
            <p>
              Your opinion matters to us! Take a moment to drop a review and
              share your experience. Your feedback helps us improve and ensures
              we're providing the best for you. Thanks for being a part of TOFA
              Marketplace.
              <span
                className={`text-primary`}
                style={{ cursor: "pointer" }}
                onClick={handleOpenModal}
              >
                Drop a review
              </span>
            </p>
          )} */}
          {/* {satisfied === false && (
            <p>
              Your opinion matters to us! Take a moment to drop a review and
              share your experience. Your feedback helps us improve and ensures
              we're providing the best for you. Thanks for being a part of TOFA
              Marketplace.
              <span
                className={`text-primary`}
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                onClick={handleOpenModal}
              >
                Drop a review
              </span>
            </p>
          )} */}
        </div>
      ) : status && status === "deny" ? (
        <div className="satisfaction-container">
          <h1>{t("Order_Not_Recieved")}</h1>
          <p> {t("Thank_you_for_confirming")}</p>
          <p>{t("Would_you_want")}</p>
          <div className="button-container">
            <button
              className={`unsatisfied-button`}
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              {t("Initiate_dispute")}
            </button>
          </div>
        </div>
      ) : null}

      {/* Review modal */}
      <ReviewModal
        isModalOpen={isOpen}
        handleCancel={handleCloseModal}
        productId={productId}
        userId={userId}
      />

      {/* Dispute modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {t("Create_dispute")}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body mx-3">
              {status && status === "Confirmed" ? (
                <p className="mt-3">
                  {t("Not_satisfied")}
                </p>
              ) : (
                <p className="mt-3">
                  {t("Not_gotten_your_order?")}
                </p>
              )}
              <form>
                <div className="mb-3">
                  <label htmlFor="recipient-name" className="col-form-label">
                    {t("Order_no")}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="recipient-name"
                    name="subject"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="message-text" className="col-form-label">
                  {t("Dispute")}:
                  </label>
                  <textarea
                    className="form-control"
                    id="message-text"
                    rows={5}
                    name=" complaints"
                    value={complaints}
                    onChange={(e) => setComplaints(e.target.value)}
                  ></textarea>
                </div>
              </form>
            </div>
            <div className="modal-footer mx-3">
              {disputeLoading ? (
                <button type="button" className="btn btn-primary">
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) => handleSubmitDispute(e, subject, complaints)}
                >
                  {t("Submit")}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmOrder;
