import { useState } from "react";
import { axiosInstance } from "../baseUrl";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export const usePostReview = () => {
  const [createReview, setCreateReview] = useState({
    reviewRating: "",
    comment: "",
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [errors, setErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
    setCreateReview({
      ...createReview,
      [e.target.name]: e.target.value,
    });
  };

  const validateFile = (file) => {
    const maxSize = 10 * 1024 * 1024; // 10 MB in bytes
    const allowedTypes = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "video/avi",
      "video/webm",
      "video/mp4",
    ];

    let newErrors = "";

    // Check file size
    if (file.size > maxSize) {
      newErrors = "File size exceeds 10MB limit";
    }

    // Check file type
    if (!allowedTypes.includes(file.type)) {
      newErrors =
        "Invalid file type. Only PNG, JPG, JPEG, mp4, Webm, and avi files are allowed";
    }

    return newErrors;
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const newErrors = validateFile(file);

      if (newErrors.length === 0) {
        // File is valid
        setSelectedFile(file);
        setErrors("");
      } else {
        // File is invalid
        setSelectedFile(null);
        setErrors(newErrors);
      }
    }
  };

  const handleUploadReview = async (e, userId, productId) => {
    e.preventDefault();
    if (!createReview.reviewRating) {
      toast.error(`Select a review rating `, {
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
    } else if (!createReview.comment) {
      toast.error(`Enter your review comment `, {
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
    } else if (errors) {
      toast.error(`${errors} `, {
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
    } else {
      setIsLoading(true);
      try {
        const reviewUpload = {
          userId: userId,
          reviewMessage: createReview?.comment,
          productId: productId,
          starNumber: createReview?.reviewRating,
        };
        const formData = new FormData();
        formData.append("file", selectedFile);
        for (const property in reviewUpload) {
          formData.append(`${property}`, reviewUpload[property]);
        }
        await axiosInstance.post(`/reviews`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setIsLoading(false);
        setCreateReview({
          reviewRating: "",
          comment: "",
        });
        setSelectedFile(null);
        toast.success("Thanks, your review was successfully uploaded.", {
          duration: 4000,
          style: {
            background: "#353434",
            color: "#fff",
          },
        });
        setSuccess(true);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
        if (error.response.data.message) {
          toast.error(`${error.response.data.message}`, {
            duration: 4000,
            style: {
              background: "#353434",
              color: "#fff",
            },
          });
        } else if (error.response.data.Error === "Kindly login") {
          navigate("/login");
        } else if (error.response.data.Error) {
          toast.error(`${error.response.data.Error}`, {
            duration: 4000,
            style: {
              background: "#353434",
              color: "#fff",
            },
          });
        } else if (error.response.data.error) {
          toast.error(`${error.response.data.error}`, {
            duration: 4000,
            style: {
              background: "#353434",
              color: "#fff",
            },
          });
        } else if (
          !error.response.data.Error ||
          !error.response.data.error ||
          !error.response.data.message
        ) {
          return navigate(`/no-connection`);
        }
      }
    }
  };

  return {
    handleUploadReview,
    handleChange,
    isLoading,
    handleFileChange,
    createReview,
    success,
  };
};
