import React, { useState, useContext } from "react";
import { axiosInstance } from "../../../../components/baseUrl";
import { useTranslation } from "react-i18next";

import toast, { Toaster } from "react-hot-toast";

import { GlobalContext } from "../../../../components/utils/GlobalState";
import { useNavigate } from "react-router-dom";
import { convertDateFormat } from "../../../../components/helpers/convertDate";
import { convertPrice } from "../../../../components/helpers/convertPrice";
import { convertToDollars } from "../../../../components/helpers/convertToDollars";
import cookies from "js-cookie";

const SellerMultipleModal = ({ rfqInfo, numberWithCommas, loading }) => {
  const { t } = useTranslation();
  const [loader, setLoader] = useState(false);
  const { user } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [respondRfq, setRespondRfq] = useState({
    price: "",
    additionalNote: "",
  });

  const selectedCurrency = cookies.get("currency") || "NGN";

  const handleChange = (e) => {
    setRespondRfq({
      ...respondRfq,
      [e.target.name]: e.target.value,
    });
  };

  const handleRfqSubmit = async (e) => {
    e.preventDefault();

    setLoader(true);
    try {
      const createRfqResponse = {
        price: convertToDollars(respondRfq.price, selectedCurrency),
        additionalNote: respondRfq.additionalNote,
        targetPrice: rfqInfo.targetPrice,
        unit: rfqInfo.unit,
        defaultRfqId: rfqInfo.id,
        userId: user.id,
      };
      await axiosInstance.post("/multiple-rfq/response", createRfqResponse);
      setRespondRfq({
        price: "",
        additionalNote: "",
      });
      setLoader(false);
      toast.success("Response successfully sent", {
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
      setTimeout(() => {
        window.location.reload();
      }, 6300);
    } catch (error) {
      console.log(error);
      setLoader(false);
      if (!error.response.data.errors) {
        return navigate(`/no-connection`);
      }
      toast.error(`${error.response.data.errors[0].message}`, {
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
    }
  };
  return (
    <>
      <Toaster />
      <div
        className="modal fade"
        id="staticBackdrop"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        {loading ? (
          <div className="gooeey">
            <span className="dot"></span>
            <div className="dots">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        ) : (
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title rfq-title-ctn"
                  id="staticBackdropLabel"
                >
                  RFQ ID {rfqInfo && rfqInfo.id}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="quote-modal">
                  <div className="rfq-details">
                    <div className="product-info supplier-product-info">
                      <p className="product-info-title">
                        {t("Price")} ({selectedCurrency})
                      </p>
                      <p className="product-info-description">
                        {rfqInfo.targetPrice &&
                          convertPrice(rfqInfo.targetPrice, selectedCurrency)}
                      </p>
                    </div>
                    <div className="product-info supplier-product-info">
                      <p className="product-info-title">{t("Quantity")}</p>
                      <p className="product-info-description">
                        {rfqInfo.quantityRequired &&
                          numberWithCommas(rfqInfo.quantityRequired)}
                      </p>
                    </div>
                    <div className="product-info supplier-product-info">
                      <p className="product-info-title">{t("Destination")}</p>
                      <p className="product-info-description">
                        {rfqInfo && rfqInfo.destinationPort}
                      </p>
                    </div>
                    <div className="product-info supplier-product-info">
                      <p className="product-info-title">{t("Date_created")}</p>
                      <p className="product-info-description">
                        {rfqInfo.createdAt &&
                          convertDateFormat(rfqInfo.createdAt)}
                      </p>
                    </div>
                    <div className="product-requirement supplier-product-requirement">
                      <p className="product-info-title text-start">
                        {t("Product_Requirement")}
                      </p>
                      <p className="text-start">
                        {rfqInfo && rfqInfo.productDescription}
                      </p>
                    </div>
                  </div>
                  <div className="supplier-response">
                    <form>
                      <p className="text-start">{t("Supplier's_Response")}</p>

                      <div className="form-group form-group supplier-form-group text-start">
                        <label>
                          {t("Price")} ({selectedCurrency})
                        </label>
                        <input
                          className="form-control"
                          type="number"
                          id="price"
                          name="price"
                          value={respondRfq.price}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group supplier-form-group text-start">
                        <label>{t("Additional_Details")}</label>
                        <textarea
                          rows="6"
                          className="form-control"
                          type="text"
                          id="additional_details"
                          name="additionalNote"
                          value={respondRfq.additionalNote}
                          onChange={handleChange}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                {!loader ? (
                  <button
                    type="button"
                    className="btn btn-primary px-4 mb-4"
                    id="message-button"
                    onClick={handleRfqSubmit}
                  >
                    {t("Submit_response")}
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary px-5"
                    id="message-button"
                  >
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SellerMultipleModal;
