import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import "./Authentication.css";
import SiteLogo from "../../assets/img/logo.png";
import { useTranslation } from "react-i18next";

const Authentication = () => {
  const { referralCode } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    if (referralCode) {
      localStorage.setItem("referralCode", referralCode);
    }
  }, [referralCode]);

  return (
    <>
      <div>
        <section className="auth">
          <div className="auth-lhs">
            <div className="auth-lhs-header">
              <Link to="/">
                <img className="site-logo" src={SiteLogo} alt="" />
              </Link>
            </div>
          </div>
          <div className="auth-rhs">
            <div className="auth-rhs-header d-flex justify-content-between">
              <img className="site-logo mobile-only" src={SiteLogo} alt="" />
              <p>
                <Link to="/support">
                  <i className="fas fa-info-circle support-icon me-2"> </i>
                  {t("Customer_Support")}
                </Link>
              </p>
            </div>
            <div className="auth-rhs-content d-flex align-items-center">
              <div className="rhs-inner">
                <h2>{t("Choose_your_type_of_account")}</h2>
                <p>
                  {t("You_are_a_few_steps_away_from_creating_your_account")}
                </p>
                <div className="auth-account-wrap">
                  <Link to="/registration/buyer">
                    <div className="auth-account d-flex justify-content-between">
                      <div className="d-flex">
                        <div className="d-flex align-items-center">
                          <i className="fas fa-user auth-user-icon"></i>
                        </div>
                        <div>
                          <h3>{t("Buyer")}</h3>
                          <p>{t("Access_African_Suppliers_and_Products")}</p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <i className="fas fa-arrow-right auth-cta-icon"></i>
                      </div>
                    </div>
                  </Link>

                  <Link to="/registration/seller">
                    <div className="auth-account d-flex justify-content-between">
                      <div className="d-flex">
                        <div className="d-flex align-items-center">
                          <i className="fas fa-briefcase auth-user-icon"></i>
                        </div>
                        <div>
                          <h3>{t("Seller")}</h3>
                          <p>{t("Access_global_markets_for_your_products")}</p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <i className="fas fa-arrow-right auth-cta-icon"></i>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Authentication;
