import React from "react";
import { Link } from "react-router-dom";

import WaIcon from "../../assets/img/whatsapp.png";
import { useTranslation } from "react-i18next";

const UpperHeader = ({
  handleEventTag,
  user,
  totalCartQuantity,
  getTotalQuantity,
}) => {
  const { t } = useTranslation();

  return (
    <div className="new__header__upper container">
      <a
        href="https://wa.me/+2349166417373"
        target="_blank"
        rel="noopener noreferrer"
        className="whatsapp-link"
      >
        <img src={WaIcon} className="whatsapp__icon" alt="..." />
        {t("Chat_Whatsapp")}
      </a>

      <ul className="header__sub__links">
        <li>
          <Link to="/category/all">{t("Browse_Products")}</Link>
        </li>
        <li>
          <Link to="/all-rfqs"> {t("Explore_RFQs")}</Link>
        </li>
        <li>
          <Link to="/become-seller">{t("Become_a_Seller")}</Link>
        </li>
        <li
          onClick={() =>
            handleEventTag("user clicked on add to cart icon", user?.id)
          }
        >
          <Link to="/checkout" className="cart__icon__wrapper">
            {" "}
            {t("Cart")}{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
              />
            </svg>
            {totalCartQuantity > 0 || getTotalQuantity() > 0 ? (
              <span>{totalCartQuantity + getTotalQuantity()}</span>
            ) : null}
          </Link>
          {/* )} */}
        </li>
      </ul>
    </div>
  );
};

export default UpperHeader;
