import "./App.css";
import "react-loading-skeleton/dist/skeleton.css";

import { SkeletonTheme } from "react-loading-skeleton";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Route, Routes } from "react-router-dom";

import GlobalState from "./components/utils/GlobalState";
import AppState from "./components/utils/AppState";
import AliexpressCartState from "./components/utils/AliexpressCartState.js";
import CurrencyExchangeState from "./components/utils/CurrencyExchangeState.js";

import Home from "./pages/Home/Home";
import AllRfq from "./pages/AllRfq/AllRfq";
import Unauthorized from "./pages/Authorized/Unauthorized";
import Catalog from "./pages/Catalog/Catalog";
import Category from "./pages/Category/Category";
import HelpCenter from "./pages/HelpCenter/HelpCenter";
import OldMutualMain from "./pages/OldMutual/OldMutualMain";
import OldMutualSupplyPage from "./pages/OldMutual/OldMutualSupplyPage";
import OldMutualRegistration from "./pages/OldMutual/OldMutualRegistration";
import ProductInfo from "./pages/ProductDetails/ProductInfo";
import SellersLandingPage from "./pages/SellersLandingPage/SellersLandingPage";
import SellersStore from "./pages/SellersStore/SellersStore";
import Authentication from "./pages/Authentication/Authentication";
import BuyersRegistration from "./pages/Authentication/BuyersRegistration";
import EmailVerification from "./pages/Authentication/Emailverification";
import ForgotPassword from "./pages/Authentication/ForgotPassword";
import Login from "./pages/Authentication/Login";
import PhoneVerification from "./pages/Authentication/PhoneVerification";
import ResetPassword from "./pages/Authentication/ResetPassword";
import SellersRegistration from "./pages/Authentication/SellerRegistration";
import BuyersMessage from "./pages/Dashboard/BuyersDashboard/BuyersMessage/BuyersMessage";
import BuyersOrders from "./pages/Dashboard/BuyersDashboard/BuyersOrder/BuyersOrders";
import BuyersOrderInfo from "./pages/Dashboard/BuyersDashboard/BuyersOrder/BuyersOrderInfo";
import BuyersSettings from "./pages/Dashboard/BuyersDashboard/BuyersSettings/BuyersSettings";
import BuyersDashboard from "./pages/Dashboard/BuyersDashboard/BuyersDashboard";
import BuyerMultipleInfo from "./pages/Dashboard/BuyersDashboard/BuyersRfq/BuyerMultipleInfo";
import BuyerMultipleRfq from "./pages/Dashboard/BuyersDashboard/BuyersRfq/BuyerMultipleRfq";
import BuyersRfqQuote from "./pages/Dashboard/BuyersDashboard/BuyersRfq/BuyersRfqQuote";
import BuyersRfq from "./pages/Dashboard/BuyersDashboard/BuyersRfq/BuyersRfq";
import SellersMessageCenter from "./pages/Dashboard/SellersDashboard/SellersMessage/SellersMessageCenter";
import SellerDashboard from "./pages/Dashboard/SellersDashboard/SellerDashboard";
import SellersOrder from "./pages/Dashboard/SellersDashboard/SellersOrder/SellersOrder";
import SellersOrderInfo from "./pages/Dashboard/SellersDashboard/SellersOrder/SellersOrderInfo";
import SellersSettings from "./pages/Dashboard/SellersDashboard/SellersSettings/SellersSettings";
import SellersProducts from "./pages/Dashboard/SellersDashboard/SellersProduct/SellersProducts";
import SellersProductDescription from "./pages/Dashboard/SellersDashboard/SellersProduct/SellersProductDescription";
import SellersEditProduct from "./pages/Dashboard/SellersDashboard/SellersProduct/SellersEditProduct";
import SellersNewProduct from "./pages/Dashboard/SellersDashboard/SellersProduct/SellersNewProduct";
import SellersRfq from "./pages/Dashboard/SellersDashboard/SellersRFQ/SellersRfq";
import SellersRfqInfo from "./pages/Dashboard/SellersDashboard/SellersRFQ/SellersRfqInfo";
import MultipleRfq from "./pages/Dashboard/SellersDashboard/SellersRFQ/MultipleRfq";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import Internet from "./pages/Internet/Internet";
import AfcftaProducts from "./pages/Afcfta/AfcftaProducts";
import AfcftaSignUp from "./pages/Authentication/AfcftaSignUp.jsx";
import Support from "./pages/Support/Support";
import RfqNotification from "./pages/RfqNotification/RfqNotification";
import OrderNotification from "./pages/OrderNotification/OrderNotification";
import Checkout from "./pages/Checkout/Checkout";
import BankDetails from "./pages/BankDetails/BankDetails";
import ConfirmOrder from "./pages/Dashboard/BuyersDashboard/ConfirmOrder/ConfirmOrder";
import ErrorConfirm from "./pages/Dashboard/BuyersDashboard/ErrorConfirm/ErrorConfirm";
import SellerEditProfile from "./pages/Dashboard/SellersDashboard/SellersSettings/SellerEditProfile";
import ReviewDetails from "./pages/ReviewDetails/ReviewDetails";
import AllReviews from "./pages/AllReviews/AllReviews";
import TermsOfCondition from "./pages/TermsOfCondition/TermsOfCondition.jsx";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy.jsx";
import FarmbeneSignUp from "./pages/Authentication/FarmbeneSignUp.jsx";
import AliexpressProductInfo from "./pages/AliexpressProductInfo/AliexpressProductInfo.jsx";
import AliexpressOrderSuccuss from "./pages/AliexpressOrderSuccess/AliexpressOrderSuccuss.jsx";
import Alicategory from "./pages/AliexpressCategory/Alicategory.jsx";
import TransactPayPaymentPage from "./pages/TransactPayPaymentPage/TransactPayPaymentPage.jsx";
import PaymentSucces from "./pages/PaymentSuccess/PaymentSucces";

function App() {
  const queryClient = new QueryClient();

  return (
    <>
      <SkeletonTheme baseColor="#ddd" highlightColor="#fff">
        <QueryClientProvider client={queryClient}>
          <GlobalState>
            <CurrencyExchangeState>
              <AppState>
                <AliexpressCartState>
                  <Routes>
                    <Route
                      path="/terms-of-use"
                      element={<TermsOfCondition />}
                    />
                    <Route path="/privacy" element={<PrivacyPolicy />} />
                    <Route path="/registration" element={<Authentication />} />
                    <Route
                      path="/registration/:referralCode"
                      element={<Authentication />}
                    />
                    <Route
                      path="/registration/buyer"
                      element={<BuyersRegistration />}
                    />
                    <Route
                      path="/verify-email/:email"
                      element={<EmailVerification />}
                    />
                    <Route
                      path="/forgot-password"
                      element={<ForgotPassword />}
                    />
                    <Route path="/login" element={<Login />} />
                    <Route path="/login/:userId/:token" element={<Login />} />
                    <Route
                      path="/verify-phone-number"
                      element={<PhoneVerification />}
                    />
                    <Route
                      path="/passwordreset/:resetToken"
                      element={<ResetPassword />}
                    />
                    <Route
                      path="/confirm-order/:userId/:productId/:orderId/:token/:status"
                      element={<ConfirmOrder />}
                    />
                    <Route
                      path="/error-on-confirm"
                      element={<ErrorConfirm />}
                    />
                    <Route
                      path="/registration/seller"
                      element={<SellersRegistration />}
                    />
                    <Route
                      path="/registration/farmbene/seller"
                      element={<FarmbeneSignUp />}
                    />
                    <Route path="/afcfta/sign-up" element={<AfcftaSignUp />} />
                    <Route path="/" element={<Home />} />
                    <Route path="/all-rfqs" element={<AllRfq />} />
                    <Route path="/checkout" element={<Checkout />} />
                    <Route
                      path="/rfq/success-message"
                      element={<RfqNotification />}
                    />
                    <Route path="/payment-status" element={<PaymentSucces />} />
                    <Route
                      path="/transact-pay/:currency/:amount/:country/:transactionId"
                      element={<TransactPayPaymentPage />}
                    />
                    <Route
                      path="/order/bank-payment/:cost"
                      element={<BankDetails />}
                    />

                    <Route
                      path="/order/success-message"
                      element={<OrderNotification />}
                    />
                    <Route path="/unauthorized" element={<Unauthorized />} />
                    <Route path="/catalog" element={<Catalog />} />
                    <Route
                      path="/category/:categoryId"
                      element={<Category />}
                    />
                    <Route
                      path="/product/review/:productId"
                      element={<ReviewDetails />}
                    />
                    <Route path="/all-reviews" element={<AllReviews />} />
                    <Route path="/help-center" element={<HelpCenter />} />
                    <Route path="/support" element={<Support />} />
                    <Route path="/no-connection" element={<Internet />} />
                    <Route path="/old-mutual" element={<OldMutualMain />} />
                    <Route
                      path="/old-mutual/become-seller"
                      element={<OldMutualSupplyPage />}
                    />
                    <Route
                      path="/old-mutual/seller/registration"
                      element={<OldMutualRegistration />}
                    />
                    <Route
                      path="/afcfta/products"
                      element={<AfcftaProducts />}
                    />
                    <Route
                      path="/product-info/:productId"
                      element={<ProductInfo />}
                    />
                    <Route
                      path="/aliexpress/product-info/:productId"
                      element={<AliexpressProductInfo />}
                    />
                    <Route
                      path="/aliexpress/order/success"
                      element={<AliexpressOrderSuccuss />}
                    />
                    <Route
                      path="/aliexpress/category/:feedName"
                      element={<Alicategory />}
                    />
                    <Route
                      path="/become-seller"
                      element={<SellersLandingPage />}
                    />
                    <Route
                      path="/sellers-info/:sellerId"
                      element={<SellersStore />}
                    />
                    <Route
                      path="/buyer/message-center"
                      element={<BuyersMessage />}
                    />
                    <Route path="/buyer/orders" element={<BuyersOrders />} />
                    <Route
                      path="/buyer/order-info/:orderId"
                      element={<BuyersOrderInfo />}
                    />
                    <Route
                      path="/buyer/settings"
                      element={<BuyersSettings />}
                    />
                    <Route
                      path="/buyer/dashboard"
                      element={<BuyersDashboard />}
                    />
                    <Route
                      path="/buyer/multiple-rfq-info/:rfqId"
                      element={<BuyerMultipleInfo />}
                    />
                    <Route
                      path="/buyer/multiple-rfq"
                      element={<BuyerMultipleRfq />}
                    />
                    <Route
                      path="/buyer/rfq-info/:rfqId"
                      element={<BuyersRfqQuote />}
                    />
                    <Route path="/buyer/rfqs" element={<BuyersRfq />} />
                    <Route
                      path="/seller/dashboard"
                      element={<SellerDashboard />}
                    />
                    <Route
                      path="/seller/message-center"
                      element={<SellersMessageCenter />}
                    />
                    <Route path="/seller/orders" element={<SellersOrder />} />
                    <Route
                      path="/seller/order-info/:orderId"
                      element={<SellersOrderInfo />}
                    />
                    <Route
                      path="/seller/products"
                      element={<SellersProducts />}
                    />
                    <Route
                      path="/seller/product-info/:productId"
                      element={<SellersProductDescription />}
                    />
                    <Route
                      path="/edit-product/:productId"
                      element={<SellersEditProduct />}
                    />
                    <Route
                      path="/create-new-product"
                      element={<SellersNewProduct />}
                    />
                    <Route path="/seller/rfqs" element={<SellersRfq />} />
                    <Route
                      path="/seller/rfq-info/:rfqId"
                      element={<SellersRfqInfo />}
                    />
                    <Route path="/multiple-rfq" element={<MultipleRfq />} />
                    <Route
                      path="/seller/settings"
                      element={<SellersSettings />}
                    />
                    <Route
                      path="/seller/settings/edit"
                      element={<SellerEditProfile />}
                    />
                    <Route path="/*" element={<ErrorPage />} />
                  </Routes>
                </AliexpressCartState>
              </AppState>
            </CurrencyExchangeState>
          </GlobalState>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </SkeletonTheme>
    </>
  );
}

export default App;
