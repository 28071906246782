import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const RfqSkeleton = () => {
  return (
    <div className="rfq-list-wrap">
      <div className="rfq-list-box">
        <Skeleton height={60} />
      </div>
      <div className="rfq-list-box">
        <Skeleton height={60} />
      </div>
      <div className="rfq-list-box">
        <Skeleton height={60} />
      </div>
      <div className="rfq-list-box">
        <Skeleton height={60} />
      </div>
      <div className="rfq-list-box">
        <Skeleton height={60} />
      </div>
      <div className="rfq-list-box">
        <Skeleton height={60} />
      </div>
    </div>
  );
};

export default RfqSkeleton;
