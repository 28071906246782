import React, { useState, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import "./Header.css";
import SiteLogo from "../../assets/img/logo.png";
import MenuBtn from "../../assets/img/menu.svg";
import WaIcon from "../../assets/img/whatsapp.png";
import { useContext } from "react";
import { GlobalContext } from "../utils/GlobalState";
import useFixedNavbar from "../hooks/useFixedNavbar";
import { useGetAllCategories } from "../hooks/useGetAllCategories";
import { useSignOut } from "../hooks/useSignOut";
import { useEventTag } from "../hooks/useEventTag";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import { AliexpressCartContext } from "../utils/AliexpressCartState";

const Header = () => {
  const { user, totalCartQuantity } = useContext(GlobalContext);
  const { cart } = useContext(AliexpressCartContext);
  const [clickSearch, setClickSearch] = useState(false);
  const [currentCurrencyCode, setCurrentCurrencyCode] = useState(
    cookies.get("currency") || "NGN"
  );
  const [searchInputValue, setSearchInputValue] = useState("");
  const { allCategories, cateLoading } = useGetAllCategories();
  const { handleEventTag } = useEventTag();
  const { handleSignOut } = useSignOut();
  const [searchParams] = useSearchParams();
  const q = searchParams.get("q");

  const languages = [
    {
      code: "EUR",
      name: "EUR",
      country_code: "EUR",
    },
    {
      code: "GBP",
      name: "GBP",
      country_code: "GBP",
    },
    {
      code: "GHS",
      name: "GHS",
      country_code: "GHS",
    },
    {
      code: "NGN",
      name: "NGN",
      country_code: "NGN",
    },
    {
      code: "USD",
      name: "USD",
      country_code: "USD",
    },
    {
      code: "XOF",
      name: "XOF",
      country_code: "XOF",
    },
  ];

  const { t } = useTranslation();

  const setCurrencyCode = (currency) => {
    cookies.set("currency", currency);
    setCurrentCurrencyCode(currency);
  };

  const handleCurrencyChange = (currency) => {
    const newCurrency = currency;
    setCurrencyCode(newCurrency);
    window.location.reload();
  };

  const isFixed = useFixedNavbar(
    typeof window !== "undefined" ? window.innerHeight * 0.1 : 0
  );

  const navigate = useNavigate();

  const handleMobileSearch = (e) => {
    setClickSearch(true);
  };

  const handleSearch = async (e) => {
    e.preventDefault();

    if (!searchInputValue) return;

    navigate(`/catalog/?q=${searchInputValue}`);
  };

  useEffect(() => {
    if (q) setSearchInputValue(q);
  }, [q]);

  const getTotalQuantity = () => {
    return cart.reduce((total, item) => total + item.quantity, 0);
  };

  return (
    <>
      {!clickSearch ? (
        <div className={isFixed ? "fixedPosition" : ""}>
          <div className="topbar">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-4">
                  <p>
                    <a
                      href="https://wa.me/+2349166417373"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={WaIcon} className="topbar-icon" alt="..." />
                      {t("Chat_Whatsapp")}
                    </a>
                  </p>
                </div>
                <div className="col-lg-8 col-8" align="right">
                  <ul className="tb-menu">
                    <li>
                      <Link to="/category/all">{t("Browse_Products")}</Link>
                    </li>
                    <li>
                      <Link to="/all-rfqs"> {t("Explore_RFQs")}</Link>
                    </li>
                    <li>
                      <Link to="/become-seller">{t("Become_a_Seller")}</Link>
                    </li>
                    <li
                      onClick={() =>
                        handleEventTag(
                          "user clicked on add to cart icon",
                          user?.id
                        )
                      }
                    >
                      {/* {getTotalQuantity() > 0 ? (
                        <Link to="/aliexpress/checkout">
                          {" "}
                          {t("Cart")}{" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                            />
                          </svg>
                          <span>{getTotalQuantity()}</span>
                        </Link>
                      ) : ( */}
                      <Link to="/checkout">
                        {" "}
                        {t("Cart")}{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                          />
                        </svg>
                        {totalCartQuantity > 0 || getTotalQuantity() > 0 ? (
                          <span>{totalCartQuantity + getTotalQuantity()}</span>
                        ) : null}
                      </Link>
                      {/* )} */}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <nav className="navbar shadow-sm">
            <div className="container">
              <div className="nav-wrap">
                <div className="d-flex align-items-center">
                  <div className="logo">
                    <Link to="/">
                      <img src={SiteLogo} alt="" />
                    </Link>
                  </div>
                  <div className="dropdown main-menu-icon-wrap">
                    <Link
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      className=""
                    >
                      <img className="main-menu-icon" src={MenuBtn} alt="" />
                    </Link>
                    <div className="dropdown-menu animate slideIn drop-menu-menu">
                      <div className="dropdown-menu-wrapper">
                        {cateLoading ? (
                          <Link>
                            <div className="dropdown-menu-content">
                              <div className="dropdown-menu-icon-ctn">
                                <p>{t("Loading_categories")}...</p>
                              </div>
                            </div>
                          </Link>
                        ) : (
                          <>
                            {" "}
                            {allCategories?.map((category, index) => (
                              <Link key={index} to={`/category/${category.id}`}>
                                <div
                                  className="dropdown-menu-content"
                                  key={index}
                                >
                                  <div className="dropdown-menu-icon-ctn">
                                    <img src={category.icon} alt="categories" />

                                    <p>{category.category}</p>
                                  </div>
                                  <div className="dropdown-menu-icon-ctn-two">
                                    <i className="fas fa-chevron-right"></i>
                                  </div>
                                </div>
                              </Link>
                            ))}
                          </>
                        )}

                        <Link to="/category/all">
                          <div className="dropdown-menu-content">
                            <div className="dropdown-menu-icon-ctn">
                              <i className="fas fa-globe"></i>
                              <p>{t("All_Categories")}</p>
                            </div>
                            <div className="dropdown-menu-icon-ctn-two">
                              <i className="fas fa-chevron-right"></i>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <form id="search" className="search" onSubmit={handleSearch}>
                  <input
                    type="text"
                    className="search"
                    id="search"
                    placeholder={t("Search_for_Products")}
                    value={searchInputValue}
                    onChange={(e) => setSearchInputValue(e.target.value)}
                  />

                  <button className="btn btn-danger"> {t("Search")}</button>
                </form>
                <div className="menu">
                  <ul className="nav-menu">
                    <li className=" mobile-search">
                      <a href="#search" onClick={handleMobileSearch}>
                        <i className="fas fa-search nav-icon"></i>
                        <span>{t("Search")}</span>
                      </a>
                    </li>

                    <li className="dropstart">
                      <Link
                        href="#"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="far fa-user nav-icon"></i>
                        <span>
                          {t("Account")}
                          <i
                            className="fas fa-caret-down mx-1"
                            style={{ fontSize: "12px" }}
                          ></i>
                        </span>
                      </Link>
                      {user ? (
                        <ul
                          className="dropdown-menu custom-top animate slideIn"
                          aria-labelledby="navbarDropdown"
                        >
                          <li>
                            <Link
                              className="dropdown-item"
                              to={
                                user.role === "BUYER"
                                  ? "/buyer/dashboard"
                                  : "/seller/dashboard"
                              }
                            >
                              <i className="fas fa-envelope nav-icon"></i>
                              {t("My_Dashboard")}
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to={
                                user.role === "BUYER"
                                  ? "/buyer/orders"
                                  : "/seller/orders"
                              }
                            >
                              <i className="fas fa-shopping-cart nav-icon"></i>
                              {t("My_orders")}
                            </Link>
                          </li>

                          <li>
                            <Link
                              className="dropdown-item"
                              to={
                                user.role === "BUYER"
                                  ? "/buyer/rfqs"
                                  : "/seller/rfqs"
                              }
                            >
                              <i className="fas fa-sticky-note nav-icon"></i>{" "}
                              {t("Inquiries")}
                            </Link>
                          </li>
                          <li onClick={handleSignOut}>
                            <Link className="dropdown-item" to="/">
                              <i className="fas fa-sign-out-alt nav-icon"></i>
                              {t("Logout")}
                            </Link>
                          </li>
                        </ul>
                      ) : (
                        <ul
                          className="dropdown-menu custom-top animate slideIn"
                          aria-labelledby="navbarDropdown"
                        >
                          <li>
                            <Link className="dropdown-item" to="/login">
                              <button className="btn btn-danger navbar-btn">
                                {t("Login")}
                              </button>
                            </Link>
                          </li>
                          <li>
                            <hr className="dropdown-divider" />
                          </li>
                          <li>
                            <Link className="dropdown-item" to="/registration">
                              <i className="fas fa-sign-in-alt nav-icon"></i>
                              {t("Sign_up")}
                            </Link>
                          </li>
                        </ul>
                      )}
                    </li>
                    <li
                      className="dropstart"
                      onClick={() =>
                        handleEventTag(
                          "user clicked on add to cart icon",
                          user?.id
                        )
                      }
                    >
                      <Link
                        to="/checkout"
                        id="navbarDropdown"
                        className="checkout"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                          />
                        </svg>
                        {totalCartQuantity > 0 || getTotalQuantity() > 0 ? (
                          <span>{totalCartQuantity + getTotalQuantity()}</span>
                        ) : null}
                      </Link>
                      {/* )} */}
                    </li>
                    <li className="">
                      {/* <Link to="/support">
                        <span>Support</span>
                      </Link> */}
                      <div className="dropdown">
                        <button
                          className="btn btn-link dropdown-toggle btn-language"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {t("Currency")}
                        </button>
                        <ul className="dropdown-menu">
                          {languages.map(({ code, name, country_code }) => (
                            <li key={country_code} className="my-0">
                              <button
                                className="dropdown-item "
                                onClick={() => handleCurrencyChange(code)}
                                disabled={code === currentCurrencyCode}
                              >
                                {name}
                              </button>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
      ) : (
        <form id="search" onSubmit={handleSearch}>
          <div className="custom__search">
            <input
              type="text"
              className="form-control custom-style"
              id=""
              placeholder={t("Search_for_Products")}
              value={searchInputValue}
              onChange={(e) => setSearchInputValue(e.target.value)}
            />
            <button style={{ backgroundColor: "transparent", border: "none" }}>
              <i className="fas fa-search nav-icon"></i>
            </button>
          </div>
        </form>
      )}
    </>
  );
};

export default Header;
