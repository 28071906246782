import { useQuery } from "react-query";
import cookies from "js-cookie";

import { axiosInstance } from "../baseUrl";

const fetchAliexpressCategories = async (feedName, selectedCurrency) => {
  const { data } = await axiosInstance.post(`/ali-express/feed-name`, {
    country: "NG",
    target_currency: selectedCurrency === "NGN" ? "NGN" : "USD",
    target_language: "EN",
    page_size: "200",
    sort: "priceAsc",
    page_no: "1",
    category_id: " ",
    feed_name: feedName,
  });

  return data?.aliexpress_ds_recommend_feed_get_response?.result?.products
    ?.traffic_product_d_t_o;
};

export const useGetAliexpressProductsByCategoryName = (feedName) => {
  const selectedCurrency = cookies.get("currency") || "NGN";

  const {
    data: products,
    isLoading: loading,
    error,
  } = useQuery(
    ["aliexpressProducts", feedName, selectedCurrency],
    () => fetchAliexpressCategories(feedName, selectedCurrency),
    {
      staleTime: 24 * 60 * 60 * 1000,
      cacheTime: 24 * 60 * 60 * 1000,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  return {
    loading,
    products,
    error,
  };
};
