import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "../../Dashboard.css";
import "./BuyerSidebar.css";
import Logo from "../../../../assets/img/logo-white.png";

import { axiosInstance } from "../../../../components/baseUrl.jsx";

import { GlobalContext } from "../../../../components/utils/GlobalState";
import { useGetReferralLink } from "../../../../components/hooks/useGetReferralLink.js";

const BuyersSidebar = ({ isActive, handleClick }) => {
  const { t } = useTranslation();
  const { user, setUser } = useContext(GlobalContext);
  const [open, setOpen] = useState(false);
  const { handleCopyClick, copied } = useGetReferralLink();

  const handleSignOut = () => {
    axiosInstance
      .get(`/auth/signout`)
      .then((response) => {
        setUser(null);
        localStorage.setItem("user", false);
        localStorage.removeItem("token");
        localStorage.removeItem("products");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <aside
        id="buyer"
        className={
          isActive
            ? `${"media-sidenav"} ${"shadow"}`
            : `${"sidenav"} ${"shadow"}`
        }
      >
        <div className="sidenav__close-icon" onClick={handleClick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-6 h-6"
          >
            <path
              fillRule="evenodd"
              d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div className="d-logo-area mb-3">
          <img className="logo" src={Logo} alt="TOFA" />
        </div>

        <h2 className="sidenav_title">{t("Dashboard")}</h2>

        <div className="switch-wrap">
          <div className="switch d-flex justify-content-between">
            <div className="switch-lhs">
              <Link to="/buyer/dashboard">{t("Buy")}</Link>
            </div>
            <div className="switch-rhs">
              <Link to="/seller/dashboard">{t("Sell")}</Link>
            </div>
          </div>
        </div>

        <ul className="sidenav__list">
          <Link className="sidenav-link" to="/buyer/dashboard">
            <li className="sidenav__list-item">
              <i className="fas fa-home list_icon"></i>
              {t("Overview")}
            </li>
          </Link>

          <Link className="sidenav-link" to="/buyer/orders">
            <li className="sidenav__list-item">
              <i className="fas fa-paste list_icon"></i>
              {t("My_Orders")}
            </li>
          </Link>

          <Link className="sidenav-link" to="/buyer/message-center">
            <li className="sidenav__list-item">
              <i className="fas fa-envelope list_icon position-relative"></i>
              {t("Message_Center")}
              <span className="icon-notification position-absolute"></span>
            </li>
          </Link>

          <div className={open ? "sidebar-item open" : "sidebar-item"}>
            <div>
              <Link className="sidenav-link" onClick={() => setOpen(!open)}>
                <li className="sidenav__list-item">
                  <i className="fas fa-folder list_icon toggle-btn"></i>
                  RFQs
                </li>
              </Link>
            </div>

            <div className="sidebar-content">
              <ul className="nav flex-column">
                <Link className="sidenav-link" to="/buyer/rfqs">
                  <li className="sidenav__list-item">{t("My_RFQs")}</li>
                </Link>
                <Link className="sidenav-link" to="/buyer/multiple-rfq">
                  <li className="sidenav__list-item">{t("Mutiple_RFQs")}</li>
                </Link>
              </ul>
            </div>
          </div>

          <Link className="sidenav-link" to="/buyer/settings">
            <li className="sidenav__list-item">
              <i className="fas fa-cog list_icon"></i>
              {t("Settings")}
            </li>
          </Link>

          <Link className="sidenav-link" to="/">
            <li className="sidenav__list-item">
              <i className="fas fa-arrow-left list_icon"></i>
              {t("Marketplace")}
            </li>
          </Link>
          {copied ? (
            <li className="sidenav__list-item referral-copy">{t("Link_copied!")}</li>
          ) : (
            <li
              className="sidenav__list-item "
              onClick={() => handleCopyClick(user?.referralCode)}
            >
              <i class="fas fa-copy"></i> {t("Copy_referral")}
            </li>
          )}
        </ul>

        <ul className="sidenav__list mt-auto">
          <Link className="sidenav-link" to="/">
            <li className="sidenav__list-item" onClick={handleSignOut}>
              <i className="fas fa-sign-in-alt list_icon"></i>
              {t("Logout")}
            </li>
          </Link>
        </ul>
      </aside>
    </>
  );
};

export default BuyersSidebar;
