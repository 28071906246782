import React from "react";
import { useTranslation } from "react-i18next";

const HeaderSearch = ({
  searchInputValue,
  setSearchInputValue,
  handleSearch,
}) => {
  const { t } = useTranslation();

  return (
    <form id="search" className="header__search__ctn" onSubmit={handleSearch}>
      <input
        type="text"
        placeholder={t("Search_for_Products")}
        value={searchInputValue}
        onChange={(e) => setSearchInputValue(e.target.value)}
      />
      <button>{t("Search")}</button>
    </form>
  );
};

export default HeaderSearch;
