import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";

import BuyersSidebar from "../BuyersComponent/BuyerSideBar";
import { ProtectedRoutes } from "../../../../components/utils/ProtectedRoutes";

import { GlobalContext } from "../../../../components/utils/GlobalState";
import { axiosInstance } from "../../../../components/baseUrl";

import toast, { Toaster } from "react-hot-toast";
import { Capitalize } from "../../../../components/helpers/functionHelpers";
import { convertDateFormat } from "../../../../components/helpers/convertDate";
import cookies from "js-cookie";
import { BigCard } from "../../../../components/SkeletonLoader/BigCard";
import ReviewModal from "../BuyersComponent/ReviewModal";
import { CurrencyExchangeContext } from "../../../../components/utils/CurrencyExchangeState";

const BuyersOrderInfo = () => {
  const { t } = useTranslation();
  const [orderInfo, setOrderInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState({});
  const [fileLoader, setFileLoader] = useState(false);
  const { orderId } = useParams();
  const { userLoading } = useContext(GlobalContext);
  const { convertCurrency } = useContext(CurrencyExchangeContext);
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const [rerender, setRerender] = useState(false);
  const selectedCurrency = cookies.get("currency") || "NGN";
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useContext(GlobalContext);

  const companyShort = process.env.REACT_APP_COMPANY_NAME_SHORT;

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleClick = (event) => {
    setIsActive((current) => !current);
  };

  function handleChange(event) {
    setFile(event.target.files[0]);
  }

  const handleEdit = async (e) => {
    let toastId = "";
    e.preventDefault();
    setFileLoader(true);
    toastId = toast.loading("loading...", {
      style: {
        background: "#353434",
        color: "#fff",
      },
    });
    try {
      const formData = new FormData();
      formData.append("file", file);

      await axiosInstance.patch(
        `/order/image/receipt/${orderInfo.paymentReceipt.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setFileLoader(false);
      toast.success("You have successfully updated your payment receipt.", {
        id: toastId,
        duration: 3000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
      setRerender((prev) => !prev);
    } catch (error) {
      setFileLoader(false);
      console.log(error);
      if (!error.response.data.errors) {
        return navigate(`/no-connection`);
      }
      toast.error(`${error.response.data.errors[0].message}`, {
        id: toastId,
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
    }
  };

  const handleSubmit = async (e) => {
    let toastId = "";
    e.preventDefault();
    setFileLoader(true);
    toastId = toast.loading("loading...", {
      style: {
        background: "#353434",
        color: "#fff",
      },
    });
    try {
      const formData = new FormData();
      formData.append("file", file);
      await axiosInstance.post(`/order/image/${orderInfo.id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setFileLoader(false);
      toast.success("You have successfully uploaded your payment receipt", {
        id: toastId,
        duration: 3000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
      setRerender((prev) => !prev);
    } catch (error) {
      setFileLoader(false);
      console.log(error);
      if (!error.response.data.errors) {
        return navigate(`/no-connection`);
      }
      toast.error(`${error.response.data.errors[0].message}`, {
        id: toastId,
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
    }
  };

  useEffect(() => {
    axiosInstance
      .get(`/order/${orderId}`)
      .then((response) => {
        setOrderInfo(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        if (error.message && error.message === "Network Error") {
          navigate("/no-connection");
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId, rerender]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  return (
    <>
      <Toaster />
      <div>
        <div className="grid-container seller-order-info-container">
          <div className={"menuu-icon"} onClick={handleClick}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6"
            >
              <path
                fillRule="evenodd"
                d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <BuyersSidebar isActive={isActive} handleClick={handleClick} />

          {userLoading ? (
            <BigCard />
          ) : loading ? (
            <BigCard />
          ) : (
            <main className="main sellerinfomain">
              <div className="main-overview sellerInfoOrder">
                <div className="">
                  <div className="Seller-order-info-header">
                    {orderInfo.status === "PENDING" && (
                      <button className="Seller-order-info-completed text-warning ">
                        {t("Pending")}
                      </button>
                    )}
                    {orderInfo.status === "PROCESSING" && (
                      <button className="Seller-order-info-completed text-primary ">
                        {t("Processing")}
                      </button>
                    )}
                    {orderInfo.status === "SHIPPED" && (
                      <button className="Seller-order-info-completed text-info">
                        {t("Shipped")}
                      </button>
                    )}
                    {orderInfo.status === "DELIVERED" && (
                      <button className="Seller-order-info-completed text-success">
                        {t("Delieved")}
                      </button>
                    )}
                    {orderInfo.status === "RECEIVED" && (
                      <button className="Seller-order-info-completed text-success">
                        {t("Completed")}
                      </button>
                    )}
                    {orderInfo.status === "CANCELLED" && (
                      <button className="Seller-order-info-completed text-danger">
                        {t("Cancelled")}
                      </button>
                    )}
                  </div>
                  <div className="overview-card">
                    <div className="review-button-ctn">
                      <p>{t("Order_Info")}</p>
                      {orderInfo.status === "DELIVERED" && (
                        <button onClick={handleOpenModal}>
                          {t("Kindly_drop_review")}
                        </button>
                      )}
                      <ReviewModal
                        isModalOpen={isOpen}
                        handleCancel={handleCloseModal}
                        productId={orderInfo?.product?.id}
                        userId={user?.id}
                      />
                    </div>

                    <div className="product-info">
                      <p className="product-info-title">{t("Product_Name")}</p>
                      <p>
                        {orderInfo.product
                          ? Capitalize(orderInfo.product.productName)
                          : Capitalize(orderInfo.productName)}
                      </p>
                    </div>
                    <div className="product-info">
                      <p className="product-info-title">{t("Order_Number")}</p>
                      <p>{orderInfo && orderInfo.orderNumber}</p>
                    </div>
                    <div className="product-info">
                      <p className="product-info-title">{t("Total_Cost")}</p>
                      <p>
                        {selectedCurrency}{" "}
                        {orderInfo.cost &&
                          convertCurrency(
                            orderInfo.cost,
                            orderInfo?.currencyType,
                            selectedCurrency
                          )}
                      </p>
                    </div>

                    <div className="product-info">
                      <p className="product-info-title">{t("Payment_Type")}</p>
                      <p>{orderInfo && orderInfo.paymentTerm}</p>
                    </div>

                    <div className="product-info">
                      <p className="product-info-title">{t("Origin")}</p>
                      <p>
                        {orderInfo.product && orderInfo.product.countryOfOrigin}
                      </p>
                    </div>
                    <div className="product-info">
                      <p className="product-info-title">{t("Destination")}</p>
                      <p>{orderInfo && orderInfo.destination}</p>
                    </div>

                    <div className="product-info">
                      <p className="product-info-title">{t("Incoterms")}</p>
                      <p>{orderInfo && orderInfo.incoterm}</p>
                    </div>
                    <div className="product-info">
                      <p className="product-info-title">{t("Notes")}</p>
                      <p>{orderInfo && orderInfo.note}</p>
                    </div>
                  </div>
                </div>
                {orderInfo &&
                  (orderInfo.status === "CANCELLED" ? (
                    <div className="Seller-order-complete">
                      <p className="order-complete-title">
                        {t("Order_history")}
                      </p>

                      <div className="order-complete-responses">
                        <div className="response-title">
                          <i
                            className="fas fa-circle"
                            style={{ color: "#4AAE4E " }}
                          ></i>
                          <p>{t("Order_Recieved")}</p>
                        </div>
                        <div className="response-content">
                          <div className="response-border"></div>
                          <div className="respose-content-text">
                            <p>
                              <span className="text-danger">
                                {t("Your_order_has_been_cancelled.")}{" "}
                              </span>{" "}
                              {t("Reach_out_to")} {companyShort}{" "}
                              {t("Support_to_find_out_more.")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="Seller-order-complete">
                      <p className="order-complete-title">
                        {t("Order_history")}
                      </p>

                      <div className="order-complete-responses">
                        <div className="response-title">
                          <i className="fas fa-circle current"></i>
                          <p>{t("Order_Recieved")}</p>
                        </div>
                        <div className="response-content">
                          <div className="response-border"></div>
                          <div className="respose-content-text">
                            <p>
                              {t("Placed_Order_for")}{" "}
                              {orderInfo && orderInfo.quantityOrdered}{" "}
                              {orderInfo.product &&
                                orderInfo.product.unitForMinOrder}{" "}
                              of{" "}
                              {orderInfo.product
                                ? Capitalize(orderInfo.product.productName)
                                : Capitalize(orderInfo.productName)}{" "}
                              to be delivered to{" "}
                              {orderInfo && orderInfo.destination}
                            </p>
                            <p className="response-content-date">
                              {orderInfo &&
                                convertDateFormat(orderInfo.createdAt)}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          orderInfo.status === "PENDING"
                            ? "order-complete-responses opacity"
                            : "order-complete-responses"
                        }
                      >
                        <div className="response-title">
                          <i
                            className={
                              orderInfo.status === "PENDING"
                                ? `${"fas fa-circle"} ${"next"}`
                                : `${"fas fa-circle"} ${"current"}`
                            }
                          ></i>
                          <p> {t("Payment")}</p>
                        </div>
                        <div className="response-content">
                          <div className="response-border"></div>
                          <div className="respose-content-text">
                            <p>
                              {t("Uploaded_and_processed")}{" "}
                              {orderInfo && orderInfo.paymentTerm}
                            </p>
                            {orderInfo.paymentTerm === "PAYNOW" ? null : (
                              <>
                                {orderInfo.paymentReceipt ? (
                                  <div>
                                    <a
                                      href={orderInfo.paymentReceipt.image}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {t("View_receipt")}
                                    </a>
                                    {orderInfo.status === "PENDING" && (
                                      <form
                                        className="m-0 my-4 d-flex align-items-center"
                                        onSubmit={handleEdit}
                                      >
                                        <div className=" upload-di ">
                                          <label htmlFor="uploadImage">
                                            {t("Edit_payment_proof")}
                                          </label>
                                        </div>
                                        <div className="upload-ii">
                                          {file && file.name}
                                          <input
                                            type="file"
                                            id="uploadImage"
                                            name="file"
                                            onChange={handleChange}
                                            className="m-0 inputtt"
                                          />
                                        </div>
                                        {!fileLoader ? (
                                          <button type="submit">
                                            {t("Edit")}
                                          </button>
                                        ) : (
                                          <button>
                                            <span
                                              className="spinner-border spinner-border-sm"
                                              role="status"
                                              aria-hidden="true"
                                            ></span>
                                          </button>
                                        )}
                                      </form>
                                    )}
                                  </div>
                                ) : (
                                  <form
                                    className="m-0 my-4  payment-wrapper"
                                    onSubmit={handleSubmit}
                                  >
                                    <div className=" upload-di">
                                      <label htmlFor="uploadImage">
                                        {t("Upload_Payment_Proof")}
                                      </label>
                                    </div>
                                    <div className="upload-ii">
                                      {file && file.name}
                                      <input
                                        type="file"
                                        id="uploadImage"
                                        name="file"
                                        onChange={handleChange}
                                        className="m-0 inputtt"
                                      />
                                    </div>
                                    {!fileLoader ? (
                                      <button type="submit">
                                        {t("Submit")}
                                      </button>
                                    ) : (
                                      <button>
                                        <span
                                          className="spinner-border spinner-border-sm"
                                          role="status"
                                          aria-hidden="true"
                                        ></span>
                                      </button>
                                    )}
                                  </form>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          orderInfo.status === "PENDING" ||
                          orderInfo.status === "PROCESSING"
                            ? "order-complete-responses opacity"
                            : "order-complete-responses"
                        }
                      >
                        <div className="response-title">
                          <i
                            className={
                              orderInfo.status === "PENDING"
                                ? `${"fas fa-circle"} ${"wait"}`
                                : orderInfo.status === "PROCESSING"
                                ? `${"fas fa-circle"} ${"next"}`
                                : `${"fas fa-circle"} ${"current"}`
                            }
                          ></i>
                          <p>{t("Order_Shipped")}</p>
                        </div>
                        <div className="response-content">
                          <div className="response-border"></div>
                          <div className="respose-content-text">
                            <p>
                              {t("Order_for")}{" "}
                              {orderInfo && orderInfo.quantityOrdered}{" "}
                              {orderInfo.product &&
                                orderInfo.product.unitForMinOrder}{" "}
                              {t("of")}{" "}
                              {orderInfo.product
                                ? Capitalize(orderInfo.product.productName)
                                : Capitalize(orderInfo.productName)}{" "}
                              {t("has_been_shipped")}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          orderInfo.status === "PENDING" ||
                          orderInfo.status === "PROCESSING" ||
                          orderInfo.status === "SHIPPED"
                            ? "order-complete-responses opacity"
                            : "order-complete-responses"
                        }
                      >
                        <div className="response-title">
                          <i
                            className={
                              orderInfo.status === "PENDING" ||
                              orderInfo.status === "PROCESSING"
                                ? `${"fas fa-circle"} ${"wait"}`
                                : orderInfo.status === "SHIPPED"
                                ? `${"fas fa-circle"} ${"next"}`
                                : `${"fas fa-circle"} ${"current"}`
                            }
                          ></i>
                          <p>{t("Order_Delivered")}</p>
                        </div>
                        <div className="response-content">
                          <div className="response-border"></div>
                          <div className="respose-content-text">
                            <p>
                              {t("Order_for")}{" "}
                              {orderInfo && orderInfo.quantityOrdered}{" "}
                              {orderInfo.product &&
                                orderInfo.product.unitForMinOrder}{" "}
                              {t("of")}{" "}
                              {orderInfo.product
                                ? Capitalize(orderInfo.product.productName)
                                : Capitalize(orderInfo.productName)}{" "}
                              {t("has_been_delivered_in")}{" "}
                              {orderInfo && orderInfo.destination}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          orderInfo.status === "PENDING" ||
                          orderInfo.status === "PROCESSING" ||
                          orderInfo.status === "SHIPPED" ||
                          orderInfo.status === "DELIVERED"
                            ? "order-complete-responses opacity"
                            : "order-complete-responses"
                        }
                      >
                        <div className="response-title">
                          <i
                            className={
                              orderInfo.status === "PENDING" ||
                              orderInfo.status === "PROCESSING" ||
                              orderInfo.status === "SHIPPED"
                                ? `${"fas fa-circle"} ${"wait"}`
                                : orderInfo.status === "DELIVERED"
                                ? `${"fas fa-circle"} ${"next"}`
                                : `${"fas fa-circle"} ${"current"}`
                            }
                          ></i>
                          <p>
                            {orderInfo.status === "RECEIVED"
                              ? "Order Received"
                              : "Order not received"}
                          </p>
                        </div>
                        <div className="response-content">
                          <div className="response-border"></div>
                          <div className="respose-content-text">
                            <p>
                              {t("Order_for")}{" "}
                              {orderInfo && orderInfo.quantityOrdered}{" "}
                              {orderInfo.product &&
                                orderInfo.product.unitForMinOrder}{" "}
                              {t("of")}{" "}
                              {orderInfo.product
                                ? Capitalize(orderInfo.product.productName)
                                : Capitalize(orderInfo.productName)}{" "}
                              {orderInfo.status === "RECEIVED"
                                ? "has been received"
                                : "has not been received"}{" "}
                              {t("in")} {orderInfo && orderInfo.destination}{" "}
                              {t("by_buyer.")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </main>
          )}
        </div>
      </div>
    </>
  );
};

export default ProtectedRoutes(BuyersOrderInfo, ["BUYER", "SELLER"]);
