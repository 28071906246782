import React from "react";
import "./ProductSpecification.css";
import { useTranslation } from "react-i18next";

const ProductSpecification = ({ isActive, productInfo }) => {
  const { t } = useTranslation();
  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <section id="product-description" className={isActive ? "" : "not-active"}>
      <div className="row mb-4">
        <div className="col-lg-12">
          <div className="m-product-spec-box">
            <table className="table table-striped">
              <tbody>
                <tr>
                  <td className="mps-title">{t("Country_of_origin")}:</td>
                  <td> {productInfo?.countryOfOrigin}</td>
                </tr>
                <tr>
                  <td className="mps-title">{t("Supply_capacity")}:</td>
                  <td>
                    {" "}
                    {productInfo?.supplyCapacity}{" "}
                    {productInfo?.unitForSupplyCapacity}
                  </td>
                </tr>
                <tr>
                  <td className="mps-title">{t("Delivery_duration")}:</td>
                  <td>
                    {" "}
                    {productInfo?.minDuration} - {productInfo?.maxDuration}
                  </td>
                </tr>
                {productInfo.productSpecification &&
                  Object.entries(productInfo.productSpecification)
                    .slice(0, productInfo.productSpecification.length)
                    .map((specification, index) => (
                      <tr key={index}>
                        <td className="mps-title">
                          {Capitalize(specification[0])} :
                        </td>
                        <td> {Capitalize(specification[1])}</td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductSpecification;
