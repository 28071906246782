import React from "react";
import { useTranslation } from "react-i18next";

const ShippingDetails = ({ userDetails }) => {
  const { t } = useTranslation();

  return (
    <>
      {" "}
      <div className="col-12">
        <div className="over-card user-profile-ctn">
          <h5>{t("Shipping_details")}</h5>
          <div className="user-details-ctn">
            <div className="user-ctn">
              <div className="user-name">
                <p>{t("Incoterm")}</p>
                <p>
                  {userDetails.incoterm
                    ? userDetails.incoterm
                    : "No mode of delivery selected"}
                </p>
              </div>

              {userDetails?.port && (
                <div className="user-name">
                  <p>{t("Port")}</p>
                  <p>
                    {userDetails?.port ? userDetails.port : "No port selected"}
                  </p>
                </div>
              )}
            </div>

            <div className="user-ctn">
              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal2"
              >
                {t("Change")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShippingDetails;
